import React, { useEffect, useState } from 'react';
import {
    Uploader,
    Button,
    Loader,
    FormGroup,
    ButtonToolbar,
    Alert
} from 'rsuite';

export const UploadImageForm = (props) =>{
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [idToModify, setIdToModify] = useState('0');
    const [modalFunction, setModalFunction] =  useState();
    const [actual, setActual] = useState('');
    var modalClose = () =>{};
    var link;
    if(!props.address){
        link = '../products/';
    }else{
        link = props.address;
    }
    
    useEffect(()=>{
        if(props.modalClose){
            setIsModal(true);
            //setModalFunction(props.modalClose);
            modalClose = props.modalClose;
        }
        if(props.id){
            setIdToModify(props.id);
        }
    });

    const getFileExtension2 =(filename) =>{
        return filename.split('.').pop();
    }

    const updatePhotoName = async (nuevo) =>{
        var nombre='';
        if(props.filename){
            nombre= props.filename;
        }
        var sendedData = new URLSearchParams("id_item="+idToModify+"&name=/products/"+nuevo);
        
        const response2 = await fetch('/backend/mainController.php?opt=uploadPhotoName',{
            method: 'POST',
            body: sendedData
        });
        const obtained2 = await response2.json();
        if(await obtained2){
            Alert.success("Imagen cambiada");
        }else{
            Alert.error("algo ha salido mal");
        }
    }

    /**
    * *Promesas para BASE64
    */

    const [imagesList, setImagesList] = useState([]);

    useEffect(()=>{
        if(imagesList.length>0){
            console.log(imagesList);
        }
    }, [imagesList]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const uploadImages = async(dir, name, file) =>{
        
    }

    const uploadFunction = async() =>{

        if( await  imagesList.length>0){
            setLoading(true);
            await imagesList.map(async (actual)=>{
                var formData = new FormData();
                formData.append('address', link);
                
                if(props.filename){
                    setActual(await props.filename+"."+getFileExtension2(actual.name));
                    await formData.append('photo_name', await props.filename+"."+getFileExtension2(actual.name));
                    await updatePhotoName(props.filename+"."+getFileExtension2(actual.name));
                }
                else{
                    formData.append('photo_name', actual.name);
                }
                formData.append('photo', await toBase64(actual.blobFile));
                const response = await fetch('/backend/mainController.php?opt=uploadPhoto' ,{
                    method: 'POST',
                    body: formData
                });
                const obtained = await response.json();
                if(await obtained.response){
                    
                }
            });
            

        }
        if(props.id){
            
        }
        await setLoading(false)
        await Alert.success('Archivo(s) subido(s)');
        
        

    }

    return(
        <div>
           {!loading && (
               <div>
                    <Uploader multiple accept="image/*" autoUpload={false} onChange={(response)=>{setImagesList(response)}} draggable>
                        <div style={{lineHeight: '200px'}}>Click o arrastre las imágenes</div>
                    </Uploader>
                    <FormGroup style={{textAlign: 'right', marginTop: '30px'}}>
                    <ButtonToolbar >
                        <Button appearance="primary" onClick={()=>{
                            uploadFunction();
                        }}  >Subir imágen(es)</Button>
                        {isModal && (
                            <Button appearance="default" onClick={()=>{modalClose(false)}} >Cancelar</Button>
                        )}
                    </ButtonToolbar>
                    </FormGroup>
               </div>
           )}
           {loading && (
               <Loader backdrop content="Subiendo, espere..." vertical />
           )}
        </div>
    );
}