import React, { useEffect, useState } from 'react';
import {
    ControlLabel,
    Form,
    FormGroup,
    Alert,
    Input,
    ButtonToolbar,
    Button
} from 'rsuite';

export const ChangeUserInfo = (props) => {
    const id = props.id;
    const [actualEmail, setActualEmail] = useState('');
    const [actualPhone, setActualPhone] = useState('');
    const [actualStreet, setActualStreet] = useState('');
    const [actualSuburb, setActualSuburb] = useState('');
    const [actualReference, setActualReference] = useState('');
    const [actualBetweenStreets, setActualBetweenStreets] = useState('');
    const [street, setStreet] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [betweenStreets, setBetweenStreets] = useState('');
    const [suburb, setSuburb] = useState('');
    const [reference, setReference] = useState('');

    const getDataFromUser = async () => {
        const url = '/backend/mainController.php?opt=getDataFromId';
        const dataToSend = new URLSearchParams("id_user=" + id);
        const response = await fetch(url, {
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        if (await obtained.response) {
            setActualStreet(await obtained.address);
            setActualSuburb(await obtained.suburb);
            setActualReference(await obtained.reference);
            setActualEmail(await obtained.email);
            setActualPhone(await obtained.phone);
            setActualBetweenStreets(await obtained.between_streets);
        } else {

        }
    }

    const updateDataUser = async () => {
        let calle = street, colonia = suburb, referencia = reference, emailToSend = email, phoneToSend = phone, between_streetsToSend = betweenStreets;
        if (street == '') {
            calle = actualStreet;
        } 
        if (suburb == '') {
            colonia = actualSuburb;
        } 
        if (reference == '') {
            referencia = actualReference;
        } 
        if(email == ''){
            emailToSend = actualEmail;
        }
        if(phone == ''){
            phoneToSend = actualPhone;
        }
        if(betweenStreets == ''){
            between_streetsToSend = actualBetweenStreets;
        }

        let url = '/backend/mainController.php?opt=changeAddressData';
        let dataToSend = new URLSearchParams("id_user=" + id + "&street=" + calle + "&suburb=" + colonia + "&reference=" + referencia+ "&email=" + emailToSend+"&phone="+phoneToSend+"&between_streets="+between_streetsToSend);

        const response = await fetch(url, {
            method: 'POST',
            body: dataToSend
        });

        const obtained = await response.json();

        if (await obtained.response) {
            Alert.success("Datos cambiados");
            getDataFromUser();
        } else {
            Alert.error("Lo lamento, algo ha salido mal");
        }
    }

    useEffect(() => {
        getDataFromUser();
    }, []);

    return (
        <div>
            <Form>
                <FormGroup>
                    <ControlLabel>Número de teléfono: (actual: {actualPhone}) </ControlLabel>
                    <Input onChange={(response) => setPhone(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Correo electrónico: (actual: {actualEmail}) </ControlLabel>
                    <Input onChange={(response) => setEmail(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Calle y número: (actual: {actualStreet}) </ControlLabel>
                    <Input onChange={(response) => setStreet(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Colonia: (actual: {actualSuburb}) </ControlLabel>
                    <Input onChange={(response) => setSuburb(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Referencia: (actual: {actualReference}) </ControlLabel>
                    <Input onChange={(response) => setReference(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Entre calles: (actual: {actualBetweenStreets}) </ControlLabel>
                    <Input onChange={(response) => setBetweenStreets(response)} />
                </FormGroup>


                <FormGroup style={{ textAlign: 'right' }}>
                    <ButtonToolbar >
                        <Button appearance="primary" onClick={() => updateDataUser()} >Enviar</Button>
                        <Button appearance="default" onClick={() => props.closeModal(false)} >Cancelar</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
}