import React, { useEffect, useState } from 'react';
import {
    List,
    FlexboxGrid,
    Icon,
    Pagination,
    Input,
    Button,
    ButtonGroup,
    Notification,
    Grid,
    Row,
    Colm,
    Alert,
    Col,
    Panel,
    Divider,
    Modal,
    PanelGroup
} from 'rsuite';
import { CopyToClipboard } from 'react-copy-to-clipboard';



export const ProductsTable = (props) => {

    const [modalInfo, setModalInfo] = useState('');
    const [modalName, setModalName] = useState('');
    const [modal, setModal] = useState(false);
    const [modalMovil, setModalMovil] = useState(false);
    const [newData, setNewData] = useState([]);
    const [actualPage, setActualPage] = useState(0);
    /**
     * *EN PRUEBA DEL ANTIGUO CÓDIGO
     */
    const arreglo = props.cart[0];
    const setArreglo = props.cart[1];


    const fixArray = (datas) => {
        var rows = 12;
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total / rows)).toFixed();
        var sobrantes = total % rows;
        var contador = 0;
        if (sobrantes > 0) {
            for (let i = 0; i < veces; i++) {
                temporal2 = [];
                for (let j = 0; j < rows; j++) {
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2 = [];
            for (let i = 0; i < sobrantes; i++) {
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        } else {
            for (let i = 0; i < veces; i++) {
                temporal2 = [];
                for (let j = 0; j < rows; j++) {
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setNewData(temporal);
        //setNewData(temporal);
    }

    /**
     * ? AGREGAR A CARRRITO DE COMPRAS
     */

    const addToCart = (index, actualValor) => {
        //console.log(arreglo);
        let nivel = 0;
        let flag = false;

        if (actualValor > 0) {
            Notification['success']({
                title: 'Agregados:',

                description: actualValor + ' unidades de ' + index['name'],

            })
            if (props.cart[0].length == 0) {
                props.cart[1]([{
                    id: index['id_item'],
                    quantity: Number(actualValor),
                    product: index['name'],
                    priceDom: index['delivery_price']
                }]);
            } else {
                let bandera = false;
                let dePaso = 0;
                let contador = 0;
                arreglo.map((actual) => {
                    if (actual.id == index['id_item']) {
                        bandera = true;
                        dePaso = contador;
                    }
                    contador++;
                })
                let temporal = [];
                if (bandera) {
                    for (let i = 0; i < props.cart[0].length; i++) {
                        if (i == dePaso) {
                            temporal.push({
                                id: props.cart[0][i].id,
                                quantity: (Number(props.cart[0][i].quantity) + Number(actualValor)),
                                product: props.cart[0][i].product,
                                priceDom: props.cart[0][i].priceDom
                            })
                        } else {
                            temporal.push({
                                id: props.cart[0][i].id,
                                quantity: Number(props.cart[0][i].quantity),
                                product: props.cart[0][i].product,
                                priceDom: props.cart[0][i].priceDom
                            })
                        }
                    }
                    props.cart[1](temporal);
                } else {
                    props.cart[0].map((actual) => {
                        temporal.push({
                            id: actual.id,
                            quantity: Number(actual.quantity),
                            product: actual.product,
                            priceDom: actual.priceDom
                        });
                    });
                    temporal.push({
                        id: index['id_item'],
                        quantity: Number(actualValor),
                        product: index['name'],
                        priceDom: index['delivery_price']
                    });
                    props.cart[1](temporal);
                }

            }
        }
    }

    useEffect(() => {
        setActualPage(0);
        fixArray(props.data);

    }, [props.data]);

    useEffect(() => {

    }, [newData]);


    const EachItem = (props) => {
        const actual = props.item;
        const [actualValor, setActualValor] = useState(0);

        return (
            <div>
                <Col xsHidden sm={8} md={6} style={{ marginBottom: '40px', alignItems: 'center' }} >
                    <Panel shaded bordered bodyFill style={{ display: 'inline-block', width: 248, height: 520, backgroundColor: 'white' }}>
                        <div
                            className="order"
                            style={{
                                height: '100%',
                                minHeight: '248px',
                                maxHeight: '248px'
                            }}
                            onClick={() => {
                                setModal(true);
                                setModalInfo(actual.image);
                                setModalName(actual.name);
                            }}
                        >
                            <img src={actual.image} style={{ height: '100%', minHeight: '248px', maxHeight: '248px', width: '100%', objectFit: 'contain', display: 'block', boxSizing: 'border-box' }} />
                            {/*<img src={actual.image} width="248" style={{width: '100%', height: 'auto', resize: 'cover'}} />*/}
                        </div>
                        <Divider />
                        <Panel >
                            <div style={{ marginTop: -40, fontWeight: 500, fontSize: '20px', marginLeft: '5px' }}>
                                <p  >
                                    ${actual.delivery_price}
                                </p>
                                <div
                                    style={{ position: 'absolute', width: '60%', backgroundColor: 'violet', textAlign: 'right' }}
                                >
                                    <CopyToClipboard text={"https://kalitecnology.com/?articulo=" + actual.id_item} onCopy={() => {
                                        Alert.success("Guardado en portapapeles");
                                    }}>
                                        <Icon className="order" icon="share" size='2x' style={{ position: 'absolute', marginTop: '-50px', color: '#bf0411' }} />
                                    </CopyToClipboard>

                                </div>
                                <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '1.3', height: 100 }}>
                                    {actual.name}
                                </div>
                                <div style={{ fontSize: '14px', fontWeight: '400' }}>
                                    Código: {actual.id_item}
                                </div>
                                <div style={{ display: 'flex' }}>

                                    <Input defaultValue="0" type="number" min="0" pattern="^[0-9]+" size="sm" style={{ width: '50%', WebkitAppearance: 'none' }} onChange={(response) => setActualValor(response)} />
                                    <Button
                                        style={{ marginLeft: '5%' }}
                                        onClick={() => {
                                            addToCart(actual, actualValor);
                                        }}
                                    >Agregar <Icon className="order" size='1x' icon="cart-plus" style={{ color: '#bf0411', marginLeft: '3%' }} /></Button>
                                </div>
                                {/*<p style={{marginTop: -20, fontSize: '10px'}} >
                                {actual.name}
                            </p>*/}
                            </div>
                        </Panel>
                    </Panel>
                </Col>
                <Col xs={24} smHidden mdHidden lgHidden >
                    <div
                        style={{
                            display: '-webkit-flex',
                            display: 'flex'
                        }}
                    >
                        <div style={{
                            padding: '16px 0',
                            alignItems: 'flex-start',
                            minHeight: '108px',
                            boxShadow: 'none',
                            margin: '1px 0',
                            borderRadius: 'unset',
                            background: '#fff',
                            position: 'relative'
                        }} >
                            <div style={{
                                flex: '1',
                                display: 'flex'
                            }}>
                                <div style={{
                                    padding: '0 16px',
                                    width: '140px',
                                    position: 'relative',
                                    display: 'table-cell',
                                    verticalAlign: 'top'
                                }} >
                                    <a style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minHeight: '100%',
                                        height: '100%',
                                        boxSizing: 'border-box',

                                    }}>
                                        <figure
                                            onClick={() => {
                                                setModalMovil(true);
                                                setModalInfo(actual.image);
                                                setModalName(actual.name);
                                            }}
                                            style={{
                                                height: '100%',
                                                minHeight: '108px',
                                                width: '140px',
                                                maxWidth: '108px',
                                                position: 'relative',
                                                display: 'table-cell',
                                                verticalAlign: 'top',
                                                borderRadius: '6px',
                                                margin: '0',
                                                overflow: 'hidden',

                                            }}
                                        >
                                            <img src={actual.image} width="120" height="120" style={{
                                                height: 'auto',
                                                width: 'auto',
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                margin: '0 auto',
                                                objectFit: 'contain',
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                borderRadius: '6px',
                                                transform: 'translate(-50%,-50%)'
                                            }} />
                                        </figure>
                                    </a>
                                </div>
                                <div style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    display: 'block'
                                }} >
                                    <div style={{
                                        marginTop: '0',
                                        paddingRight: '40px',
                                        minHeight: '108px',
                                        color: 'black',
                                        display: 'block'
                                    }}>
                                        <div
                                            className="title"
                                            style={{
                                                marginBottom: '12px',
                                                display: 'block',

                                            }}
                                        >
                                            {actual.name}
                                        </div>
                                        <div
                                            style={{
                                                marginBottom: '16px',
                                                display: 'block'
                                            }}
                                        >
                                            <div
                                                className="precio"
                                                style={{
                                                    marginBottom: '0px',
                                                    fontSize: '20px',
                                                    fontWeight: '400',
                                                    color: '#333'
                                                }}
                                            >
                                                ${actual.delivery_price} <CopyToClipboard text={"https://kalitecnology.com/?articulo=" + actual.id_item} onCopy={() => {
                                                    Alert.success("Guardado en portapapeles");
                                                }}>
                                                    <Icon className="order" icon="share" style={{ marginLeft: '30%', fontSize: '20px', color: '#bf0411' }} />
                                                </CopyToClipboard>
                                            </div>
                                            <div  style={{ marginTop: '10px', marginBottom: '10px'}}>
                                                Código: {actual.id_item}
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: '0px',
                                                    fontSize: '20px',
                                                    fontWeight: '400',
                                                    color: '#333'

                                                }}
                                            >
                                                <Input defaultValue="0" type="number" min="0" pattern="^[0-9]+" size="sm" style={{ width: '50%', WebkitAppearance: 'none' }} onChange={(response) => setActualValor(response)} />
                                                <Button style={{ marginLeft: '10px' }} size="xs" onClick={() => {
                                                    addToCart(actual, actualValor);
                                                }} >Agregar <Icon className="order" size='1x' icon="cart-plus" style={{ color: '#bf0411', marginLeft: '3%' }} /></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        )
    }

    return (
        <div>
            {newData.length > 0 && (
                <div   >
                    <Grid fluid>
                        <Row  >
                            {newData[actualPage].map((actual) => (
                                <EachItem item={actual} />
                            ))}
                        </Row>
                    </Grid>
                </div>
            )}
            <center style={{ color: 'black' }} >
                <Pagination
                    first
                    ellipsis
                    last
                    boundaryLinks
                    pages={newData.length}
                    maxButtons={5}
                    activePage={(actualPage + 1)}
                    onSelect={(response) => {
                        setActualPage(response - 1);
                    }}
                />
            </center>
            <Modal
                show={modal}
                onHide={() => {
                    setModal(false);
                }}
            >
                <Modal.Header>
                    <Modal.Title>{modalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img style={{ width: '100%', maxWidth: '700px', height: 'auto' }} src={modalInfo} />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalMovil}
                onHide={() => {
                    setModalMovil(false);
                }}
                style={{
                    width: '100%'
                }}
            >
                <Modal.Header>
                    <Modal.Title>{modalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img style={{ width: '100%', maxWidth: '700px', height: 'auto' }} src={modalInfo} />
                </Modal.Body>
            </Modal>
        </div>
    );
}