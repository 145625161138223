import React, { useEffect, useState } from 'react';
import {
    Form,
    FormGroup,
    ControlLabel,
    Input, 
    ButtonGroup,
    ButtonToolbar,
    Button,
    Whisper,
    Tooltip,
    Alert
} from 'rsuite';

export const ChangePasswordForm = (props) =>{
    //const 
    var md5 = require('md5');
    const id_user = props.id;
    const [actualPassword, setActualPassword] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [checkPasswd, setCheckPasswd] = useState(false);

    const speaker = (
        <Tooltip style={{ width: 120 }}>
          Las contraseñas no coinciden.
        </Tooltip>
    );

    const sendData = async() =>{
        var sendetData = new URLSearchParams('id_user='+id_user+"&original_password="+md5(actualPassword)+"&new_password="+md5(password));
        const response = await fetch('/backend/mainController.php?opt=changePassword',{
            method: 'POST',
            body: sendetData
        });
        const obtained = await response.json();
        if(obtained.response){
            Alert.success(obtained.message);
        }else{
            Alert.error(obtained.message);
        }
    }

    useEffect(()=>{
        if(password!=password2){
            setCheckPasswd(true);
        }else{
            setCheckPasswd(false);
        }
    }, [password2, password]);

    return(
        <div>
            <Form fluid>
                <FormGroup>
                    <ControlLabel>Contraseña actual</ControlLabel>
                    <Input type="password" onChange={(response)=>setActualPassword(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Nueva contraseña</ControlLabel>
                    <Input type="password" onChange={(response)=>setPassword(response)} />
                </FormGroup>
                <FormGroup>
                <ControlLabel>Repita la nueva contraseña</ControlLabel>
                <Whisper
                    preventOverflow
                    
                    speaker={speaker}
                    placement="right"
                    open={checkPasswd}
                >
                    <Input type="password" onChange={(response)=>setPassword2(response)} />
                </Whisper>
                </FormGroup>
                <FormGroup style={{textAlign: 'right', marginTop: '30px'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={()=>{sendData()}} >Enviar</Button>
                <Button appearance="default" >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
            </Form>
        </div>
    );
}