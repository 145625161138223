import React, { useState } from 'react';
import {
    Button,
    FormGroup,
    ControlLabel,
    Input,
    Form,
    Alert,
    ButtonToolbar,
    Notification
} from 'rsuite';

export const ForgetPasswordForm = (props) =>{

    const action = props.action;
    const [email, setEmail] = useState('');

    const resetPassword = async () =>{
        let type="error", message="";
        const url = '/backend/mainController.php?opt=changePasswordByUser';
        const dataToSend = new URLSearchParams('email='+email);
        const response = await fetch(url, {
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        switch(await obtained.response_code){
            case '0':
                type="error";
            break;
            case '1':
                type="success";
            break;
            case '2':
                type="warning";
            break;
            default: 
                type="error";
        }

        Notification[type]({
            title: 'Realizado!',
            description: await obtained.memssage
        });
    }

    return(
        <Form fluid>
            <FormGroup>
                <ControlLabel>Correo electrónico:</ControlLabel>
                <Input placeholder="email@ejemplo.com" onChange={(entry)=>{
                    setEmail(entry);
                }} />
            </FormGroup>
            
            <FormGroup style={{textAlign: 'right', marginTop: '-20px'}}>
                <Button onClick={()=>{props.modalValor('Iniciar Sesión')}} appearance="link">Recordé mi contraseña</Button>
            </FormGroup>
            <FormGroup style={{textAlign: 'right'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={resetPassword} >Enviar</Button>
                <Button appearance="default" onClick={()=>action(false)} >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
        </Form>
    );
}