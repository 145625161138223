import React, { useEffect, useRef, useState } from 'react';
import ReactExport from "react-data-export";
import {Button, Icon} from 'rsuite';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const DownloadExcel = (props) =>{

    const [item, setItem] = useState(<></>);
    const [data, setData] = useState([]);

    const getAllData = async() => {
        const response = await fetch('backend/mainController.php?opt=getAllItemJson');
        const obtained =  await response.json();
        setData(await obtained);
    }
    var d = new Date();

    useEffect(()=>{
        if(props.item){
            setItem(props.item);
        }
        getAllData();
    },[])
    return(
        <div>
            <ExcelFile filename={"Histórico "+d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear()} element={ item } >
                <ExcelSheet data={data} name="Histórico">
                    <ExcelColumn label="CLAVE" value="id_item"/>
                    <ExcelColumn label="NOMBRE" value="name"/>
                    <ExcelColumn label="NOTAS" value="note"/>
                    <ExcelColumn label="PGRAL" value="general_price"/>
                    <ExcelColumn label="PTIENDA" value="registred_price"/>
                    <ExcelColumn label="PDOMICILIO" value="delivery_price"/>
                    <ExcelColumn label="CANTIDAD" value="quantity"/>
                    <ExcelColumn label="SECCION" value="section"/>
                    <ExcelColumn label="ESTADO" value="status"/>
                    <ExcelColumn label="FOTO" value="image"/>
                    <ExcelColumn label="GARANTIA" value="warranty"/>
                </ExcelSheet>
            </ExcelFile>
        </div>
    )
}