import React, { useEffect, useState } from 'react';
import {
    List,
    Modal,
    FlexboxGrid,
    Icon,
    Whisper,
    Popover,
    Pagination,
    Input,
    InputGroup,
    FormGroup,
    ButtonToolbar,
    Button,
    Loader,
    Alert,
    Grid,
    Row,
    Col,
    SelectPicker,
    InputPicker,
    Divider
} from 'rsuite';

export const UsersTable = () =>{

    const [searchWord, setSearchWord] = useState('');
    const [actualPage, setActualPage] = useState(0);
    const [data, setData] = useState([]);   
    const [modal, setModal] = useState(false);

    /**
     * *Estilos
     */

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '52px',
        fontSize: '12px'
    };
      
    const slimText = {
        fontSize: '10px',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
    };
      
    const titleStyle = {
        paddingBottom: 5,
        
        fontWeight: 500
    };
      
    const dataStyle = {
        fontSize: '1.2em',
        fontWeight: 500
    };

    const fixArray = (datas)  =>{
        var rows = 5;
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/rows)).toFixed();
        var sobrantes = total%rows;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setData(temporal);  
    }

    /**
     * *Funciones asíncronas
    */
    const getAllUsers = async() =>{
        const url = '/backend/mainController.php?opt=getAllUsersData';
        const dataToSend = new URLSearchParams("word="+searchWord);
        const response = await fetch(url, {
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        fixArray(await obtained);
    }
    //const getUsers

    useEffect(()=>{
        getAllUsers();
    },[]);

    useEffect(()=>{
        console.log(data);
    }, [data]);

    const EachItem = (props) =>{
        const item = props.item;
        const [phone, setPhone] = useState(item.phone);
        const [type, setType] = useState(item.user_type);
        const [street, setStreet] = useState(item.street);
        const [suburb, setSuburb] = useState(item.suburb);
        const [reference, setReference] = useState(item.reference);
        const [status, setStatus] = useState(item.status);
        const [email, setEmail] = useState(item.email);

        const changeData = async() =>{
            let dataToSend = new URLSearchParams("id_user="+item.id_user+"&phone="+phone+"&type="+type+"&street="+street+"&suburb="+suburb+"&reference="+reference+"&status="+status+"&email="+email);
            let response = await fetch('/backend/mainController.php?opt=updateUser',{
                method: 'POST',
                body: dataToSend
            });
            const obtained = await response.json();
            if(await obtained.response){
                Alert.success("Datos cambiados");
                getAllUsers();
            }else{
                Alert.error("Algo ha salido mal");
            }
        }

        return(
            <div style={{width: '100%'}}>
                <FlexboxGrid fluid style={{marginLeft: '5px'}}>
                    <FlexboxGrid.Item
                        colspan={6}
                        style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                        }}
                    >   
                        <div style={titleStyle}>Nombre: </div>
                        <div style={titleStyle}>{item.name} {item.lastname}</div>
                        
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Nombre de usuario:
                            </div>
                            <div>
                            <div style={titleStyle}>{item.username}</div>
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Teléfono:
                            </div>
                            <div>
                                <Input onChange={(response)=>setPhone(response)} defaultValue={item.phone} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Tipo:
                            </div>
                            <div>
                                <InputPicker onChange={(response)=>setType(response)} style={{width: '100%'}} data={[{value: '1', label: 'Administrador'}, {value: '3', label: 'Cliente'}]} defaultValue={item.user_type} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Calle y número:
                            </div>
                            <div>
                                <Input defaultValue={item.street} onChange={(response)=>setStreet(response)} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Colonia:
                            </div>
                            <div>
                                <Input defaultValue={item.suburb} onChange={(response)=>setSuburb(response)} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Referencia:
                            </div>
                            <div>
                                <Input defaultValue={item.reference} onChange={(response)=>setReference(response)} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                    colspan={6}
                    style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'hidden',
                        alignContent: 'center',
                        alignSelf: 'center',
                        alignItems: 'center'
                    }}>
                        <Button style={{ marginTop: '10px'}} onClick={()=>changeData()} >Aplicar cambios</Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Estado:
                            </div>
                            <div>
                                <InputPicker style={{width: '100%'}} data={[{value: '1', label: 'Activo'}, {value: '0', label: 'Inactivo'}]} defaultValue={item.status} onChange={(response)=>setStatus(response)} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item 
                        colspan={6}
                        style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={titleStyle}>
                            <div>
                                Email:
                            </div>
                            <div>
                            <Input defaultValue={item.email} onChange={(response)=>setEmail(response)} />
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
            </div>
        );
    }

    return(
        <div style={{
            paddingTop: '15px', 
            paddingBottom: '15px'
        }} >
            <Grid fluid>
                <Row>
                    <Col xsHidden smHidden md={24} >
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={3} >
                                <div style={{fontSize: '16px', fontWeight: 'bold', marginLeft:'20px', marginTop: '20px'}} >Usuarios</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={19} style={{...styleCenter, marginTop: '5px'}} >
                                <InputGroup >
                                    <Input placeholder="Buscar por nombre ó teléfono" onChange={(response)=>{
                                        setSearchWord(response);
                                    }} />
                                    <InputGroup.Addon className="order"  onClick={()=>{
                                            getAllUsers();
                                        }} >
                                        <Icon icon="search"  />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Col>
                    <Col xs={24} sm={24} mdHidden lgHidden >
                        <div>
                        <FlexboxGrid>
                        
                        <FlexboxGrid.Item colspan={24} >
                            <div style={{fontSize: '16px', marginLeft:'20px', fontWeight: 'bold'}} >Pedidos</div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={21} style={{}} >
                                <InputGroup >
                                    <Input size="xs" placeholder="Buscar por nombre ó teléfono" onChange={(response)=>{
                                        
                                    }} />
                                    <InputGroup.Addon>
                                        <Icon icon="search" className="order" onClick={()=>{
                                            
                                        }} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="order" colspan={1} onClick={()=>{
                                
                            }}>
                               <div style={{marginTop: '10px', marginLeft: '10px'}} >
                               
                               </div>
                            </FlexboxGrid.Item>
                            </FlexboxGrid>
                            </div>
                    </Col>
                </Row>
            </Grid>
            <List hover fluid>
            {data.length > 0 && (
                <div>
                    {data[actualPage].map((item, index)=>(
                        <div>
                            <List.Item key={index} index={index} >
                                <EachItem item={item} />
                            </List.Item>
                        </div>
                    ))}
                </div>
            )}
            </List>
            <Pagination
                first
                ellipsis
                last
                boundaryLinks
                pages={data.length}
                maxButtons={5}
                activePage={(actualPage+1)}
                onSelect={(response)=>{
                    setActualPage(response-1);
                }}
            />
        </div>
    );
}