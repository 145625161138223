import React, { useEffect, useState } from 'react';

import {
    FlexboxGrid,
    Button,
    ButtonGroup,
    FormGroup,
    ButtonToolbar,
    Input, 
    Form,
    ControlLabel,
    Alert,
    InputPicker
} from 'rsuite';

export const UpdateFiscalData = (props) =>{

    const [business_name, setBusiness_name] = useState('');
    const [business_address, setBusiness_address] = useState('');
    const [business_email, setBusiness_Email] = useState('');
    const [rfc, setRfc] = useState('');

    const payment_type = [
        {value: '1', label: 'Efectivo'},
        {value: '2', label: 'Cheque nominativo'},
        {value: '3', label: 'Transferencia electrónica de fondos'},
        {value: '4', label: 'Tarjeta de crédito'},
        {value: '5', label: 'Tarjeta de débito'}
    ];

    const CFDI =  [
        {value: '1', label: 'G01 Adquisición de mercancias'},
        {value: '2', label: 'G03 Gastos en general'},
        {value: '3', label: 'I04 Equipo de computo y accesorios'},
        {value: '4', label: 'P01 Por definir'}
    ]

    const sendData = async()=>{
        var sendedData = new URLSearchParams("id="+props.id+"&business_name="+business_name+"&business_address="+business_address+"&rfc="+rfc+"&business_email="+business_email);
        if(business_name != '' && business_address != '' && rfc != ''){
            const response = await fetch('/backend/mainController.php?opt=updateFiscalData',{
                method: 'POST',
                body: sendedData
            });
            const obtained = await response.json();
            if(await obtained.response){
                Alert.success("Cambios agregados");
                fillData();
            }else{
                Alert.error("Algo ha salido mal, inténtelo de nuevo más  tarde");
            }
        }
    }

    const fillData =  async()=>{
        var sendedData = new URLSearchParams("id_user="+props.id);
        const response = await fetch("/backend/mainController.php?opt=getDataFromId", {
            method: 'POST',
            body: sendedData
        });
        const obtained = await response.json();
        if(obtained.response){
            setBusiness_name(obtained.business_name);
            setBusiness_address(obtained.business_address);
            setRfc(obtained.rfc);
            setBusiness_Email(obtained.business_email);
        }
    }

    useEffect(()=>{
        fillData();
    },[]);

    return(
        <div>
            <Form fluid>
                <FormGroup>
                    <ControlLabel>Razón social</ControlLabel>
                    <Input placeholder={business_name} onChange={(response)=>setBusiness_name(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Dirección fiscal</ControlLabel>
                    <Input placeholder={business_address} onChange={(response)=>setBusiness_address(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>RFC</ControlLabel>
                    <Input placeholder={rfc} onChange={(response)=>setRfc(response)} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <Input placeholder={business_email} onChange={(response)=>setBusiness_Email(response)} />
                </FormGroup>
                <FormGroup style={{textAlign: 'right', marginTop: '30px'}}>
                <ButtonToolbar >
                    <Button appearance="primary" onClick={()=>{sendData()}} >Enviar</Button>
                    <Button appearance="default" onClick={()=>props.closeModal(false)} >Cancelar</Button>
                </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
}