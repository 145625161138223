import React, {useState, useEffect} from 'react';
import {
    Form, 
    FormGroup, 
    ControlLabel, 
    ButtonToolbar, 
    Button, 
    Input, 
    Tooltip, 
    Whisper, 
    Alert,
    InputPicker,
    Notification
} from 'rsuite';

export const AddUser = (props) =>{

    var md5 = require('md5');
    const action = props.action;
    const setLoading = props.action2;
    const [name, setName] = useState("");
    const [lastname, setLastame] = useState('');
    const [user, setUser] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('123');
    const [typeUser, setTypeUsers] = useState('3');
    const dataSelect = [
        {value: '1', label: 'Administrador', role: 'Administrador'},
        
        {value: '3', label: 'Cliente', role: 'Cliente'}
    ]
    
    useEffect(()=>{
        console.log(typeUser);
    }, [typeUser]);
    const [address, setAddress] = useState('');
    const [suburb, setSuburb] = useState('');
    const [reference, setReference] = useState('');
    const [email, setEmail] = useState('');

    const setData = async() =>{
        const url = '/backend/mainController.php?opt=addManualUser';
        let dataToSend = new URLSearchParams("name="+name+"&lastname="+lastname+"&username="+user+"&password="+md5(password)+"&phone="+phone+"&address="+address+"&suburb="+suburb+"&reference="+reference+"&email="+email+"&user_type="+typeUser);
        if(typeUser == '1'){
            if(name!='' && lastname != '' && user != '' && password != '' && email != ''){
                const response = await fetch(url, {
                    method: 'POST',
                    body: dataToSend
                });
                const obtained = await response.json();
                if(await obtained.response){
                    Notification['success']({
                        title: 'Listo!',
                        description: 'Tu usuario se ha agregado con éxito'
                    });
                }else{
                    Notification['error']({
                        title: 'Oops!',
                        description: 'algo ha salido mal'
                    });
                }
            }else{
                Notification['error']({
                    title: 'Oops!',
                    description: 'Por favor, rellene todos los datos necesarios'
                });
            }
        }else if(typeUser=='3'){
            if(name!='' && lastname != '' && user != '' && password != '' && email != '' && address!= '' && suburb!='' && reference!=''){
                const response = await fetch(url, {
                    method: 'POST',
                    body: dataToSend
                });
                const obtained = await response.json();
                if(await obtained.response){
                    Notification['success']({
                        title: 'Listo!',
                        description: 'Tu usuario se ha agregado con éxito'
                    });
                }else{
                    Notification['error']({
                        title: 'Oops!',
                        description: 'algo ha salido mal'
                    });
                }
            }else{
                Notification['error']({
                    title: 'Oops!',
                    description: 'Por favor, rellene todos los datos necesarios'
                });
            }
        }

    }

    

    return(
        <Form fluid >
             <FormGroup>
                <ControlLabel>Nombre: </ControlLabel>
                <Input onChange={(response)=>{setName(response)}} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Apellidos: </ControlLabel>
                <Input onChange={(response)=>setLastame(response)} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Usuario: </ControlLabel>
                <Input onChange={(response)=>{
                        setUser(response);
                    }} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Tipo:(Cliente por default) </ControlLabel>
                <InputPicker  
                    style={{width:'100%'}}  
                    data={dataSelect} 
                    onChange={(value)=>{
                        setTypeUsers(value);
                    }} 
                    onClean={()=>{
                        setTypeUsers('3');
                    }}
                />
                
            </FormGroup>
            <FormGroup>
                <ControlLabel>Contraseña:(123 por default) </ControlLabel>
                <Input type="password" onChange={(response)=>{setPassword(response)}}   />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Email: </ControlLabel>
                <Input type="email" onChange={(response)=>{setEmail(response)}} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Teléfono</ControlLabel>
                <Input  onChange={(response)=>setPhone(response)} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Calle y número: </ControlLabel>
                <Input onChange={(response)=>{setAddress(response)}}  />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Colonia: </ControlLabel>
                <Input onChange={(response)=>{setSuburb(response)}}  />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Referencia: </ControlLabel>
                <Input onChange={(response)=>{setReference(response)}}  />
            </FormGroup>
            
            <FormGroup style={{textAlign: 'right'}}>
                <ButtonToolbar >
                    <Button appearance="primary" onClick={()=>setData()} >Enviar</Button>
                    <Button appearance="default" onClick={()=>action(false)} >Cancelar</Button>
                </ButtonToolbar>
            </FormGroup>
        </Form>
    );
}