import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import {
    Container,
    Header,
    Sidebar,
    Content,
    Footer,
    Grid,
    Row,
    Col,
    Button,
    Icon,
    InputGroup,
    AutoComplete,
    Modal,
    FormGroup,
    ControlLabel,
    Input,
    Form,
    FormControl,
    Alert,
    Badge,
    Loader,
    Sidenav,
    Nav,
    Dropdown,
    FlexboxGrid,
    Drawer,
    List,
    Carousel,
    Divider
} from 'rsuite';

import {
    NavbarComponent
} from '../../components/navbarcomponent/navbarcomponent';

import { SignUpForm } from '../../components/signUpForm/signUpForm';
import { LoginForm } from '../../components/loginForm/loginForm';
import { ProductsTable } from '../../components/productsTable/productsTable';
import { ShoppingCartForm } from '../../components/shoppingCartForm/shoppingCartForm';
import { ChangePasswordForm } from '../../components/changePasswordForm/changePasswordForm';
import { UpdateFiscalData } from './../../components/updateFiscalData/updateFiscalData';
import { UserShipment } from './../../components/userShipments/userShipments';
import { ChangeUserInfo } from './../../components/changeUserInfo/changeUserInfo';
import { NewNavbarComponent } from './../../components/newNavbarComponent/newNavbarComponent';
import { ForgetPasswordForm } from './../../components/forgetPasswordForm/forgetPasswordForm';
import { ShowFiscalDataToPay } from './../../components/showFiscalDataToPay/showFiscalDataToPay';
import chroma from 'chroma-js';
import Select from 'react-select';

import './index.css';

import RecipeReviewCard from '../../components/RecipeReviewCard/RecipeReviewCard';

export const Index = () => {

    const history = useHistory();
    const [userExist, setUserExist] = useState(null);
    const [findName, setFindName] = useState('');

    if (localStorage.getItem("user_type") == 1) {
        history.replace("/admin");
    }

    const styles = {
        width: '100%',
        marginTop: '3%'
    };

    /**
     ** Requeridos para  el uso de encriptado de datos MD5
     */
    var md5 = require('md5');
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [modalLogin, setModalLogin] = useState(false);
    const [modal, setModal] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [actualModal, setActualModal] = useState("");
    const [actualDrawer, setActualDrawer] = useState("");
    const [articulos, setArticulos] = useState(0);
    const [loading, setLoading] = useState(false);
    const [menu, setMenu] = useState([]);
    const [listPredict, setListPredict] = useState([]);
    const [searchBySection, setSearchBySection] = useState('');
    const [orderType, setOrderType] = useState(1);
    const [productsList, setProductsList] = useState([]);
    let premisa;
    if (localStorage.getItem('shoppingCart')) {
        premisa = JSON.parse(localStorage.getItem('shoppingCart'));
    } else {
        premisa = [];
    }
    const [shoppingCart, setShoppingCart] = useState(premisa);
    const [userDataId, setUserDataId] = useState('');
    const [userDataName, setUserDataName] = useState('');
    const [userDataAddress, setUserDataAddress] = useState('');
    const [userDataPhone, setUserDataPhone] = useState('');
    const [imagesList, setImagesLIst] = useState([]);

    const [content, setContent] = useState('Slider');

    /**
     * *Movil
     */
    const [drawerMovil, setDrawerMovil] = useState(false);
    const [modalMovil, setModalMovil] = useState(false);

    /**
     * *Funciones
     */

    const checkMenu = async () => {
        const response = await fetch('/backend/mainController.php?opt=getCategoriesByOrder');
        const obtained = await response.json();
        setMenu(obtained);
    }

    const getItemList = async () => {
        const response = await fetch('/backend/mainController.php?opt=getListForPredict');
        const obtained = await response.json();
        await setListPredict(await obtained);
    }

    const getItemListWord = async () => {
        setSearchBySection('');
        var sendetData = new URLSearchParams('name=' + findName + "&order=" + orderType);
        const response = await fetch('/backend/mainController.php?opt=getProductsData', {
            method: 'POST',
            body: sendetData
        });
        const obtained = await response.json();
        //setContent('Productos');
        setProductsList(await obtained);
    }

    const getItemListWord2 = async (variable) => {
        setSearchBySection('');
        var sendetData = new URLSearchParams('name=' + variable + "&order=" + orderType);
        const response = await fetch('/backend/mainController.php?opt=getProductsData', {
            method: 'POST',
            body: sendetData
        });
        const obtained = await response.json();
        //setContent('Productos');
        setProductsList(await obtained);
    }

    const getUserData = async () => {
        var nombres = null;
        var id_user = null;
        var address_user = null;

        nombres = await localStorage.getItem("name");
        id_user = await localStorage.getItem("id_user");
        if (await nombres != null) {
            setUserDataName(nombres);
            setUserDataId(id_user);
        } else {
            setUserDataName('');
            setUserDataId('');
        }
    }

    const getItemListSection = async () => {
        var sendetData = new URLSearchParams('category=' + searchBySection + "&order=" + orderType);
        const response = await fetch('/backend/mainController.php?opt=getProductsData', {
            method: 'POST',
            body: sendetData
        });
        const obtained = await response.json();
        //setContent('Productos');
        setProductsList(await obtained);


    }

    const getImagesLIst = async () => {
        const response = await fetch('/backend/mainController.php?opt=getCoverImages');
        const obtained = await response.json();

        setImagesLIst(await obtained);
    }

    const llenarCarritoStorage = async () => {
        //await localStorage.removeItem('shoppingCart');
        //localStorage.setItem('shoppingCart', JSON.stringify(carrito));
        //console.log(await localStorage.getItem('shoppingCart'));
        //localStorage.setItem('contador', contador);
        let contador = 0;
        if (shoppingCart.length > 0) {
            shoppingCart.map((actual) => {
                contador += actual.quantity;
            });
        }

        setArticulos(contador);
        localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
        localStorage.setItem('contador', contador);

    }

    useEffect(() => {

        llenarCarritoStorage();
    }, [shoppingCart]);


    useEffect(() => {
        getUserData();
    })

    const mostrarLet = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return false;
    }

    const prepararTodo = async () => {
        await checkMenu();
        await getImagesLIst();
        await getItemList();
        await getUserData();

        if (await mostrarLet("articulo")) {
            let articulo = mostrarLet("articulo");

            await getItemListWord2(articulo);

            await setContent("Productos");

        } else {

        }
    }

    const orden = [
        { value: 1, label: 'Ordenar por nombre' },
        { value: 2, label: 'Ordenar de menor a mayor precio' },
        { value: 3, label: 'Ordenar de mayor a menor precio' }];

    const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',
        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma('#4CAF50');
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? '#4CAF50'
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : '#4CAF50',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? '#4CAF50' : color.alpha(0.3).css()),
                },
            };
        },
        input: styles => ({ ...styles, ...dot() }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot('#4CAF50') }),
    };


    const obtainFirstCart = async () => {
        /*await localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
        await localStorage.setItem('contador', contador);*/
        let obtenido = JSON.parse(await localStorage.getItem('shoppingCart'));
        let contadorObtenido = await localStorage.getItem('contador');

        await setShoppingCart(obtenido);
        await setArticulos(contadorObtenido);
        await prepararTodo();
    }
    useEffect(() => {
        //checkMenu();
        /*localStorage.removeItem("id_user");
        localStorage.removeItem("user_type");
        localStorage.removeItem("name");
        localStorage.clear();*/
        /*getItemList();
        getUserData();
        getImagesLIst();*/
        //prueba.splice(0, 1);

        obtainFirstCart();
    }, []);

    useEffect(() => {

    }, [productsList]);


    useEffect(() => {

    }, [findName]);

    useEffect(() => {

        getItemListSection();
    }, [searchBySection]);

    useEffect(() => {
        getUserData();
    }, [loading]);

    useEffect(() => {

        if (searchBySection == '') {
            getItemListWord();
        } else {
            getItemListSection();
        }
    }, [orderType]);

    //sort-alpha-asc
    //sort-alpha-desc
    //sort-numeric-asc
    //sort-numeric-desc
    return (
        <div style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}
        >
            <div
                style={{
                    flex: 1,

                }}
            >
                <div className="header" style={{ backgroundColor: '#4CAF50' }} >
                    <Grid fluid >
                        <Row className="show-grid" >
                            <Col xs={4} sm={4} mdHidden lgHidden >
                                <img className="order" style={{ marginTop: '5px', height: '50px' }} onClick={() => setContent('Slider')} src="./logokali.svg" ></img>
                            </Col>
                            <Col xs={14} sm={14} mdHidden lgHidden >
                                <InputGroup inside style={{ ...styles, marginTop: '12px' }}>
                                    <AutoComplete
                                        onKeyDown={(response) => {
                                            if (response.key === 'Enter') {

                                                if (findName == null) {
                                                    setContent('Productos');
                                                    getItemListWord();

                                                } else {
                                                    setContent('Productos');
                                                    setSearchBySection('');
                                                    getItemListWord();
                                                }
                                            }
                                        }}
                                        placeholder="Buscar por nombre o código" onChange={(response) => {
                                            setFindName(response);
                                        }} data={listPredict} />
                                    <InputGroup.Button style={{ backgroundColor: '#febd69' }} onClick={() => {
                                        if (findName == null) {
                                            setContent('Productos');
                                            getItemList();
                                        } else {
                                            setContent('Productos');
                                            setSearchBySection('');
                                            getItemListWord();
                                        }
                                    }} >
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </InputGroup>
                            </Col>
                            <Col xs={3} sm={3} mdHidden lgHidden >
                                <Icon icon="bars" inverse size="2x" style={{ marginTop: '15px' }} onClick={() => setDrawerMovil(true)} />
                            </Col>
                            <Col xs={3} sm={3} mdHidden lgHidden >
                                <div style={{ marginTop: '15px' }} onClick={() => {
                                    setModalMovil(true);
                                    setActualModal('Carrito de compras');
                                }} >
                                    <Badge content={articulos} >
                                        <div className="order"><center><Icon inverse icon="shopping-cart" size="2x" /></center></div>
                                    </Badge>
                                </div>
                            </Col>
                            <Col xsHidden smHidden md={4}  >
                                <img className='order' style={{ marginLeft: '30px', marginTop: '15px', marginBottom: '15px', height: '35px' }} onClick={() => {
                                    setContent('Slider');
                                }} src="./kalilogo.svg" />
                            </Col>
                            <Col xsHidden smHidden md={14} >
                                <InputGroup inside style={{ ...styles, marginTop: '15px' }}>
                                    <AutoComplete
                                        placeholder="Buscar por nombre o código" onChange={(response) => {
                                            setFindName(response);
                                        }}
                                        onKeyDown={(response) => {
                                            if (response.key === 'Enter') {
                                                console.log("Tenemos enter");
                                                if (findName == null) {
                                                    setContent('Productos');
                                                    getItemListWord();

                                                } else {
                                                    setContent('Productos');
                                                    setSearchBySection('');
                                                    getItemListWord();
                                                }
                                            }
                                        }}
                                        data={listPredict} />


                                    <InputGroup.Button style={{ backgroundColor: '#febd69' }} onClick={() => {
                                        if (findName == null) {
                                            setContent('Productos');
                                            getItemListWord();

                                        } else {
                                            setContent('Productos');
                                            setSearchBySection('');
                                            getItemListWord();
                                        }
                                    }} >
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </InputGroup>
                            </Col>
                            {userDataName === '' && (
                                <div>
                                    <Col xsHidden smHidden md={2} style={{ marginTop: '15px' }} >
                                        {userDataName === '' && (
                                            <Button style={{ color: 'white' }} appearance="link" onClick={() => {
                                                setModal(true);
                                                setActualModal("Iniciar Sesión");
                                            }} >Iniciar sesión</Button>
                                        )}


                                    </Col>
                                    <Col xsHidden smHidden md={2} style={{ marginTop: '15px' }} >
                                        {userDataName === '' && (
                                            <Button style={{ color: 'white' }} appearance="link" onClick={() => {
                                                setModal(true);
                                                setActualModal("Crear Usuario");
                                            }} >Crear usuario</Button>)
                                        }
                                    </Col>
                                </div>
                            )}
                            {userDataName !== '' && (
                                <div>
                                    <Col xsHidden smHidden md={4} style={{ marginTop: '15px' }} >
                                        <Button appearance="link" style={{ color: 'white' }} onClick={() => {
                                            setDrawer(true)
                                            setActualDrawer("Opciones");
                                        }} >{userDataName}</Button>
                                    </Col>

                                </div>
                            )}
                            <Col xsHidden smHidden md={2} >
                                <div style={{ marginTop: '15px' }} onClick={() => {
                                    setModal(true);
                                    setActualModal('Carrito de compras');
                                }} >
                                    <Badge content={articulos} >
                                        <div className="order"><center><Icon inverse icon="shopping-cart" size="2x" /></center></div>
                                    </Badge>
                                </div>
                            </Col>
                        </Row>

                    </Grid>

                </div>
                <div>
                    <Grid fluid>
                        <Row>
                            <Col xsHidden smHidden md={24} >
                                <div
                                    style={{
                                        display: '-webkit-flex',
                                        display: 'flex',
                                        backgroundColor: '#f0f2f5',
                                        WebkitFlexWrap: 'wrap',
                                        flexWrap: 'wrap',
                                        WebkitJustifyContent: 'center',
                                        justifyContent: 'center'
                                    }} >

                                    <div style={{ width: '100%', maxWidth: '1300px', display: 'flex', marginTop: '10px' }} >
                                        <div style={{ flex: 1, width: '100%', maxWidth: '370px', minWidth: '200px', display: 'flex', height: '100%', zIndex: '1' }}>
                                            {/* <NavbarComponent contenido={setContent} leerContenido={content} section={setSearchBySection} menu={menu} />*/}
                                            <NewNavbarComponent setDrawer={setDrawerMovil} contenido={setContent} leerContenido={content} section={setSearchBySection} menu={menu} />
                                        </div>
                                        <div style={{ width: '100%' }} >
                                            {content === 'Productos' && (
                                                <>
                                                    {productsList.length === 0 && (
                                                        <h1>No hay resultados aún...</h1>
                                                    )}
                                                    {productsList.length > 0 && (
                                                        <div>
                                                            <FlexboxGrid fluid style={{ marginBottom: '10px' }}>
                                                                <FlexboxGrid.Item style={{ fontSize: '25px', fontWeight: '400', color: 'black' }} colspan={16} >
                                                                    Bienvenido
                                                        </FlexboxGrid.Item>
                                                                <FlexboxGrid.Item colspan={8}  >
                                                                    <Select
                                                                        className="order"
                                                                        theme={theme => ({
                                                                            ...theme,
                                                                            borderRadius: 0,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary25: 'hotpink',
                                                                                primary: '#f0f2f5',
                                                                            },
                                                                        })}
                                                                        styles={colourStyles}
                                                                        defaultValue={orden[0]}
                                                                        onChange={(response) => {
                                                                            setOrderType(response.value);
                                                                        }}
                                                                        options={orden}
                                                                    />
                                                                </FlexboxGrid.Item>
                                                            </FlexboxGrid>
                                                            <ProductsTable  cart={[shoppingCart, setShoppingCart]} data={productsList} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {(content === 'Slider' && imagesList.length > 0) && (
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ paddingTop: '0px' }} ><h2 >Promociones</h2></div>
                                                    <Carousel autoplay shape="bar" style={{ height: '100%' }} >
                                                        {imagesList.map((actual) => (
                                                            <img
                                                                src={actual.name}
                                                                height="100"
                                                            />
                                                        ))}
                                                    </Carousel>

                                                </div>
                                            )}
                                            {(content === 'Mis pedidos' && userDataId !== '') && (
                                                <div style={{ width: '100%' }}>

                                                    <UserShipment id_user={userDataId} />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            {/**
                         * *Para móviles
                         */}
                            <Col xs={24} sm={24} mdHidden lgHidden >
                                {content === 'Productos' && (
                                    <div>
                                        <FlexboxGrid align="middle" >
                                            <FlexboxGrid.Item colspan={13} >
                                                <h3 style={{ margin: '20px' }} >Bienvenidos</h3>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item className="order" colspan={10}>
                                                <Select
                                                    className="order"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'hotpink',
                                                            primary: '#f0f2f5',
                                                        },
                                                    })}
                                                    styles={colourStyles}
                                                    defaultValue={orden[0]}
                                                    onChange={(response) => {
                                                        setOrderType(response.value);
                                                    }}
                                                    options={orden} />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={24} >
                                                {productsList.length > 0 && (
                                                    <ProductsTable cart={[shoppingCart, setShoppingCart]} data={productsList} />
                                                )}
                                                {productsList.length === 0 && (
                                                    <h1>No hay resultados aún</h1>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </div>
                                )}
                                {(content === 'Slider' && imagesList.length > 0) && (
                                    <div style={{ width: '100%' }}>
                                        <div style={{ paddingTop: '0px' }} ><h2 >Promociones</h2></div>
                                        <Carousel autoplay shape="bar" style={{ height: '100%' }} >
                                            {imagesList.map((actual) => (
                                                <img
                                                    src={actual.name}
                                                    height="100"
                                                />
                                            ))}
                                        </Carousel>
                                    </div>
                                )}
                                {(content === 'Mis pedidos' && userDataId !== '') && (
                                    <div style={{ width: '100%' }}>

                                        <UserShipment id_user={userDataId} />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Grid>
                </div>


            </div>

            <Modal
                overflow={true}
                show={modal}
                onHide={() => { setModal(false) }}

            >
                <Modal.Header>
                    {actualModal}
                </Modal.Header>
                <Modal.Body>
                    {loading && (
                        <center> <Loader size="md" content="Consultando" /> </center>
                    )}
                    {(!loading && actualModal === "Crear Usuario") && (
                        <SignUpForm action={setModal} action2={setLoading} />
                    )}
                    {(!loading && actualModal === "Iniciar Sesión") && (
                        <LoginForm action={setModal} modalValor={setActualModal} action2={setLoading} />
                    )}
                    {(!loading && actualModal === "¿Olvidó su contraseña?") && (
                        <ForgetPasswordForm action={setModal} modalValor={setActualModal} action2={setLoading} />
                    )}
                    {(!loading && actualModal === 'Carrito de compras' && shoppingCart.length !== 0) && (
                        <ShoppingCartForm close1={setModal} close2={setModalMovil}  id_user={userDataId} modalValor={setActualModal} action={setModal} action2={setLoading} data={shoppingCart} setData={setShoppingCart} />
                    )}
                    {(!loading && actualModal === 'Carrito de compras' && (shoppingCart.length === 0)) && (
                        <h1>Lo lamento, aún no tiene productos en su carrito</h1>
                    )}
                    {(!loading && actualModal === 'Cambiar contraseña') && (
                        <ChangePasswordForm id={userDataId} closeModal={setModal} />
                    )}
                    {(!loading && actualModal === 'Ver datos de pagos') && (
                        <ShowFiscalDataToPay id={userDataId} closeModal={setModal} />
                    )}
                    {(!loading && actualModal === 'Cambiar datos fiscales') && (
                        <UpdateFiscalData id={userDataId} closeModal={setModal} />
                    )}
                    {(!loading && actualModal === 'Editar datos personales') && (
                        <ChangeUserInfo id={userDataId} closeModal={setModal} />
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                overflow={true}
                show={modalMovil}
                onHide={() => { setModalMovil(false) }}
                style={{ width: '100%' }}
            >
                <Modal.Header>
                    {actualModal}
                </Modal.Header>
                <Modal.Body>
                    {loading && (
                        <center> <Loader size="md" content="Consultando" /> </center>
                    )}
                    {(!loading && actualModal === "Crear Usuario") && (
                        <SignUpForm action={setModalMovil} action2={setLoading} />
                    )}
                    {(!loading && actualModal === "Iniciar Sesión") && (
                        <LoginForm action={setModalMovil} modalValor={setActualModal} action2={setLoading} />
                    )}
                    {(!loading && actualModal === "¿Olvidó su contraseña?") && (
                        <ForgetPasswordForm action={setModalMovil} modalValor={setActualModal} action2={setLoading} />
                    )}
                    {(!loading && actualModal === 'Carrito de compras' && shoppingCart.length !== 0) && (
                        <ShoppingCartForm close1={setModal} close2={setModalMovil} id_user={userDataId} modalValor={setActualModal} action={setModalMovil} action2={setLoading} data={shoppingCart} setData={setShoppingCart} />
                    )}
                    {(!loading && actualModal === 'Carrito de compras' && (shoppingCart.length === 0)) && (
                        <h1>Lo lamento, aún no tiene productos en su carrito</h1>
                    )}
                    {(!loading && actualModal === 'Ver datos de pagos') && (
                        <ShowFiscalDataToPay closeModal={setModalMovil} />
                    )}
                    {(!loading && actualModal === 'Cambiar contraseña') && (
                        <ChangePasswordForm id={userDataId} />
                    )}
                    {(!loading && actualModal === 'Cambiar datos fiscales') && (
                        <UpdateFiscalData id={userDataId} closeModal={setModalMovil} />
                    )}
                    {(!loading && actualModal === 'Editar datos personales') && (
                        <ChangeUserInfo id={userDataId} closeModal={setModalMovil} />
                    )}
                </Modal.Body>
            </Modal>

            <Drawer
                show={drawer}
                onHide={() => { setDrawer(false) }}
                style={{ backgroundColor: '#f0f2f5' }}
            >
                <Drawer.Header>
                    <Drawer.Title>
                        {actualDrawer}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <List hover>
                    <List.Item key='1' index='1' >
                            <div className='openMenu'>
                                <a onClick={() => {
                                    setModal(true);
                                    setActualModal('Ver datos de pagos');
                                }} >
                                    Ver datos de pagos
                                </a>
                            </div>
                        </List.Item>
                        <List.Item key='2' index='2' >
                            <div className='openMenu'>
                                <a onClick={() => {
                                    setModal(true);
                                    setActualModal('Cambiar contraseña');
                                }} >
                                    Cambiar contraseña
                                </a>
                            </div>
                        </List.Item>
                        <List.Item key='3' index='3' >
                            <div className='openMenu'>
                                <a onClick={() => {
                                    setModal(true);
                                    setActualModal('Editar datos personales');
                                }} >
                                    Editar datos personales
                                </a>
                            </div>
                        </List.Item>
                        <List.Item key='4' index='4' >
                            <div className='openMenu'>
                                <a onClick={() => {
                                    setModal(true);
                                    setActualModal('Cambiar datos fiscales');
                                }} >
                                    Cambiar datos fiscales
                                </a>
                            </div>
                        </List.Item>
                        <List.Item key='5' index='5' >
                            <div className='openMenu'>
                                <a onClick={() => {

                                    setContent('Mis pedidos');
                                }} >
                                    Mis pedidos
                                </a>
                            </div>
                        </List.Item>
                        <List.Item key='6' index='6' >
                            <div className='openMenu'>
                                <a onClick={() => {
                                    localStorage.clear();
                                    setUserDataName('');
                                    setDrawer(false);
                                }} >
                                    Cerrar Sesión
                                </a>
                            </div>
                        </List.Item>
                    </List>
                </Drawer.Body>
            </Drawer>

            <Drawer
                show={drawerMovil}
                placement="left"
                onHide={() => setDrawerMovil(false)}
                style={{ backgroundColor: '#f0f2f5', width: '100%' }}
            >
                <Drawer.Header>
                    <Drawer.Title>
                        Menú
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {userDataName === '' && (
                        <FlexboxGrid fluid align="middle">
                            <FlexboxGrid.Item colspan={12}  >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setActualModal("Iniciar Sesión");
                                    setModalMovil(true);
                                }}>Iniciar sesión</Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={12} >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setActualModal("Crear Usuario");
                                    setModalMovil(true);
                                }} >Crear cuenta</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                    {userDataName !== '' && (
                        <FlexboxGrid fluid align="middle">
                            <FlexboxGrid.Item colspan={24}  >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setContent('Slider');
                                    setDrawerMovil(false);
                                }}>{userDataName}</Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}  >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setModalMovil(true);
                                    setActualModal('Ver datos de pagos');
                                }}>Ver datos de pagos</Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}  >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setModalMovil(true);
                                    setActualModal('Cambiar contraseña');
                                }}>Cambiar Contraseña</Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24} >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setModalMovil(true);
                                    setActualModal('Editar datos personales');
                                }}>
                                    Editar datos personales.
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24} >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setModalMovil(true);
                                    setActualModal('Cambiar datos fiscales');
                                }}>
                                    Cambiar datos fiscales
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24} >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    setDrawerMovil(false);
                                    setContent('Mis pedidos');
                                }}>
                                    Mis pedidos
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}  >
                                <Button style={{ color: 'black' }} appearance="link" onClick={() => {
                                    localStorage.clear();
                                    setUserDataName('');
                                    setDrawerMovil(false);
                                }}>Cerrar sesión</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                    <Divider>Categorías</Divider>
                    <div style={{ marginTop: '10px' }}>
                        {/*<NavbarComponent contenido={setContent} section={setSearchBySection} menu={menu} />*/}
                        <NewNavbarComponent setDrawer={setDrawerMovil} contenido={setContent} leerContenido={content} section={setSearchBySection} menu={menu} />
                    </div>
                </Drawer.Body>
            </Drawer>
            {content === 'Slider' && (
                <footer>
                    <center>
                        <Grid fluid>
                            <Row>
                                <Col xsHidden smHidden md={24} >

                                    <img style={{
                                        height: '100px'
                                    }} src="/BANER.png"></img>

                                </Col>
                                <Col xs={24} sm={24} mdHidden lgHidden>
                                    <img style={{
                                        MinHeight: '35px',
                                        width: '100%'
                                    }} src="/BANER.png"></img>
                                </Col>
                            </Row>
                        </Grid>
                    </center>
                </footer>
            )}
        </div>
    );
}