import React, { useEffect, useState } from 'react';
import {
    Steps,
    Grid,
    Row,
    FlexboxGrid, 
    List,
    Icon,
    Col,
    Modal,
    Divider,
    Pagination
} from 'rsuite';


export const UserShipment = (props) =>{

    var id_user = props.id_user;
    const [shipments, setShipments] = useState([]);
    const [actualPage, setActualPage] = useState(0);
    const [usableData, setUsableData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalMovil, setModalMovil] = useState(false);

    const [actualName, setActualName] = useState('');
    const [actualPhone, setActualPhone] = useState('');
    const [actualDate, setActualDate] = useState('');
    const [actualTime, setActualTime] = useState('');
    const [actualColonia, setActualColonia] = useState('');
    const [actualCalle, setActualCalle] = useState('');
    const [actualReferencia, setActualReferencia] = useState('');
    const [actualId, setActualId] = useState('');
    const [actualBill, setActualBill] = useState('');
    const [actualList, setActualList] = useState([]);
    const [actualBussinessName, setActualBussinessName] = useState('');
    const [actualBussinessAddress, setActualBussinessAddress] = useState('');
    const [actualRfc, setActualRfc] = useState('');
    const [actualTotal, setActualTotal] = useState(0);
    const [actualEmail, setActualEmail] = useState('');
    const [actualCfdi, setActualCfdi] = useState('');
    const [actualPaymentMethod, setActualPaymentMethod] = useState('');
    const [actualStatusF, setActualStatusF] = useState('');
    

    const getMyShipments = async () =>{
        const dataToSend = new URLSearchParams("id_user="+id_user);
        const response = await fetch('/backend/mainController.php?opt=getShipmentsFromUser',{
            method: 'POST',
            body: dataToSend
        });

        const obtained = await response.json();
        setShipments(await obtained);
        fixArray(await obtained);
    }

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        fontSize: '10px',
        color: 'black'
    };

    const fixArray = (datas)  =>{
        var rows = 9;
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/rows)).toFixed();
        var sobrantes = total%rows;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setUsableData(temporal);
        //setNewData(temporal);
    }

    useState(()=>{
        getMyShipments();
    }, []);

    

    const EachItem = (props) =>{
        const item = props.item;
        const [current, setCurrent]  = useState(0);
        const [currentStatus, setCurrentStatus] = useState('process');
        const [total, setTotal] = useState(0);
        
        useEffect(()=>{
            switch(item.status){
                case "1":
                    setCurrent(0)
                break;
                case "2":
                    setCurrent(1);
                break;
                case "3":
                    setCurrent(2);
                    setCurrentStatus("finish");
                break;
                case "4":
                    setCurrentStatus("error");
                break;
                default:
                break;
            }
            var dinero =0;
            (item.list).map((actual)=>{
                dinero += Number(actual.quantity)*Number(actual.price);
            });
            setTotal(dinero);
        }, [])
        
        return(
            <div>
                <Grid fluid>
                <Col xsHidden smHidden md={24} >
                    <div 
                    onClick={()=>{
                        setActualName(item.name);
                        setActualPhone(item.phone);
                        setActualDate(item.date);
                        setActualTime(item.time);
                        setActualId(item.id_order);
                        setActualColonia(item.suburb);
                        setActualCalle(item.address);
                        setActualReferencia(item.reference);
                        setActualBill(item.need_bill);
                        if(item.need_bill != '0'){
                            setActualBussinessName(item.business_name);
                            setActualBussinessAddress(item.business_address);
                            setActualRfc(item.rfc);
                            setActualEmail(item.email);
                            switch (item.cfdi) {
                                case '1':
                                    setActualCfdi('G01 Adquisición de mercancias');
                                    break;
                                case '2':
                                    setActualCfdi('G03 Gastos en general');
                                    break;
                                case '3':
                                    setActualCfdi('I04 Equipo de computo y accesorios');
                                    break;
                                case '4':
                                    setActualCfdi('P01 Por definir');
                                    break;
                                default:
                                    break;
                            }
                            switch (item.payment_method) {
                                case '1':
                                    setActualPaymentMethod('Efectivo');
                                    break;
                                case '2':
                                    setActualPaymentMethod('Cheque nominativo');
                                    break;
                                case '3':
                                    setActualPaymentMethod('Transferencia electrónica de fondos');
                                    break;
                                case '4':
                                    setActualPaymentMethod('Tarjeta de crédito');
                                    break;
                                case '5':
                                    setActualPaymentMethod('Tarjeta de débito');
                                default:
                                    break;
                            }
                            switch (item.statusF) {
                                case '0':
                                    setActualStatusF('En proceso de facturación');
                                    break;
                                case '1':
                                    setActualStatusF('Facturado');
                                    break;
                                default:
                                    break;
                            }
                        }
                        setActualList(item.list);
                        setActualTotal(total);
                        /*const [actualPhone, setActualPhone] = useState('');
                        const [actualDate, setActualDate] = useState('');
                        const [actualTime, setActualTime] = useState('');
                        const [actualColonia, setActualColonia] = useState('');
                        const [actualCalle, setActualCalle] = useState('');
                        const [actualReferencia, setActualReferencia] = useState('');
                        const [actualId, setActualId] = useState('');
                        const [actualBill, setActualBill] = useState('');*/
                        setModal(true);
                        
                    }}
                    >
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan='2'>
                        
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid fluid>
                    <FlexboxGrid.Item colspan={1}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8} >
                        Fecha: {item.date}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8} >
                        Hora: {item.time}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                        ${total}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24} style={{width: '95%', marginLeft: '2.5%'}} >
                        <Grid fluid>
                            <Col xsHidden smHidden md={24}>
                                <Steps current={current} style={{fontSize: '10px'}} currentStatus={currentStatus} >
                                    <Steps.Item title="Preparación" icon={<Icon icon="file-text" size="lg" />} />
                                    <Steps.Item title="En camino" icon={<Icon icon="truck" size="lg" />} />
                                    <Steps.Item title="Recibido" icon={<Icon icon="check-circle" size="lg" />} />
                                </Steps>
                            </Col>
                            <Col sm={24} xs={24} mdHidden lgHidden>
                            <Steps current={current}  currentStatus={currentStatus} >
                            <Steps.Item title={<div style={{fontSize: '10px'}} >Preparación</div>  } icon={<Icon icon="file-text" size="lg" />} />
                            <Steps.Item title={<div style={{fontSize: '10px'}} >En camino</div>  } icon={<Icon icon="truck" size="lg" />} />
                            <Steps.Item title={<div style={{fontSize: '10px'}} >Recibido</div>  } icon={<Icon icon="check-circle" size="lg" />} />
                            </Steps>
                            </Col>
                        </Grid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                </div>
                </Col>
                <Col sm={24} xs={24} mdHidden lgHidden>
                <div 
                    onClick={()=>{
                        setActualName(item.name);
                        setActualPhone(item.phone);
                        setActualDate(item.date);
                        setActualTime(item.time);
                        setActualId(item.id_order);
                        setActualColonia(item.suburb);
                        setActualCalle(item.address);
                        setActualReferencia(item.reference);
                        setActualBill(item.need_bill);
                        if(item.need_bill != '0'){
                            setActualBussinessName(item.business_name);
                            setActualBussinessAddress(item.business_address);
                            setActualRfc(item.rfc);
                            setActualEmail(item.email);
                            switch (item.cfdi) {
                                case '1':
                                    setActualCfdi('G01 Adquisición de mercancias');
                                    break;
                                case '2':
                                    setActualCfdi('G03 Gastos en general');
                                    break;
                                case '3':
                                    setActualCfdi('I04 Equipo de computo y accesorios');
                                    break;
                                case '4':
                                    setActualCfdi('P01 Por definir');
                                    break;
                                default:
                                    break;
                            }
                            switch (item.payment_method) {
                                case '1':
                                    setActualPaymentMethod('Efectivo');
                                    break;
                                case '2':
                                    setActualPaymentMethod('Cheque nominativo');
                                    break;
                                case '3':
                                    setActualPaymentMethod('Transferencia electrónica de fondos');
                                    break;
                                case '4':
                                    setActualPaymentMethod('Tarjeta de crédito');
                                    break;
                                case '5':
                                    setActualPaymentMethod('Tarjeta de débito');
                                default:
                                    break;
                            }
                            switch (item.statusF) {
                                case '0':
                                    setActualStatusF('En proceso de facturación');
                                    break;
                                case '1':
                                    setActualStatusF('Facturado');
                                    break;
                                default:
                                    break;
                            }
                        }
                        setActualList(item.list);
                        setActualTotal(total);
                        /*const [actualPhone, setActualPhone] = useState('');
                        const [actualDate, setActualDate] = useState('');
                        const [actualTime, setActualTime] = useState('');
                        const [actualColonia, setActualColonia] = useState('');
                        const [actualCalle, setActualCalle] = useState('');
                        const [actualReferencia, setActualReferencia] = useState('');
                        const [actualId, setActualId] = useState('');
                        const [actualBill, setActualBill] = useState('');*/
                        setModalMovil(true);
                        
                    }}
                    >
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan='2'>
                        
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid fluid style={{fontSize: '10px'}} >
                    <FlexboxGrid.Item colspan={1}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8} >
                        Fecha: {item.date}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8} >
                        Hora: {item.time}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}>

                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                        ${total}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24} style={{width: '95%', marginLeft: '2.5%'}} >
                        <Grid fluid>
                            <Col xsHidden smHidden md={24}>
                                <Steps current={current} style={{fontSize: '10px'}} currentStatus={currentStatus} >
                                    <Steps.Item title="Preparación" icon={<Icon icon="file-text" size="lg" />} />
                                    <Steps.Item title="En camino" icon={<Icon icon="truck" size="lg" />} />
                                    <Steps.Item title="Recibido" icon={<Icon icon="check-circle" size="lg" />} />
                                </Steps>
                            </Col>
                            <Col sm={24} xs={24} mdHidden lgHidden>
                            <Steps current={current}  currentStatus={currentStatus} >
                            <Steps.Item title={<div style={{fontSize: '10px'}} >Preparación</div>  } icon={<Icon icon="file-text" size="lg" />} />
                            <Steps.Item title={<div style={{fontSize: '10px'}} >En camino</div>  } icon={<Icon icon="truck" size="lg" />} />
                            <Steps.Item title={<div style={{fontSize: '10px'}} >Recibido</div>  } icon={<Icon icon="check-circle" size="lg" />} />
                            </Steps>
                            </Col>
                        </Grid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                </div>
                </Col>
                </Grid>
            </div>
        );
    }

    const EvaluateShipments = (props) =>{
        return(
            <div>
                <List hover>

                
                {usableData[actualPage].map((actual, index)=>(
                    <List.Item>
                        <EachItem item={actual} />
                    </List.Item>
                ))}
                </List>
                <FlexboxGrid style={{...styleCenter, paddingBottom: '25px'}}>
                    <Pagination
                        first
                        ellipsis
                        last
                        boundaryLinks
                        pages={usableData.length}
                        maxButtons={5}
                        activePage={(actualPage+1)}
                        onSelect={(response)=>{
                            setActualPage(response-1);
                        }}
                    />   
                </FlexboxGrid>
            </div>
        );
    }

    return(
        <div>
            <div style={{padding: '10px', fontSize: '2em', fontWeight: 'bold'}}>
                Mis pedidos.
            </div>
            {usableData.length > 0 &&(
                <div>
                    <EvaluateShipments />
                </div>
            ) }
            <Grid fluid>
                <Row>
                    <Col xsHidden smHidden md={24} >
                        <Modal
                            show={modal}
                            size="lg"
                            onHide={()=>setModal(false)}
                        >
                            <Modal.Header>
                            <Modal.Title>Datos del pedido</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={24}>
                                        Orden: K-{actualId}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Nombre: {actualName}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Teléfono: {actualPhone}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Calle: {actualCalle}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Colonia: {actualColonia}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Referencia: {actualReferencia}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Fecha: {actualDate}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Hora: {actualTime}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <FlexboxGrid>
                                    {actualBill !== '0' && (
                                        <div style={{width: '100%'}} >
                                            <Divider>Solicitó factura</Divider>
                                            <FlexboxGrid>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Razón social: {actualBussinessName}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Dirección fiscal: {actualBussinessAddress}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Email: {actualEmail}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    RFC: {actualRfc}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    CFDI: {actualCfdi}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Tipo de pago: {actualPaymentMethod}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Estado: {actualStatusF}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </div>
                                    )}
                                    
                                    <FlexboxGrid.Item colspan={24}>
                                        <Divider>Productos</Divider>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid fluid style={{width: '100%'}}> 
                                    <FlexboxGrid.Item colspan={1} style={{marginLeft: '5px'}}  >
                                                Cant.
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2} style={{textAlign: 'center'}}>
                                                Imagen
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3} style={{textAlign: 'center'}}>
                                                Clave
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={13}>
                                                Producto
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                P.Unit.
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                P.Total
                                            </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <List fluid style={{width: '100%'}}>
                                    {actualList.map((actual, index)=>(
                                        <List.Item key={index} index={index} >
                                            <FlexboxGrid style={{width: '100%', fontSize: '12px',  display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '70px',
                                            fontSize: '12px'}}>
                                            <FlexboxGrid.Item colspan={1} style={{marginLeft: '5px'}}>
                                                {actual.quantity}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                <img src={actual.image} style={{width: '100%'}} />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3} style={{textAlign: 'center'}}>
                                                {actual.id_item}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={13}>
                                                {actual.name}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                {actual.price}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                {Number(actual.price)*Number(actual.quantity)}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                        </List.Item>

                                    ))}
                                    </List>
                                </FlexboxGrid>
                                <FlexboxGrid style={{width:  '100%'}} >
                                    <FlexboxGrid.Item colspan={22} style={{display: 'flex', textAlign: 'center', justifyContent: 'center',
                                            alignItems: 'center'}}>
                                        Total
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2}  >
                                        {actualTotal}
                                    </FlexboxGrid.Item>

                                </FlexboxGrid>
                            </Modal.Body>
                        </Modal>
                    </Col>
                    <Col xs={24} sm={24} mdHidden lgHidden >
                        <Modal
                            show={modalMovil}
                            style={{width: '100%'}}
                            onHide={()=>setModalMovil(false)}
                        >
                            <Modal.Header>
                            <Modal.Title>Datos del pedido</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={24}>
                                        Orden: K-{actualId}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Nombre: {actualName}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Teléfono: {actualPhone}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Calle: {actualCalle}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Colonia: {actualColonia}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Referencia: {actualReferencia}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Fecha: {actualDate}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        Hora: {actualTime}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <FlexboxGrid>
                                    {actualBill !== '0' && (
                                        <div style={{width: '100%'}} >
                                            <Divider>Solicitó factura</Divider>
                                            <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={24}>
                                                    Razón social: {actualBussinessName}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Dirección fiscal: {actualBussinessAddress}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Email: {actualEmail}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    RFC: {actualRfc}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    CFDI: {actualCfdi}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Tipo de pago: {actualPaymentMethod}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={24}>
                                                    Estado: {actualStatusF}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </div>
                                    )}
                                    
                                    <FlexboxGrid.Item colspan={24}>
                                        <Divider>Productos</Divider>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid fluid style={{width: '100%', fontSize: '10px'}}> 
                                    <FlexboxGrid.Item colspan={2} style={{marginLeft: '5px'}}  >
                                                Cant.
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3} style={{textAlign: 'center'}}>
                                                Imagen
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3} style={{textAlign: 'center'}}>
                                                Clave
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={10}>
                                                Producto
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3}>
                                                P.Unit.
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                P.Total
                                            </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <List fluid style={{width: '100%'}}>
                                    {actualList.map((actual, index)=>(
                                        <List.Item key={index} index={index} >
                                            <FlexboxGrid style={{width: '100%',  display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '70px',
                                            fontSize: '10px'}}>
                                            <FlexboxGrid.Item colspan={2} style={{marginLeft: '5px'}}>
                                                {actual.quantity}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3}>
                                                <img src={actual.image} style={{width: '100%'}} />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3} style={{textAlign: 'center'}}>
                                                {actual.id_item}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={10}>
                                                {actual.name}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3}>
                                                ${actual.price}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                ${Number(actual.price)*Number(actual.quantity)}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                        </List.Item>

                                    ))}
                                    </List>
                                </FlexboxGrid>
                                <FlexboxGrid style={{width:  '100%'}} >
                                    <FlexboxGrid.Item colspan={20} style={{display: 'flex', textAlign: 'center', justifyContent: 'center',
                                            alignItems: 'center'}}>
                                        Total
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2}  >
                                        ${actualTotal}
                                    </FlexboxGrid.Item>

                                </FlexboxGrid>
                            
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}