import React, { useEffect, useState } from 'react';
import {
    Grid,
    Col,
    Icon,
    FlexboxGrid,
    Whisper,
    Popover,
    Alert
} from 'rsuite';
import { UploadImageForm } from './../../components/uploadImageForm/uplpadImageForm';

export const ManagePublish = ()  =>{

    const  [publicaciones, setPublicaciones] = useState([]);

    const getAllImages = async () =>{
        const response = await fetch('/backend/mainController.php?opt=getCoverImages');
        const obtained = await response.json();
        setPublicaciones(await obtained);
    }

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        fontSize: '1.2em'
    };

    useEffect(()=>{
        getAllImages();
    }, []);

    const ItemImage = (props) =>{
        var item = props.item;

        const deleteImage = async() =>{
            var sendedData = new URLSearchParams("address=../portadas/&photo_name="+item.file);
            const response = await fetch("/backend/mainController.php?opt=deleteImage",{
                method: 'POST',
                body: sendedData
            });
            const obtained = await response.json();
            if(await obtained.response){
                Alert.success('Imagen borrada');
                return true;
            }else{
                Alert.error('algo ha salido mal, inténtelo de nuevo más tarde');
                return false;
            }
        }


        return(
            <div 
                style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'
                }} 
            >
                
                <FlexboxGrid align="middle" >
                    <FlexboxGrid.Item colspan={24}>
                        <img src={item.name} style={{width: '100%', display: 'flex'}} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{marginTop: '10px'}} colspan={24}>
                        <center>
                            <Whisper placement="bottom" trigger="hover" 
                                        speaker={((
                                            <Popover >
                                                <p>Borrar foto</p>
                                            </Popover>
                                        ))} 
                                    >
                                        <div onClick={async()=>{
                                            if(await deleteImage()){
                                                getAllImages();
                                            }
                                        }} ><Icon className="order" icon="trash-o" size="2x" /></div>
                            </Whisper>
                        </center>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        );
    }

    return(
        <div style={{padding: '10px'}} >
            <FlexboxGrid fluid>
                <FlexboxGrid.Item colspan={20}>
                <div style={{fontSize: '16px', fontWeight: 'bold', margin: '10px'}}>Publicaciones actuales</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2}>
                    <Icon icon="reload" size="2x" className="order" style={{marginTop: '10px', fontWeight: 'bold'}} onClick={()=>getAllImages()} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            

            {publicaciones.length>0 && (
                <div style={{display: 'flex', marginTop: '10px'}}>
                    <Grid fluid>
                        {publicaciones.map((item, index)=>(
                            <Col xs={24} sm={12} md={8} lg={6} style={{display: 'flex'}} >
                                <div style={{width: '100%', display: 'flex'}}>
                                    <ItemImage item={item} index={index} />
                                </div>
                            </Col>
                        ))}
                    </Grid>
                </div>
            )}
            <div>
            <div style={{fontSize: '16px', fontWeight: 'bold', margin: '10px'}}>Agregar nuevas publicaciones</div>
                <UploadImageForm  address='../portadas/' />
            </div>
        </div>
    );
}