import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import './navbar.css';
import {
    Sidenav,
    Nav,
    Dropdown,
    Icon
} from 'rsuite';


const estilosGlobales = {
    
    color: 'black',
    fontSize: '10px', 
    lineHeight: '1',
    heigth: '3px', 
    display: 'flex',
    verticalAlign: 'center',
    padding: '2px',
    padding: '0px'
};


export const NavbarComponent = (props) =>{

    const history = useHistory();
    const [defaultKeys, setDefaultKeys] = useState([]);
    const menu = props.menu;
    const setSection = props.section;
    const setContenido = props.contenido;
    const panelStyles ={
        
        backgroundColor: '#f0f2f5',

    }

    useEffect(()=>{
        var arreglo = [];
        var contador = 1;
        menu.map((actual)=>{
            if(actual.content){
                arreglo.push(""+contador);
            }
            contador++;
            setDefaultKeys(arreglo);
        });

    }, []);
    
    const CheckMultipleOptions = (props) =>{
        const cadena = props.cadena;
        const resultArray = props.arreglo;
        var contador=0;
        return(
            <>
                {resultArray.map((actual)=>{
                    contador++;
                    return(
                        <>
                            {(!actual.content && !(!actual.icon)) && (
                                <Dropdown.Item  style={{textAlignLast: 'left', display: 'flex'}} onClick={()=>{setSection(actual.value); setContenido('Productos');}} icon={actual.icon} eventKey={cadena+"-"+contador}><div style={estilosGlobales} >{actual.title}</div></Dropdown.Item>
                            )}
                             {(!actual.content && !actual.icon) && (
                                <Dropdown.Item style={{display: 'flex'}} onClick={()=>{setSection(actual.value); setContenido('Productos');}} eventKey={cadena+"-"+contador}>
                                    <div style={{...estilosGlobales, marginTop: '0px',verticalAlign: 'center' , fontSize: '10px'}} >
                                        <Icon style={{}} icon="circle" />
                                        <div style={{marginTop: '2.5px', marginLeft: '2px'}} >{actual.title}</div>
                                    </div>
                                </Dropdown.Item>
                            )}
                            {(actual.content && !(!actual.icon)) && (
                                 <Dropdown eventKey={cadena+"-"+contador} style={{display: 'flex'}} title={<div style={estilosGlobales} >{actual.title}</div>} icon={actual.icon} onClick={()=>{setSection(actual.value); setContenido('Productos')}} >
                                    <CheckMultipleOptions arreglo={actual.content} cadena={cadena+"-"+contador} />
                                 </Dropdown>
                            )}
                            {(actual.content && !actual.icon) && (
                                 <Dropdown eventKey={cadena+"-"+contador} style={estilosGlobales}  icon={<Icon icon="circle" size="1x" />} title={<div style={{...estilosGlobales, overflow: 'scrooll'}}  >{actual.title}</div>} onClick={()=>{setSection(actual.value); setContenido('Productos');}} >
                                    <CheckMultipleOptions arreglo={actual.content} cadena={cadena+"-"+contador} />
                                 </Dropdown>
                            )}
                        </>
                    );
                })}
            </>
        );
    }

    const CheckNav = (props) =>{
        const total = props.arreglo;
        var contador=0;
        return(
            <>
                {menu.map((actual)=>{
                    contador++;
                    return(
                        <>
                            { (!actual.content && !actual.icon ) && (
                                <Nav.Item key={contador} eventKey={contador} onClick={()=>{
                                    setSection(actual.value); 
                                    setContenido('Productos');
                                }}>
                                    <div style={estilosGlobales} >{actual.title}</div>
                                </Nav.Item>
                            )}
                            { (!actual.content && !(!actual.icon) ) && (
                                <Nav.Item key={contador} eventKey={contador} icon={actual.icon} onClick={()=>{setSection(actual.value); setContenido('Productos');}} >
                                    <div style={estilosGlobales} >{actual.title}</div>
                                </Nav.Item>
                            )}
                            {actual.content && (
                                <Dropdown key={contador} style={{...estilosGlobales, paddingTop: '0px', marginTop: '0px'}} eventKey={contador} title={<div style={estilosGlobales} >{actual.title}</div>} onClick={()=>{setSection(actual.value); setContenido('Productos');}} icon={actual.icon}>
                                    <CheckMultipleOptions arreglo={actual.content} cadena={''+contador} />
                                </Dropdown>
                            )}
                        </>
                    )

                })}
            </>
        )
    }

    
    
    return(
        <div>
            <Sidenav onSelect={(response)=>{
                
            }} defaultOpenKeys={defaultKeys} activeKey={defaultKeys[0]} expanded={true} >
                <Sidenav.Body>
                    <Nav style={panelStyles}  >
                            <Nav.Item  eventKey={0} icon={<Icon style={{padding: '0px', marginTop: '-10px', fontWeight: 'bold'}} icon="dashboard" />} onClick={()=>{ 
                                setContenido('Slider')
                            }} >
                            <div style={{...estilosGlobales, lineHeight: '1px', height: '0px', padding: '0px', fontWeight: 'bold'}} >KALI</div>
                            </Nav.Item>
                        <CheckNav arreglo={defaultKeys} onClick={()=>setContenido('Productos')} />
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </div>
    );
}