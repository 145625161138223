import React, { useEffect, useRef, useState } from 'react';
import {
    List,
    Modal,
    FlexboxGrid,
    Icon,
    Whisper,
    Popover,
    Pagination,
    Input,
    InputGroup,
    FormGroup,
    ButtonToolbar,
    Button,
    Loader,
    Alert,
    Grid,
    Row,
    Col,
    InputPicker
} from 'rsuite';
import { GenerateReport } from '../../components/generateReport/generateReport';
import ReactToPrint from 'react-to-print';

export const ShipmentsTable = () =>{

    const [orderShipments, setOrderShipments] = useState(1);
    const [data, setData] = useState([]);
    const [functionalData, setFunctionalData] = useState([]);
    const [modal, setModal] = useState(false);
    const [actualShipment, setActualShipment] = useState([]);
    const [actualShipmentName, setActualShipmentName] = useState('');
    const [actualIndexTable, setActualIndexTable] = useState(0);
    const [actualSearch, setActualSearch] = useState('');

    /**
     * !Si requiere factura
     */
    const [actualShipmentBill, setActualShipmentBill] = useState('0');
    const [actualShipmentBN, setActualShipmentBN] = useState('');
    const [actualShipmentBA, setActualShipmentBA] = useState('')
    const [actualrfc, setActualRfc] = useState('');

    const [modalMovil, setModalMovil] = useState(false);
    


    const [selectionShipment, setSelectionShipment] = useState();
    const [loading, setLoading] = useState(false)

    const [actualName, setActualName] = useState('');
    const [actualAddress, setActualAddress] = useState('');
    const [actualSuburb, setActualSuburb] = useState('');
    const [actualReference, setActualReference] = useState('');
    const [actualPhone, setActualPhone] = useState('');
    const [actualDate, setActualDate] = useState('');
    const [actualTime, setActualTime] = useState('');
    const [actualCfdi, setActualCfdi] = useState('');
    const [actualEmail, setActualEmail] = useState('');
    const [actualPaymentMethod, setActualPaymentMethod] = useState('');
    const [actualList, setActualList] = useState('');
    const [actualBetweenStreets, setActualBetweenStreets] = useState('');
    const [itemC, setItemC] = useState(null);



    const [temporalModal, setTemporalModal] = useState(false);
    const [mostrarPrueba, setMostrarPrueba] = useState(true);

    /**
     * ? Variables de selectores
     */
     const payment_type = [
        {value: '1', label: 'Efectivo'},
        {value: '2', label: 'Cheque nominativo'},
        {value: '3', label: 'Transferencia electrónica de fondos'},
        {value: '4', label: 'Tarjeta de crédito'},
        {value: '5', label: 'Tarjeta de débito'}
    ];

    const CFDI =  [
        {value: '1', label: 'G01 Adquisición de mercancias'},
        {value: '2', label: 'G03 Gastos en general'},
        {value: '3', label: 'I04 Equipo de computo y accesorios'},
        {value: '4', label: 'P01 Por definir'}
    ];



    /**
     * *Estilos
     */

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '52px',
        fontSize: '1.2em'
    };

    const styleCenterMovil = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '52px',
        fontSize: '10px'
    };
      
    const slimText = {
        fontSize: '0.666em',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
    };

    const slimTextMovil = {
        fontSize: '10px',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
    };
      
    const titleStyle = {
        paddingBottom: 5,
        whiteSpace: 'nowrap',
        fontWeight: 500
    };

    const titleStyleMovil = {
        paddingBottom: 5,
        
    };
      
    const dataStyle = {
        fontSize: '1.2em',
        fontWeight: 500
    };

    /**
     * *Funciones asíncronas
    */

    const getShipments = async() =>{
        
        const data = new URLSearchParams("kind="+orderShipments+"&number="+actualSearch);
        setLoading(true);
        const response = await fetch('/backend/mainController.php?opt=getShipments',{
            method: 'POST',
            body: data
        });
        
        const obtained = await response.json();
        await setData(obtained);
        await setLoading(false);
        
    }

    const refe = useRef(null);
    /**
     * *Reparación de array hacia el segundo método
     */

    const fixArray =(datas)=>{
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/9)).toFixed();
        var sobrantes = total%9;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<9; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<9; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setFunctionalData(temporal);
        
    }

    const changeStatus = async(item, status) =>{
        var sendData = new URLSearchParams("id_order="+item.id_order+"&status="+status);
        const response = await fetch("/backend/mainController.php?opt=updateShipmentStatus",{
            method: 'POST',
            body: sendData
        });
        const obtained = await response.json();
        if(obtained.response){
            Alert.success('Status cambiado');
        }else{
            Alert.error('Algo salió mal');
        }
        getShipments();
    }
    

    useEffect(()=>{
        getShipments();
    }, [orderShipments]);

    useEffect(()=>{
        //fixArray(data);
        fixArray(data);
        //console.log(data);
    }, [data])

    const ItemShipment = (props) =>{
        const item = props.item;
        return(
            <div>
            <Grid fluid>
                <Row>
                    <Col xsHidden smHidden md={24} >
                        <FlexboxGrid >
                        <FlexboxGrid.Item
                            colspan={2}
                            style={{
                            ...styleCenter,
                            flexDirection: 'column',
                            
                            overflow: 'hidden'
                            }}
                        >
                            <div>
                                K-{item.id_order}
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} style={styleCenter}>
                            {item.status === '1' && (
                                <Icon
                                    icon="file-text"
                                    style={{
                                        color: 'darkgrey',
                                        fontSize: '1.5em'
                                    }}
                                />
                            )}
                            {item.status === '2' &&(
                                <Icon
                                icon="truck"
                                style={{
                                    color: 'blue',
                                    fontSize: '1.5em'
                                }}
                            />
                            )}
                            {item.status === '3' &&(
                                <Icon
                                icon="check-circle"
                                style={{
                                    color: 'green',
                                    fontSize: '1.5em'
                                }}
                            />
                            )}
                            {item.status === '4' &&(
                                <Icon
                                icon="ban"
                                style={{
                                    color: 'red',
                                    fontSize: '1.5em'
                                }}
                            />
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                             colspan={10}
                            style={{
                                ...styleCenter,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                overflow: 'hidden'
                            }}
                        >
                            <div style={titleStyle}>{item['name']}</div>
                            <div style={slimText}>
                                <div>
                                    <Icon icon="user-circle-o" />
                                    {' ' + item['phone']}
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            colspan={4}
                            style={{
                            ...styleCenter
                            }}
                        >
                            {item['need_bill']!=='0' && (
                                <div>
                                    {item.statusF === '0' && (
                                        <div className="order" onClick={async ()=>{
                                            const url = '/backend/mainController.php?opt=updateIsInvoiced';
                                            const dataToSend = new URLSearchParams('id_order='+item.id_order+"&status=1");
                                            const response = await fetch(url,{
                                                method: 'POST',
                                                body: dataToSend
                                            });
                                            const obtained = await response.json();
                                            if(obtained.response){
                                                Alert.success('Datos cambiados');
                                                getShipments();
                                            }else{
                                                Alert.error('Ha ocurrido un error');
                                            }
                                        }} style={{color: 'red'}} >REQUIERE FACTURA</div>
                                    )}
                                    {item.statusF === '1' && (
                                        <div className="order" onClick={async ()=>{
                                            const url = '/backend/mainController.php?opt=updateIsInvoiced';
                                            const dataToSend = new URLSearchParams('id_order='+item.id_order+"&status=0");
                                            const response = await fetch(url,{
                                                method: 'POST',
                                                body: dataToSend
                                            });
                                            const obtained = await response.json();
                                            if(obtained.response){
                                                Alert.success('Datos cambiados');
                                                getShipments();
                                            }else{
                                                Alert.error('Ha ocurrido un error');
                                            }
                                            
                                        }}style={{color: 'green'}} >Facturado</div>
                                    )}
                                </div>
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={1} style={styleCenter} >
                                <div onClick={()=>{setItemC(item)}} >
                                    <ReactToPrint
                                        trigger={() => 
                                            <Whisper placement="top" trigger="hover" 
                                                speaker={((
                                                    <Popover >
                                                        <p>Descargar orden</p>
                                                    </Popover>
                                                ))} 
                                            >
                                                <Icon onClick={()=>{setItemC(item)}} icon="file-pdf-o" className="order" style={{color: 'darkgray', fontSize: '1.5em'}} />
                                            </Whisper>
                                        }
                                        content={() => refe.current}
                                    />
                                </div>
                            
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={1} style={styleCenter}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Preparación</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="file-text"
                                className="order"
                                style={{
                                    color: 'darkgrey',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '1')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={1} style={styleCenter}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>En camino</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="truck"
                                className="order"
                                style={{
                                    color: 'blue',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '2')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={1} style={styleCenter}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Entregado</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="check-circle"
                                className="order"
                                style={{
                                    color: 'green',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '3')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={1} style={styleCenter}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Cancelar</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="ban"
                                className="order"
                                style={{
                                    color: 'red',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '4')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={1} style={styleCenter}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Ver/editar</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="edit2"
                                className="order"
                                style={{
                                    color: 'darkgray',
                                    fontSize: '1.5em'
                                }}
                                onClick={()=>{
                                    setModal(true);
                                    setActualShipmentName('Orden: K-'+item['id_order']);
                                    setActualShipment(item);
                                    setActualName(item.name);
                                    setActualAddress(item.address);
                                    setActualSuburb(item.suburb);
                                    setActualReference(item.reference);
                                    setActualPhone(item.phone);
                                    setActualDate(item.date);
                                    setActualTime(item.time);
                                    setActualList(item.list);
                                    setActualBetweenStreets(item.between_streets);
                                    if(item.need_bill!='0'){
                                        setActualShipmentBN(item.business_name);
                                        setActualShipmentBA(item.business_address);
                                        setActualRfc(item.rfc);
                                        setActualEmail(item.email);
                                        setActualPaymentMethod(item.payment_method);
                                        setActualCfdi(item.cfdi);
                                    }
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Col>
                    <Col lgHidden mdHidden xs={24} sm={24} >
                    <FlexboxGrid >
                        <FlexboxGrid.Item
                            colspan={2}
                            style={{
                            ...styleCenterMovil,
                            flexDirection: 'column',
                            
                            overflow: 'hidden'
                            }}
                        >
                            <div>
                                K-{item.id_order}
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} style={styleCenterMovil}>
                            {item.status === '1' && (
                                <Icon
                                    icon="file-text"
                                    style={{
                                        color: 'darkgrey',
                                        fontSize: '1.5em'
                                    }}
                                />
                            )}
                            {item.status === '2' &&(
                                <Icon
                                icon="truck"
                                style={{
                                    color: 'blue',
                                    fontSize: '1.5em'
                                }}
                            />
                            )}
                            {item.status === '3' &&(
                                <Icon
                                icon="check-circle"
                                style={{
                                    color: 'green',
                                    fontSize: '1.5em'
                                }}
                            />
                            )}
                            {item.status === '4' &&(
                                <Icon
                                icon="ban"
                                style={{
                                    color: 'red',
                                    fontSize: '1.5em'
                                }}
                            />
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            colspan={8}
                            style={{
                            ...styleCenterMovil,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflow: 'hidden'
                            }}
                        >
                            <div style={titleStyleMovil}>{item['name']}</div>
                            <div style={slimTextMovil}>
                                <div>
                                    <Icon icon="user-circle-o" />
                                    {' ' + item['phone']}
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} style={styleCenterMovil} >
                                <div onClick={()=>{setItemC(item)}} >
                                    <ReactToPrint
                                        trigger={() => 
                                            <Whisper placement="top" trigger="hover" 
                                                speaker={((
                                                    <Popover >
                                                        <p>Descargar orden</p>
                                                    </Popover>
                                                ))} 
                                            >
                                                <Icon onClick={()=>{setItemC(item)}} icon="file-pdf-o" className="order" style={{color: 'darkgray', fontSize: '1.5em'}} />
                                            </Whisper>
                                        }
                                        content={() => refe.current}
                                    />
                                </div>
                            
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={2} style={styleCenterMovil}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Preparación</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="file-text"
                                className="order"
                                style={{
                                    color: 'darkgrey',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '1')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={2} style={styleCenterMovil}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>En camino</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="truck"
                                className="order"
                                style={{
                                    color: 'blue',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '2')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={2} style={styleCenterMovil}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Entregado</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="check-circle"
                                className="order"
                                style={{
                                    color: 'green',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '3')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={2} style={styleCenterMovil}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Cancelar</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="ban"
                                className="order"
                                style={{
                                    color: 'red',
                                    fontSize: '1.5em'
                                }}
                                onClick={ ()=>{
                                    changeStatus(item, '4')
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={2} style={styleCenterMovil}>
                            <Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Ver/editar</p>
                                    </Popover>
                                ))} 
                            >
                                <Icon
                                icon="edit2"
                                className="order"
                                style={{
                                    color: 'darkgray',
                                    fontSize: '1.5em'
                                }}
                                onClick={()=>{
                                    setModalMovil(true);
                                    setActualShipmentName('Orden: K-'+item['id_order']);
                                    setActualShipment(item);
                                    setActualName(item.name);
                                    setActualAddress(item.address);
                                    setActualSuburb(item.suburb);
                                    setActualReference(item.reference);
                                    setActualPhone(item.phone);
                                    setActualDate(item.date);
                                    setActualTime(item.time);
                                    setActualList(item.list);
                                    setActualBetweenStreets(item.between_streets);
                                    if(item.need_bill!='0'){
                                        setActualShipmentBN(item.business_name);
                                        setActualShipmentBA(item.business_address);
                                        setActualRfc(item.rfc);
                                    }
                                }}
                                />
                            </Whisper>
                        </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Col>
                </Row>
            </Grid>
            
            </div>
        );
    }

    const fixList = (index) =>{
        let temporalList = actualList;
        console.log("recibo: "+index);
        temporalList.splice(index, 1);
        console.log(temporalList);
        setActualList(temporalList);
        getShipments();
    }

    const deleteItemFromList = async (id_order_item)  =>{ 
        let dataToSend = new URLSearchParams("id="+id_order_item);
        const response = await fetch('/backend/mainController.php?opt=deleteItemFromOrder', {
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        if(obtained.response){
            Alert.success("Elemento quitado");
        }else{
            Alert.error("Algo ha salido mal");
        }
    }

    const EachItem = (propsE) =>{

        
        
        var actual = propsE.item;
        var index = propsE.index;
        const [editaleSN, setEditableSN] = useState(actual.serial_number);
        const [editablePrice, setEditablePrice] = useState(actual.price);
        

        const updateItemData = async(id_item) =>{
            let dataToSend = new URLSearchParams("id_shipment_order="+actualShipment.id_order+"&id_item="+id_item+"&price="+editablePrice+"&serial_number="+editaleSN);
            const url = '/backend/mainController.php?opt=updateSerialNumberAndPrice';
            const response = await fetch(url, {
                method: 'POST',
                body: dataToSend
            });
            const obtained = await response.json();
            if(await obtained.response){
                Alert.success("Datos cambiados");
                
            }else{
                Alert.error("Lo sentimos, algo ha salido mal");
            }
            
        }

        return(
            <div>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={5} style={styleCenter} >
                        <img src={actual.image} height="75" ></img>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={2} style={styleCenter} >
                        {actual.quantity}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={2} style={styleCenter} >
                        {actual.id_item}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4} style={{...styleCenter, justifyContent: "start", fontSize: '10px'}} >
                        {actual.name}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4} style={{...styleCenter, justifyContent: "start"}} >
                        <Input onChange={(response)=>setEditableSN(response)} defaultValue={editaleSN} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                        $<Input type="text" onChange={(response)=>setEditablePrice(response)}  defaultValue={editablePrice} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                        ${Number(actual.price)*Number(actual.quantity)}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1} style={styleCenter} >
                        <Icon icon="edit2" className="order" onClick={()=>{
                            updateItemData(actualList[index].id_order_item);
                        }} />
                        <Icon style={{marginLeft: '10px'}} className="order" icon="trash2" onClick={()=>{
                            deleteItemFromList(actualList[index].id_order_item);
                            fixList(index);
                            setMostrarPrueba(!mostrarPrueba);
                        }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
            </div>
        );
    }

    const ShowKart = (props) =>{
        
        let total = 0;

        const [priceToSend, setPriceToSend] = useState([]);
        actualList.map((actual)=>{
            total += (Number(actual.quantity)*Number(actual.price));
        });

        useEffect(()=>{
            let temporal=[];
            actualList.map((actualizado)=>{
                temporal.push(actualizado.price);
            });
            setPriceToSend(temporal);
            console.log(priceToSend);
        },[]);

        useEffect(()=>{
            let temporal=[];
            actualList.map((actualizado)=>{
                temporal.push(actualizado.price);
            });
            setPriceToSend(temporal);
            console.log(priceToSend);
        }, [actualList]);

        return(
            <div>
                <List hover>
                {actualList.length > 0 && (
                    <div>
                        {actualList.map((actual, index)=>(
                            <>
                            <List.Item>
                            <Grid fluid>
                                <Row>
                                    <Col  xsHidden smHidden md={24} >
                                        <EachItem item={actual} index={index} />
                                    </Col>
                                    <Col xs={24} sm={24} mdHidden lgHidden >
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item colspan={5} style={styleCenterMovil} >
                                            <img src={actual.image} height="35" ></img>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={2} style={styleCenterMovil} >
                                            {actual.quantity}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={3} style={styleCenterMovil} >
                                            {actual.id_item}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={5} style={{...styleCenter, justifyContent: "start",marginLeft: '5px', fontSize: '12px'}} >
                                            {actual.name}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={3} style={styleCenterMovil} >
                                            ${actual.price}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={3} style={styleCenterMovil} >
                                            ${Number(actual.price)*Number(actual.quantity)}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={2} style={styleCenter} >
                                                <Icon icon="trash2" />
                                            </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    </Col>
                                </Row>
                            </Grid>
                            </List.Item>
                        </>
                ))}
                    </div>
                )}
                </List>
                <Grid fluid>
                    <Row>
                        <Col xsHidden smHidden md={24} >
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={22} style={{...styleCenter, height: '30px'}} >
                                    Total
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={2} style={{...styleCenter, height: '30px'}} >
                                    ${total}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Col>
                        <Col xs={24} sm={24} mdHidden lgHidden>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={21} style={{...styleCenterMovil, height: '30px'}} >
                                    Total
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={2} style={{...styleCenterMovil, height: '30px'}} >
                                    ${total}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Col>
                    </Row>
                </Grid>
                
            </div>
        );
    }

    useEffect(()=>{
        
    },[actualList]);

    const [nuevaClave, setNuevaClave] = useState('');
    const [nuevoProducto, setNuevoProducto] = useState('');
    const [nuevaCantidad, setNuevaCantidad] = useState(0);
    const [nuevoPrecio, setNuevoPrecio] = useState(0);
    const [nuevoSN, setNuevoSN] = useState('-');
    const [nuevaImage, setNuevaImage] = useState('');

    

    const checkForIdProduct = async() =>{
        var dataToSend = new URLSearchParams("id_item="+nuevaClave);
        const response = await fetch('/backend/mainController.php?opt=getDataFromItem',{
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        if(obtained.response){
            setNuevoPrecio(obtained.price);
            setNuevoProducto(obtained.name);
            setNuevaImage(obtained.image);
        }else{
            setNuevoPrecio('');
            setNuevoProducto('');
            setNuevaImage('/products/0.png');
        }
    }

    const agregarProductoArray = (item) =>{
        let temporal = actualList;
        temporal.push(item);
        setActualList(temporal);
    }

    const agregarProducto = async () =>{
        var dataToSend = new URLSearchParams("id_order="+actualShipment.id_order+"&id_item="+nuevaClave+"&quantity="+nuevaCantidad+"&price="+nuevoPrecio+"&serial_number="+nuevoSN);
        if(nuevaCantidad != 0 && nuevoProducto != '' && nuevoPrecio != 0){
            const response = await fetch('/backend/mainController.php?opt=addProductToOrder',{
                method: 'POST',
                body: dataToSend
            });
            const obtained = await response.json();
            if(obtained.response){
                agregarProductoArray({
                    image: nuevaImage,
                    quantity: nuevaCantidad,
                    id_item: nuevaClave,
                    name: nuevoProducto,
                    serial_number: nuevoSN,
                    price: nuevoPrecio,
                    total: "$"+(Number(nuevoPrecio)*Number(nuevaCantidad))
                })
                Alert.success("Agregado");

            }else{
                Alert.error("Algo ha salido mal");
            }

        }else{
            Alert.error("Por favor rellene la cantidad, el producto y el precio");
        }
        /**
         * ! Regresar aquí
         */
    }

    useEffect(()=>{
        checkForIdProduct();
    }, [nuevaClave]);

    return(
        <div style={{
            paddingTop: '15px', 
            paddingBottom: '15px'
        }}>
            
            <Grid fluid>
                <Row>
                    <Col xsHidden smHidden md={24} >
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={3} >
                                <div style={{fontSize: '16px', fontWeight: 'bold', marginLeft:'20px', marginTop: '20px'}} >Pedidos</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={19} style={{...styleCenter, marginTop: '10px'}} >
                                <InputGroup >
                                    <Input placeholder="Buscar por nombre ó teléfono" onChange={(response)=>{
                                        setActualSearch(response);
                                    }} />
                                    <InputGroup.Addon>
                                        <Icon icon="search" className="order" onClick={()=>{
                                            getShipments();
                                        }} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                            <FlexboxGrid.Item className="order" style={{marginTop: '20px'}} colspan={1} onClick={()=>{
                                if(orderShipments==2){
                                    setOrderShipments(1)
                                }else{
                                    setOrderShipments(orderShipments+1);
                                }
                            }}>
                                {orderShipments === 1 && (
                                    <Whisper placement="top" trigger="hover" 
                                    speaker={((
                                        <Popover >
                                            <p>Más reciente</p>
                                        </Popover>
                                    ))} >
                                    <Icon size="2x" icon="sort-numeric-desc"/>
                                </Whisper>
                                )}
                                {orderShipments === 2 && (
                                    <Whisper placement="top" trigger="hover" 
                                        speaker={((
                                            <Popover >
                                                <p>Más antigua</p>
                                            </Popover>
                                        ))} >
                                        <Icon size="2x" icon="sort-numeric-asc"/>
                                    </Whisper>
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Col>
                    <Col xs={24} sm={24} mdHidden lgHidden >
                        <div>
                        <FlexboxGrid>
                        
                        <FlexboxGrid.Item colspan={24} >
                            <div style={{fontSize: '16px', marginLeft:'20px', fontWeight: 'bold'}} >Pedidos</div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item  colspan={21} style={{...styleCenterMovil}} >
                                <InputGroup >
                                    <Input size="xs" placeholder="Buscar por nombre ó teléfono" onChange={(response)=>{
                                        setActualSearch(response);
                                    }} />
                                    <InputGroup.Addon>
                                        <Icon icon="search" className="order" onClick={()=>{
                                            getShipments();
                                        }} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="order" colspan={1} onClick={()=>{
                                if(orderShipments==2){
                                    setOrderShipments(1)
                                }else{
                                    setOrderShipments(orderShipments+1);
                                }
                            }}>
                               <div style={{marginTop: '10px', marginLeft: '10px'}} >
                               {orderShipments === 1 && (
                                    <Whisper placement="top" trigger="hover" 
                                    speaker={((
                                        <Popover >
                                            <p>Más reciente</p>
                                        </Popover>
                                    ))} >
                                    <Icon size="2x" icon="sort-numeric-desc"/>
                                </Whisper>
                                )}
                                {orderShipments === 2 && (
                                    <Whisper placement="top" trigger="hover" 
                                        speaker={((
                                            <Popover >
                                                <p>Más antigua</p>
                                            </Popover>
                                        ))} >
                                        <Icon size="2x" icon="sort-numeric-asc"/>
                                    </Whisper>
                                )}
                               </div>
                            </FlexboxGrid.Item>
                            </FlexboxGrid>
                            </div>
                    </Col>
                </Row>
            </Grid>
            
            {functionalData.length>0 && (
                <div>
                    <List hover>
                        {functionalData[actualIndexTable].map((item, index)=>(
                            <List.Item key={item['id_order']  } index={index}  >
                                
                                    <ItemShipment item={item} />
                                
                            </List.Item>
                        ))}
                        </List>
                        <FlexboxGrid style={styleCenter} >
                            <Pagination
                                first
                                ellipsis
                                last
                                boundaryLinks
                                pages={functionalData.length}
                                maxButtons={5}
                                activePage={(actualIndexTable+1)}
                                onSelect={(response)=>{
                                    setActualIndexTable(response-1);
                                }}
                                
                            />
                            
                        </FlexboxGrid>
                        
                </div>
            )}

            {loading && (
                <Loader backdrop content="Cargando..." vertical />
            )}

            <Modal
                show={modalMovil}
                style={{width:'100%'}}
                onHide={()=>setModalMovil(false)}
            >
                <Modal.Header>
                    <Modal.Title>Orden: K-{actualShipment.id_order}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Nombre:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualName(response)} defaultValue={actualShipment.name} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Calle y Núm:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualAddress(response)} defaultValue={actualShipment.address} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Colonia:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualSuburb(response)} defaultValue={actualShipment.suburb} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Referencia:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualReference(response)} defaultValue={actualShipment.reference} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Entre calles:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualBetweenStreets(response)} defaultValue={actualShipment.between_streets} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Teléfono:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualPhone(response)} defaultValue={actualShipment.phone} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}} >
                            Fecha:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input type="date" onChange={(response)=>setActualDate(response)} defaultValue={actualShipment.date} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} style={{...styleCenter, justifyContent: 'left'}}>
                            Hora:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24} >
                            <Input onChange={(response)=>setActualTime} defaultValue={actualShipment.time} />
                        </FlexboxGrid.Item>
                        
                        {actualShipment.need_bill != '0' && (
                            <div style={{width: '100%'}}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={20} style={{...styleCenter, justifyContent: 'left'}} >
                                        Razón social
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        <Input onChange={(response)=>setActualShipmentBN(response)} defaultValue={actualShipment.business_name} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20} style={{...styleCenter, justifyContent: 'left'}} >
                                        Dirección fiscal
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        <Input onChange={(response)=>setActualShipmentBA(response)} defaultValue={actualShipment.business_address} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20} style={{...styleCenter, justifyContent: 'left'}} >
                                        RFC
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        <Input onChange={(response)=>setActualRfc(response)} defaultValue={actualShipment.rfc} />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        )}
                    </FlexboxGrid>
                    <Grid fluid>
                        <Row >
                            <Col xsHidden smHidden md={24} >
                                <FlexboxGrid style={{marginTop: '20px'}} >
                                    <FlexboxGrid.Item colspan={5} style={{...styleCenter, height: '20px'}} >
                                        Producto
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} style={{...styleCenter, height: '20px'}} >
                                        Cant.
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} style={{...styleCenter, height: '20px'}} >
                                        Clave
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={4} style={{...styleCenter, height: '20px', justifyContent: "start"}} >
                                        Nombre
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{...styleCenter, height: '20px', justifyContent: "start"}} >
                                        N. Serie
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{...styleCenter, height: '20px'}} >
                                        P.unitario
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{...styleCenter, height: '20px'}} >
                                        Total
                                    </FlexboxGrid.Item>
                                    {actualShipment.list && (
                                        <FlexboxGrid.Item colspan={24} >
                                            
                                        </FlexboxGrid.Item>
                                    )}
                                </FlexboxGrid>
                            </Col>
                            <Col xs={24} sm={24} mdHidden lgHidden >
                                <FlexboxGrid style={{marginTop: '20px'}} >
                                    <FlexboxGrid.Item colspan={5} style={{...styleCenterMovil, height: '20px'}} >
                                        Producto
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} style={{...styleCenterMovil, height: '20px'}} >
                                        Cant.
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{...styleCenterMovil, height: '20px'}} >
                                        Clave
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={5} style={{...styleCenterMovil, height: '20px', justifyContent: "start"}} >
                                        Nombre
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{...styleCenterMovil, height: '20px'}} >
                                        P.unitario
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{...styleCenterMovil, height: '20px'}} >
                                        Total
                                    </FlexboxGrid.Item>
                                    {actualShipment.list && (
                                        <FlexboxGrid.Item colspan={24} >
                                            
                                        </FlexboxGrid.Item>
                                    )}
                                </FlexboxGrid>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                <FormGroup style={{textAlign: 'right', marginTop: '30px'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={ async ()=>{
                    var sendedData = new URLSearchParams("id_order="+actualShipment.id_order+"&name="+actualName+"&address="+actualAddress+"&suburb="+actualSuburb+"&reference="+actualReference+"&phone="+actualPhone+"&time="+actualTime+"&date="+actualDate);
                    const response = await fetch('/backend/mainController.php?opt=changeShipmentData',{
                        method: 'POST',
                        body: sendedData
                    });
                    const obtained = await response.json();
                    if(obtained.response){
                        Alert.success('Datos cambiados');
                    }else{
                        Alert.error('Algo ha salido mal, inténtelo de nuevo más tarde')
                    }

                    if(actualShipment.need_bill != '0'){
                        var sendedData = new URLSearchParams('id_fiscal_shipment='+actualShipment.id_fiscal_shipment+'&business_name='+actualShipmentBN+"&business_address="+actualShipmentBA+"&rfc="+actualrfc);
                        const responseBill = await fetch('/backend/mainController.php?opt=updateBill',{
                            method: 'POST',
                            body: sendedData
                        });
                        const obtained2 = await responseBill.json();
                        if(obtained2){

                        }
                    }

                    getShipments();
                }} >Aplicar cambios</Button>
                <Button appearance="default" onClick={()=>setModalMovil(false)} >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
                </Modal.Footer>
            </Modal>

            <Modal 
                show={modal}
                size="lg"
                onHide={()=>{setModal(false); getShipments();}}
            >
                <Modal.Header>
                    <Modal.Title>Orden: K-{actualShipment.id_order}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Nombre:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualName(response)} defaultValue={actualShipment.name} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Calle y Núm:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualAddress(response)} defaultValue={actualShipment.address} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Colonia:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualSuburb(response)} defaultValue={actualShipment.suburb} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Referencia:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualReference(response)} defaultValue={actualShipment.reference} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={{...styleCenter, justifyContent: 'left'}} >
                            Entre calles:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualBetweenStreets(response)} defaultValue={actualShipment.between_streets} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Teléfono:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualPhone(response)} defaultValue={actualShipment.phone} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Fecha:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input type="date" onChange={(response)=>setActualDate(response)} defaultValue={actualShipment.date} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={styleCenter} >
                            Hora:
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20} >
                            <Input onChange={(response)=>setActualTime} defaultValue={actualShipment.time} />
                        </FlexboxGrid.Item>
                        
                        {actualShipment.need_bill != '0' && (
                            <div style={{width: '100%'}}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                                        Razón social
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20}>
                                        <Input onChange={(response)=>setActualShipmentBN(response)} defaultValue={actualShipment.business_name} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                                        Dirección fiscal
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20}>
                                        <Input onChange={(response)=>setActualShipmentBA(response)} defaultValue={actualShipment.business_address} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                                        RFC
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20}>
                                        <Input onChange={(response)=>setActualRfc(response)} defaultValue={actualShipment.rfc} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                                        Email
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20}>
                                        <Input onChange={(response)=>setActualEmail(response)} defaultValue={actualShipment.email} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                                        Tipo de pago
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20}>
                                        {/* {<Input onChange={(response)=>setActualEmail(response)} defaultValue={actualShipment.payment_method} />} */}
                                        <InputPicker style={{
                                            width: '100%'
                                        }} 
                                            data={payment_type} 
                                            placeholder={payment_type[0].label} 
                                            defaultValue={actualShipment.payment_method}  
                                            onChange={(response)=>setActualPaymentMethod(response)} 
                                            onClean={()=>setActualPaymentMethod('1')}
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={styleCenter} >
                                        CFDI
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={20}>
                                        <InputPicker style={{width: '100%'}}
                                            data={CFDI} 
                                            placeholder={CFDI[0].label}
                                            defaultValue={actualShipment.cfdi}
                                            onChange={(response)=>setActualCfdi(response)}
                                            onClean={()=>setActualCfdi('1')}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        )}
                    </FlexboxGrid>
                    <FlexboxGrid style={{marginTop: '20px'}} >
                        <FlexboxGrid.Item colspan={5} style={{...styleCenter, height: '20px'}} >
                            Producto
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} style={{...styleCenter, height: '20px'}} >
                            Cant.
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} style={{...styleCenter, height: '20px'}} >
                            Clave
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4} style={{...styleCenter, height: '20px', justifyContent: "start"}} >
                            Nombre
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4} style={{...styleCenter, height: '20px', justifyContent: "start"}} >
                            N. Serie
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={{...styleCenter, height: '20px'}} >
                            P.unitario
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} style={{...styleCenter, height: '20px'}} >
                            Total
                            
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={1}>
                            <Icon className="order"  icon="plus-circle" style={{color: 'green', fontSize: '16px'}} onClick={()=>setTemporalModal(true)} />
                        </FlexboxGrid.Item>

                        {actualList.length!=0 && (
                            <FlexboxGrid fluid style={{width: '100%'}}>
                                <FlexboxGrid.Item colspan={24} >
                                <ShowKart />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            
                        )}
                        
                    </FlexboxGrid>
                </Modal.Body>
                <Modal.Footer>
                <FormGroup style={{textAlign: 'right', marginTop: '30px'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={ async ()=>{
                    var sendedData = new URLSearchParams("id_order="+actualShipment.id_order+"&name="+actualName+"&address="+actualAddress+"&suburb="+actualSuburb+"&reference="+actualReference+"&phone="+actualPhone+"&time="+actualTime+"&date="+actualDate+"&between_streets="+actualBetweenStreets);
                    const response = await fetch('/backend/mainController.php?opt=changeShipmentData',{
                        method: 'POST',
                        body: sendedData
                    });
                    const obtained = await response.json();
                    if(obtained.response){
                        Alert.success('Datos cambiados');
                    }else{
                        Alert.error('Algo ha salido mal, inténtelo de nuevo más tarde')
                    }

                    if(actualShipment.need_bill != '0'){
                        var sendedData = new URLSearchParams('id_fiscal_shipment='+actualShipment.id_fiscal_shipment+'&business_name='+actualShipmentBN+"&business_address="+actualShipmentBA+"&rfc="+actualrfc+"&email="+actualEmail+"&payment_method="+actualPaymentMethod+"&cfdi="+actualCfdi);
                        const responseBill = await fetch('/backend/mainController.php?opt=updateBill',{
                            method: 'POST',
                            body: sendedData
                        });
                        const obtained2 = await responseBill.json();
                        if(obtained2){

                        }
                    }

                    getShipments();
                }} >Aplicar cambios</Button>
                <Button appearance="default" onClick={()=>setModal(false)} >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
                </Modal.Footer>
            </Modal>
            <Modal 
                    show={temporalModal}
                    onHide={()=>{
                        setNuevaClave('');
                        setNuevoProducto('');
                        setNuevaImage('/products/0.png');
                        setTemporalModal(false);
                    }}
            >
                <Modal.Header>
                    <Modal.Title>Nuevo producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={4}>
                            Clave
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            Cantidad
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={16}>
                            Producto
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            <Input onChange={ (response)=>{
                                
                                setNuevaClave(response);
                                
                            }} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            <Input type="number" onChange={(response)=>setNuevaCantidad(response)} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={16}>
                            <Input onChange={(response)=>setNuevoProducto(response)} placeholder={nuevoProducto} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            Precio
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20}>
                            Número de serie
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            <Input type="number" placeholder={Number(nuevoPrecio)} onChange={(response)=>setNuevoPrecio(response)} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20}>
                            <Input onChange={(response)=>setNuevoSN(response)} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    
                </Modal.Body>
                <Modal.Footer>
                    <FormGroup style={{textAlign: 'right'}} >
                        <ButtonToolbar >
                            <Button appearance="primary" onClick={()=>{
                                agregarProducto();
                            }} >Agregar</Button>
                            <Button appearance="default" onClick={()=>{
                                setNuevaClave('');
                                setNuevoProducto('');
                                setNuevaImage('/products/0.png');
                                setTemporalModal(false);
                            }} >Cancelar</Button>
                        </ButtonToolbar>
                    </FormGroup>
                </Modal.Footer>
            </Modal>
            <div style={{display: 'none'}}> <GenerateReport referencia={refe} item={itemC} /> </div>
        </div>
    );
}