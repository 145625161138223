import React,{useEffect, useState} from 'react';
import {
    Pagination,
    FlexboxGrid,
    Alert,
    Whisper,
    Popover,
    Icon,
    Grid,
    Row,
    Col
}from 'rsuite';

export const ManageProductsPhotos = () =>{

    const [productos, setProductos] = useState([]);
    const [actualPage, setActualPage] = useState(0);

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        fontSize: '1.2em'
    };

    const fixArray = (datas)  =>{
        var rows = 8
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/rows)).toFixed();
        var sobrantes = total%rows;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setProductos(temporal);
        //setNewData(temporal);
    }

    const ItemImage = (props) =>{
        var item = props.item;

        const deleteImage = async() =>{
            var sendedData = new URLSearchParams("address=../products/&photo_name="+item.file);
            const response = await fetch("/backend/mainController.php?opt=deleteImage",{
                method: 'POST',
                body: sendedData
            });
            const obtained = await response.json();
            if(await obtained.response){
                Alert.success('Imagen borrada');
                return true;
            }else{
                Alert.error('algo ha salido mal, inténtelo de nuevo más tarde');
                return false;
            }
        }


        return(
            <div 
                style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'
                }} 
            >
                
                <FlexboxGrid align="middle" >
                    <FlexboxGrid.Item colspan={24}>
                        <img src={item.name} style={{width: '100%', display: 'flex'}} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{marginTop: '10px'}} colspan={24}>
                        <center>
                            <Whisper placement="bottom" trigger="hover" 
                                        speaker={((
                                            <Popover >
                                                <p>Borrar foto</p>
                                            </Popover>
                                        ))} 
                                    >
                                        <div onClick={async()=>{
                                            if(await deleteImage()){
                                                getAllImages();
                                            }
                                        }} ><Icon className="order" icon="trash-o" size="2x" /></div>
                            </Whisper>
                        </center>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        );
    }

    const getAllImages = async () =>{
        const response = await fetch('/backend/mainController.php?opt=getProductsImages');
        const obtained = await response.json();
        fixArray(await obtained);
    }

    useEffect(()=>{
        getAllImages();
    },[])
    
    useEffect(()=>{
        console.log(productos);
    }, [productos])

    const EvaluateArray = () =>{
        return(
            
                
                <div style={{display: 'flex', marginTop: '10px'}}>
                    <Grid fluid>
                        {productos[actualPage].map((item, index)=>(
                            <Col xs={24} sm={12} md={8} lg={6} style={{display: 'flex'}} >
                                <div style={{width: '100%', display: 'flex'}}>
                                    <ItemImage item={item} index={index} />
                                </div>
                            </Col>
                        ))}
                    </Grid>
                    <FlexboxGrid style={{...styleCenter, paddingBottom: '25px'}}>
                    <Pagination
                        first
                        ellipsis
                        last
                        boundaryLinks
                        pages={productos.length}
                        maxButtons={5}
                        activePage={(actualPage+1)}
                        onSelect={(response)=>{
                            setActualPage(response-1);
                        }}
                    />   
                </FlexboxGrid>
                </div>

            
        );
    }

    return(
        <div style={{padding: '10px'}} >
            <FlexboxGrid fluid>
                <FlexboxGrid.Item colspan={20}>
                <div style={{fontSize: '16px', fontWeight: 'bold', margin: '10px'}}>Productos</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2}>
                    <Icon icon="reload" size="2x" className="order" style={{marginTop: '10px', fontWeight: 'bold'}} onClick={()=>getAllImages()} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            

            {productos.length>0 && (
                <div style={{display: 'flex', marginTop: '10px'}}>
                    <Grid fluid>
                        {productos[actualPage].map((item, index)=>(
                            <Col xs={24} sm={12} md={8} lg={6} style={{display: 'flex', height: '450px'}} >
                                <div style={{height: 'auto', width: '100%', display: 'flex'}}>
                                    <ItemImage item={item} index={index} />
                                </div>
                            </Col>
                        ))}
                    </Grid>
                    
                </div>
            )}
            <div>
            <FlexboxGrid fluid style={{width: '100%', alignContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center', display: 'flex'    }}>
                    <center>
                    <Pagination
                        first
                        ellipsis
                        last
                        boundaryLinks
                        pages={productos.length}
                        maxButtons={5}
                        activePage={(actualPage+1)}
                        onSelect={(response)=>{
                            setActualPage(response-1);
                        }}
                    />   
                    </center>
                </FlexboxGrid>
            </div>
        </div>
    );
}