import React, {useState, useEffect} from 'react';
import {
  Form,
  ControlLabel, 
  FormGroup,
  Input,
  Toggle,
  InputPicker,
  Button,
  ButtonToolbar,
  Alert,
  Animation,
  SelectPicker
} from 'rsuite';
const { Fade, Collapse, Transition } = Animation;

export const DependencyForm = ( props ) =>{

    const action = props.action;
    const setLoading = props.action2;
    const [name, setName] = useState('');
    const [isDepen, setIsDepen] = useState(false);
    const [idDepen, setIdDepen] = useState('');
    const [dataSelect, setDataSelect] = useState([]);

    const checkArray = async() =>{
        try{
        const response = await fetch('/backend/mainController.php?opt=getCategoriesLabel');
        const obtenido = await response.json();
        if(obtenido.response==false){
            setDataSelect([]);
        }else{
            setDataSelect(obtenido);
        }
        }catch(error){
        console.log(error);
        }
    }

    const addCategory = async () =>{
        if(name!=''){
        if(!isDepen || (isDepen && idDepen!='' )){
            var acepted = true;
            dataSelect.map((option)=>{
            if(option.label.toUpperCase()==name.toUpperCase()){
                acepted=false;
            }
            });
            if (acepted) {
            try{ 
                setLoading(true);
                const postData = new URLSearchParams("name="+name+"&dependency="+isDepen+"&id_dependency="+idDepen);
                const response = await fetch('/backend/mainController.php?opt=addCategory',{
                    method: 'POST',
                    body: postData
                });
                const obtained = await response.json();
                if(obtained.response==true){
                    setLoading(false);
                    //action(false);
                    Alert.success("Categoría agregada");
                    await checkArray();
                }else{
                    setLoading(false);
                    Alert.error("Hubo un error al agregar");
                }
            }catch(errores){
                setLoading(false);
                Alert.error("ha ocurrido un error");
            }
            }else{
                setLoading(false);
                Alert.error('Este campo ya existe, intente de nuevo');
            }
        }else{
            Alert.error('Por favor, asegúrese de llenar todos los campos');
        }
        
        }else{
            Alert.error("Por favor, seleccione el nombre de la dependencia");
        }
    }
    //checkArray();
    useEffect(()=>{
        checkArray();
    }, []);

    return(
        <div>
        <Form fluid>
            <FormGroup>
            <ControlLabel>Nombre: </ControlLabel>
            <Input onChange={(response)=>setName(response)} />
            </FormGroup>
            <FormGroup>
            <ControlLabel>Es subcategoría: </ControlLabel>
            <Toggle onChange={(response)=>{
                setIsDepen(response);
            }} />
            </FormGroup>
            <Collapse in={isDepen}>
            <FormGroup >
                <ControlLabel>Subcategoría de: </ControlLabel>
                <SelectPicker placeholder="Categoría" style={{width:'100%'}} onClean={()=>{setIdDepen('')}} onSelect={	(value, item, event)=>{setIdDepen(value)}} data={dataSelect} />
            </FormGroup>
            </Collapse>
            <FormGroup style={{textAlign: 'right'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={addCategory} >Enviar</Button>
                <Button appearance="default" onClick={()=>{action(false)}} >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
        </Form>
        </div> 
    );
}