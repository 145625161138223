import React, {useState, useEffect} from 'react';
import {
    Form, 
    FormGroup, 
    ControlLabel, 
    ButtonToolbar, 
    Button, 
    Input, 
    Tooltip, 
    Whisper, 
    Alert
} from 'rsuite';

export const SignUpForm = (props) =>{

    const action = props.action;
    const setLoading = props.action2;
    const [name, setName] = useState('');
    const [lastname, setLastame] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [suburb, setSuburb] = useState('');
    const [reference, setReference] = useState('');
    const [email, setEmail] = useState('');

    const [checkPasswd, setCheckPasswd] = useState(false);

    const [validUsername, setValidUser] = useState(false);

    /**
     ** Requeridos para  el uso de encriptado de datos MD5
     */
    var md5 = require('md5');
    
    useEffect(()=>{
        if(password!=password2){
            setCheckPasswd(true);
        }else{
            setCheckPasswd(false);
        }
    }, [password2, password]);



    useEffect(()=>{
        validateUser();
    }, [user]);

    const speaker = (
        <Tooltip style={{ width: 120 }}>
          Las contraseñas no coinciden.
        </Tooltip>
    );

    const speaker2 = (
        <Tooltip style={{ width: 120 }}>
          Este usuadio ya está en uso.
        </Tooltip>
    );

    const validateUser = async() =>{
        if(user!=''){
            const url = '/backend/mainController.php?opt=validateUsername';
            const dataToSend = new URLSearchParams("name="+user);
            const response = await fetch(url,{
                method: 'POST',
                body: dataToSend
            });
            const obtained = await response.json();
            console.log(obtained);
            if(await obtained.response){
                setValidUser(false);
            }else{
                setValidUser(true);
            }
        }
    }

    const addUser = async () =>{
        if(!validUsername && user!=''){
            if(password == password2 && (name!='' &&  lastname != '' && password!='' && suburb != '' )){
                await setLoading(true);
                try{
                    const data = new URLSearchParams("phone="+phone);
                    const response = await fetch('/backend/mainController.php?opt=checkNormalUser',{
                        method: 'POST',
                        body: data
                    })
                    const obtenido = await response.json();
                    if(obtenido.response){
                        const data = new URLSearchParams("name="+name+"&lastname="+lastname+"&username="+user+"&password="+md5(password)+"&phone="+phone+"&address="+address+"&suburb="+suburb+"&reference="+reference+"&email="+email);
                        const responseAdd = await fetch('/backend/mainController.php?opt=addNormalUser',{
                            method: 'POST',
                            body: data
                        })
                        const obtenidoAdd = await responseAdd.json();
                        if(obtenidoAdd.response){
                            await setLoading(false);
                            
                            Alert.success("Usuario agregado con éxito");
    
                            /**
                             * ! aquí se  loggeará
                             */
                            const dataToSend = new URLSearchParams("username="+user+"&password="+md5(password));
                            const response = await fetch('/backend/mainController.php?opt=login',{
                                method: 'POST',
                                body: dataToSend
                            });
                            const obtenido2 = await response.json();
                            if(obtenido2.response){
                                await localStorage.setItem("id_user", obtenido2.id_user);
                                await localStorage.setItem("user_type", obtenido2.user_type);
                                await localStorage.setItem("name", obtenido2.name+" "+obtenido2.lastname);
                                action(false);
                            }
    
                        }else{
                            await setLoading(false);
                            Alert.error("Ha ocurrido un error");
                        }
                    }else{
                        await setLoading(false);
                        Alert.error("Lo siento, ese número de teléfono ya está registrado");
                    }
                }
                catch(error){
                    await setLoading(false);
                    console.log(error);
                }
            }else{
                Alert.error("Verifique las contraseñas o rellene los campos faltantes");
            }
        }else{
            Alert.error("Por favor, seleccione un nombre de usuario válido");
        }
    }

    return(
        <Form fluid >
            <FormGroup>
                <ControlLabel>Nombre: </ControlLabel>
                <Input onChange={(response)=>{setName(response)}} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Apellidos: </ControlLabel>
                <Input onChange={(response)=>setLastame(response)} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Nombre de usuario: </ControlLabel>
                <Whisper
                    preventOverflow
                    speaker={speaker2}
                    placement="right"
                    open={validUsername}
                >
                    <Input onChange={(response)=>setUser(response)} />
                </Whisper>
                
            </FormGroup>
            <FormGroup>
                <ControlLabel>Número de teléfono (10 dígitos)</ControlLabel>
                <Input type="number" maxLength="10" min="1" pattern="^[0-9]+" onChange={(response)=>{
                        //setUser(response);
                        setPhone(response);
                    }} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Contraseña: </ControlLabel>
                <Input type="password" onChange={(response)=>{setPassword(response)}} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Repita la contraseña: </ControlLabel>
                <Whisper
                    preventOverflow
                    speaker={speaker}
                    placement="right"
                    open={checkPasswd}
                >
                    <Input type="password" onChange={(response)=>{
                        setPassword2(response);
                    }} />
                </Whisper>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Calle y número: </ControlLabel>
                <Input onChange={(response)=>{setAddress(response)}}  />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Colonia: </ControlLabel>
                <Input onChange={(response)=>{setSuburb(response)}}  />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Referencia: </ControlLabel>
                <Input placeholder="Opcional" onChange={(response)=>{setReference(response)}}  />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Email: </ControlLabel>
                <Input type="email" onChange={(response)=>{setEmail(response)}} />
            </FormGroup>
            <FormGroup style={{textAlign: 'right'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={addUser} >Enviar</Button>
                <Button appearance="default" onClick={()=>action(false)} >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
        </Form>
    );
}