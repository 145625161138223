import React, { useState } from 'react';
import{
    Form,
    FormGroup,
    Button,
    ButtonToolbar,
    ControlLabel,
    Input
} from 'rsuite';

import { DownloadExcelFromDate } from './../../components/downloadExcelFromDate/downloadExcelFromDate';

export const DownloadDataByDate = (props) =>{
    
    const [actualData, setActualData] = useState('');

    const Locale = {
        sunday: 'Dom',
        monday: 'Lun',
        tuesday: 'Mar',
        wednesday: 'Mie',
        thursday: 'Jue',
        friday: 'Vie',
        saturday: 'Sáb',
        ok: 'OK',
        today: 'Hoy',
        yesterday: 'Ayer',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos'
    };

    return(
        <>
            <Form fluid>
            <FormGroup>
                <ControlLabel>Fecha: </ControlLabel>
                <Input type="date" placeholder="Fecha"  locale={Locale} style={{width: '100%'}} onChange={(response)=>setActualData(response)} />
            </FormGroup>
            <FormGroup style={{textAlign: 'right'}}>
                <ButtonToolbar >
                    <DownloadExcelFromDate date_order={actualData} item={ <Button appearance="primary">Descargar</Button> } />
                    {/* {<Button appearance="primary" onClick={()=>{}} >Descargar</Button>} */}
                    <Button appearance="default" onClick={()=>{props.action(false)}} >Cancelar</Button>
                </ButtonToolbar>
            </FormGroup>
            </Form>
        </>
    );
}