import React, { useEffect, useState } from 'react';
import {
    InputPicker,
    Form,
    FormGroup,
    ControlLabel,
    Input,
    Button, 
    ButtonToolbar,
    Notification
} from 'rsuite';

export const ChangePaymentData = (props) =>{
    const [kind, setKind] = useState('1');
    const [entrada, setEntrada] = useState('');
    const [entrada2, setEntrada2] = useState('');

    const data = [
        {label: 'N° de cuenta 1', value: '1'},
        {label: 'Banco 1', value: '3'},
        {label: 'Teléfono 1', value: '2'},
        {label: 'Transferencia 1', value: '7'},
        {label: 'Oxxo 1', value: '8'},
        {label: 'N° de cuenta 2', value: '6'},
        {label: 'Banco 2', value: '5'},
        {label: 'Transferencia 2', value: '9'},
        {label: 'Oxxo 2', value: '10'},
    ];

    const getData = async() =>{
        let dataToSend = new URLSearchParams("kind="+kind);
        const response = await fetch('/backend/mainController.php?opt=getPaymentData',{
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        setEntrada(await obtained.value);
        console.log(await obtained.value);
    }

    const updateData = async() =>{
        let dataToSend = new URLSearchParams("kind="+kind+"&value="+entrada2);
        const response = await fetch('/backend/mainController.php?opt=updatePaymentData',{
            method: 'POST',
            body: dataToSend
        });
        const obtained = await response.json();
        if(obtained.response){
            Notification['success']({
                title: 'Datos cambiados',
                description: 'Datos cambiados exitosamente'
            });
            getData();
        }else{
            Notification['error']({
                title: 'Oops!',
                description: 'Algo ha salido mal, inténtelo se nuevo más tarde'
            });
            
        }
    }

    const Relleno = (obtenido) =>{
        return(<Input defaultValue={obtenido.valor} onChange={(response)=>setEntrada(response)} />);   
    }

    useEffect(()=>{
        getData();
    }, []);

    useEffect(()=>{
        console.log(kind);
        getData();
    }, [kind]);

    return( 
        <div>
            <Form fluid>
                <FormGroup>
                    <ControlLabel>
                       Dato 
                    </ControlLabel>
                    <InputPicker style={{width: '100%'}} data={data}  defaultValue="1" onChange={(option, value)=>{
                        setKind(option);  
                    }} />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>
                       Actual: {entrada}
                    </ControlLabel>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>
                        Nuevo valor
                    </ControlLabel>
                    <Input onChange={(response)=>setEntrada2(response)} />
                </FormGroup>
                <FormGroup style={{textAlign: 'right'}}>
                <ButtonToolbar >
                    <Button appearance="primary" onClick={()=>updateData()}  >Enviar</Button>
                    <Button appearance="default"  >Cancelar</Button>
                </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>  
    );
}