import React, { useEffect, useState } from 'react';
import {
    List,
    FlexboxGrid, 
    Input,
    ControlLabel,
    Col,
    InputPicker,
    Icon,
    Pagination,
    Alert
} from 'rsuite';

export const CategoriesEdiTable = () =>{
    const [categories, setCategories] = useState([]);
    const [usableArray, setUsableArray] = useState([]);
    const [actualPage, setActualPage] = useState(0);

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        fontSize: '10px',
        color: 'black'
    };

    const getAllCategories = async() =>{
        const response = await fetch('/backend/mainController.php?opt=getCategoriesLabel');
        const obtained = await response.json();
        setCategories(await obtained);
        fixArray(await obtained);
    }

    const fixArray = (datas)  =>{
        var rows = 9;
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/rows)).toFixed();
        var sobrantes = total%rows;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setUsableArray(temporal);
        //setNewData(temporal);
    }

    useEffect(()=>{
        console.log(usableArray);
    }, [usableArray]);

    const EachItem = (props) =>{
        const item = props.item;
        const [name, setName] = useState(item.role);
        const [status, setStatus] = useState(item.stat);


        const updateCategory = async () =>{
            if(name!='' && status!=''){
                let dataToSend = new URLSearchParams("id_category="+item.value+"&name="+name+"&status="+status);
                const response = await fetch('/backend/mainController.php?opt=updateCategory',{
                    method: 'POST',
                    body: dataToSend
                });
                const obtained = await response.json();

                if(obtained.response){
                    Alert.success("Cambios hechos");
                    getAllCategories();
                }else{
                    Alert.error("Ha ocurrido un error");
                }
            }else{
                Alert.error("Por favor rellene los campos correctamente");
            }
        }

        return(
            <FlexboxGrid>
                <Col xs={16} style={{display: 'flex', marginTop: '12px'}} >
                <div style={{marginTop: '7px'}} > <ControlLabel >{item.pre}</ControlLabel></div>
                <FlexboxGrid.Item >
                    <Input defaultValue={item.role} onChange={(response)=>setName(response)} />
                </FlexboxGrid.Item>
                </Col>
                <Col>
                    <div style={{marginTop: '7px'}} > <ControlLabel >Estado</ControlLabel></div>
                    <InputPicker size="xs" defaultValue={item.stat} data={[{label: 'Inactivo', value: '0'},{label: 'Activo', value: '1'}]} 
                    renderValue={(value, item)=>{
                        return(
                            <div style={{color: 'black', fontSize: '12px'}} >
                                {item.label}
                            </div>
                        );
                    }}
                    onChange={(response)=>{
                        setStatus(response);
                    }} 
                    onClean={()=>{
                        setStatus('');
                    }}
                    /> 
                </Col>
                <Col xs={1}  >
                    <center><Icon onClick={()=>{
                        updateCategory();   
                    }} className="order"  icon="edit2" size="2x" style={{marginTop: '25px', marginLeft: '25px'}} /></center>
                </Col>
            </FlexboxGrid>
        )
    }

    const GenerateCategorySpace = () =>{
        return (
            <div style={{paddingTop: '20px', paddingBottom: '10px'}} >
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={10}>
                        <div style={{fontSize: '16px', marginBottom: '20px', marginLeft: '10px'}} >Categorías</div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <List hover>
                    {usableArray[actualPage].map((actual, index)=>(
                        <div>
                            <List.Item index={index} key={index} >
                                
                                <EachItem item={actual} />
                            </List.Item>
                        </div>
                    ))}
                </List>
                <FlexboxGrid style={{...styleCenter, paddingBottom: '25px'}}>
                    <Pagination
                        first
                        ellipsis
                        last
                        boundaryLinks
                        pages={usableArray.length}
                        maxButtons={5}
                        activePage={(actualPage+1)}
                        onSelect={(response)=>{
                            setActualPage(response-1);
                        }}
                    />   
                </FlexboxGrid>
            </div>
        )

    }

    useState(()=>{
        getAllCategories();
    }, []);

    return(
        <div>
            {usableArray.length > 0 && (
                <GenerateCategorySpace />
            )}
        </div>
    );
}