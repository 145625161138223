import React, { useEffect, useState } from 'react';
import {
    List,
    FlexboxGrid,
    Icon,
    Input,
    Button,
    ButtonGroup,
    ButtonToolbar,
    FormGroup,
    Modal,
    Form,
    ControlLabel,
    Alert,
    Checkbox,
    Animation,
    Loader,
    Notification,
    Toggle,
    InputPicker,
    Divider
} from 'rsuite';
import { SignUpForm } from './../signUpForm/signUpForm';
import { LoginForm } from './../loginForm/loginForm';
const { Fade, Collapse, Transition } = Animation;


export const ShoppingCartForm = (props) => {

    var data = props.data;
    const setData = props.setData;
    const action = props.action;
    const setLoading = props.action2;
    const [userId, setUserId] = useState('');
    const [nombre, setNombre] = useState('');
    const [address, setAddress] = useState('');
    const [suburb, setSuburb] = useState('');
    const [paymentType, setPaymentType] = useState('1');
    const [cfdi, setCfdi] = useState('1');
    const [reference, setReference] = useState('');
    const [betweenStreets, setBetweenStreets] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [rnombre, setRNombre] = useState('');
    const [raddress, setRAddress] = useState('');
    const [rsuburb, setRSuburb] = useState('');
    const [rreference, setRReference] = useState('');
    const [rbetweenStreets, setRBetweenStreets] = useState('');
    const [remail, setREmail] = useState('');
    const [rphone, setRPhone] = useState('');


    const [payMethod, setPayMethod] = useState(false);

    const [cuenta, setCuenta] = useState('');
    const [banco, setBanco] = useState('');
    const [transferencia, setTransferencia] = useState('');
    const [oxxo, setOxxo] = useState('');
    const [cuenta2, setCuenta2] = useState('');
    const [banco2, setBanco2] = useState('');
    const [transferencia2, setTransferencia2] = useState('');
    const [oxxo2, setOxxo2] = useState('');
    const [numeroKali, setNumeroKali] = useState('');
    const [foraneo, setForaneo] = useState(false);

    /**
     * *Precio de pedidos
     */
    const [foreanPrice, setForeanPrice] = useState(200);
    const [localPrice, setLocalPrice] = useState(20);

    /**
     * *DATOS FISCALES
     */
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedDom, setIsCheckedDom] = useState(false);
    const [razonSocial, setRazonSocial] = useState('');
    const [direccionFiscal, setDireccionFiscal] = useState('');
    const [rfc, setRfc] = useState('');
    const payment_type = [
        { value: '1', label: 'Efectivo' },
        { value: '2', label: 'Cheque nominativo' },
        { value: '3', label: 'Transferencia electrónica de fondos' },
        { value: '4', label: 'Tarjeta de crédito' },
        { value: '5', label: 'Tarjeta de débito' }
    ];

    /**
     * !Solo para foraneos
     */

    const [estado, setEstado] = useState('San Luis Potosí');
    const [ciudad, setCiudad] = useState('Ciudad Valles');
    const [cp, setCp] = useState('79000');


    const CFDI = [
        { value: '1', label: 'G01 Adquisición de mercancias' },
        { value: '2', label: 'G03 Gastos en general' },
        { value: '3', label: 'I04 Equipo de computo y accesorios' },
        { value: '4', label: 'P01 Por definir' }
    ]

    //console.log(localStorage.getItem("id_user"));


    const getPaymentData = async () => {
        const url = '/backend/mainController.php?opt=getPaymentData';
        let datas = new URLSearchParams('kind=1');
        const response1 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained1 = await response1.json();
        setCuenta(await obtained1.value);
        datas = new URLSearchParams('kind=2');
        const response2 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained2 = await response2.json();
        setNumeroKali(await obtained2.value);
        datas = new URLSearchParams('kind=3');
        const response3 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained3 = await response3.json();
        setBanco(await obtained3.value);
        datas = new URLSearchParams('kind=7');
        const response4 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained4 = await response4.json();
        setTransferencia(await obtained4.value);
        datas = new URLSearchParams('kind=8');
        const response5 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained5 = await response5.json();
        setOxxo(await obtained5.value);
        datas = new URLSearchParams('kind=5');
        const response6 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained6 = await response6.json();
        setBanco2(await obtained6.value);
        datas = new URLSearchParams('kind=6');
        const response7 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained7 = await response7.json();
        setCuenta2(await obtained7.value);
        datas = new URLSearchParams('kind=9');
        const response8 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained8 = await response8.json();
        setTransferencia2(await obtained8.value);
        datas = new URLSearchParams('kind=10');
        const response9 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained9 = await response9.json();
        setOxxo2(await obtained9.value);

    }


    var utilizable = [];

    const requestShipment = async () => {

        if (isCheckedDom) {

            let dataTemporal = [];
            if (foraneo) {
                dataTemporal = [];
                dataTemporal = data;
                dataTemporal.push({ id: '2', product: 'Envío foraneo', quantity: 1, priceDom: foreanPrice });
            } else {
                dataTemporal = [];
                dataTemporal = data;
                dataTemporal.push({ id: '1', product: 'Envío a domicilio', quantity: 1, priceDom: localPrice });
            }

            if (nombre != '' || (rnombre != '' && rphone != '' && raddress != '' && rsuburb)) {
                setLoading(true);
                var nameSend, phoneSend, addressSend, suburbSend, referenceSend, betweenStreetsSend, emailSend;
                if (rnombre != '') {
                    nameSend = rnombre;
                } else {
                    nameSend = nombre;
                }
                if (rphone != '') {
                    phoneSend = rphone;
                } else {
                    phoneSend = phone;
                }
                if (raddress != '') {
                    addressSend = raddress;
                } else {
                    addressSend = address;
                }
                if (rsuburb != '') {
                    suburbSend = rsuburb;
                } else {
                    suburbSend = suburb;
                }
                if (rreference != '') {
                    referenceSend = rreference;
                } else {
                    referenceSend = reference;
                }
                if (rbetweenStreets != '') {
                    betweenStreetsSend = rbetweenStreets;
                } else {
                    betweenStreetsSend = betweenStreets;
                }
                if (remail == '') {
                    emailSend = remail;
                } else {
                    emailSend = email;
                }
                var sendetData = new URLSearchParams('name=a');
                if (!isChecked) {
                    sendetData = new URLSearchParams('arreglo=' + JSON.stringify(dataTemporal) + "&name=" + nameSend + "&phone=" + phoneSend + "&address=" + addressSend + "&suburb=" + suburbSend + "&reference=" + referenceSend + "&id_user=" + userId + "&between_streets=" + betweenStreetsSend);
                    if (foraneo) {
                        sendetData = new URLSearchParams('arreglo=' + JSON.stringify(dataTemporal) + "&name=" + nameSend + "&phone=" + phoneSend + "&address=" + estado + ", " + ciudad + ", C.P. " + cp + " " + addressSend + "&suburb=" + suburbSend + "&reference=" + referenceSend + "&id_user=" + userId + "&between_streets=" + betweenStreetsSend);
                    }
                } else {
                    sendetData = new URLSearchParams('arreglo=' + JSON.stringify(dataTemporal) + "&name=" + nameSend + "&phone=" + phoneSend + "&address=" + addressSend + "&suburb=" + suburbSend + "&reference=" + referenceSend + "&business_name=" + razonSocial + "&business_address=" + direccionFiscal + "&rfc=" + rfc + "&business_email=" + emailSend + "&id_user=" + userId + "&payment_method=" + paymentType + "&cfdi=" + cfdi + "&between_streets=" + betweenStreetsSend);
                    if (foraneo) {
                        sendetData = new URLSearchParams('arreglo=' + JSON.stringify(dataTemporal) + "&name=" + nameSend + "&phone=" + phoneSend + "&address=" + estado + ", " + ciudad + ", C.P. " + cp + " " + addressSend + "&suburb=" + suburbSend + "&reference=" + referenceSend + "&business_name=" + razonSocial + "&business_address=" + direccionFiscal + "&rfc=" + rfc + "&business_email=" + emailSend + "&id_user=" + userId + "&payment_method=" + paymentType + "&cfdi=" + cfdi + "&between_streets=" + betweenStreetsSend);

                    }
                }
                const response = await fetch('/backend/mainController.php?opt=addShipmentOrder', {
                    method: 'POST',
                    body: sendetData
                });
                const obtained = await response.json();
                if (obtained.response) {
                    await action(false);
                    Notification['success']({
                        duration: 5000,
                        title: 'Bien',
                        description: 'Pedido realizado con éxito'
                    });
                    props.close1(false);
                    props.close2(false);
                    setData([]);
                    await setLoading(false);

                } else {
                    Notification['error']({
                        duration: 5000,
                        title: 'Oops',
                        description: 'Lo sentimos, algo ha salido mal'
                    });
                    await setLoading(false);
                }
            } else {
                Alert.error('Por favor, rellene todos los campos');
            }
        } else {
            if (nombre != '' || (rnombre != '' && rphone != '')) {
                if (rnombre != '') {
                    nameSend = rnombre;
                } else {
                    nameSend = nombre;
                }
                if (rphone != '') {
                    phoneSend = rphone;
                } else {
                    phoneSend = phone;
                }
                let sendetData;
                if (!isChecked) {
                    sendetData = new URLSearchParams('arreglo=' + JSON.stringify(data) + "&name=" + nameSend + "&phone=" + phoneSend + "&address=Kali&suburb=Kali&reference=kali" + "&id_user=" + userId + "&between_streets=kali");
                } else {
                    sendetData = new URLSearchParams('arreglo=' + JSON.stringify(data) + "&name=" + nameSend + "&phone=" + phoneSend + "&address=Kali&suburb=Kali&reference=kali" + "&business_name=" + razonSocial + "&business_address=" + direccionFiscal + "&rfc=" + rfc + "&business_email=" + email + "&id_user=" + userId + "&payment_method=" + paymentType + "&cfdi=" + cfdi + "&between_streets=kali");
                }
                const response = await fetch('/backend/mainController.php?opt=addShipmentOrder', {
                    method: 'POST',
                    body: sendetData
                });
                const obtained = await response.json();
                if (obtained.response) {
                    Notification['success']({
                        duration: 5000,
                        title: 'Bien',
                        description: 'Pedido realizado con éxito'
                    });
                    props.close1(false);
                    props.close2(false);
                    setData([]);
                    await setLoading(false);
                } else {
                    Notification['error']({
                        duration: 5000,
                        title: 'Oops',
                        description: 'Lo sentimos, algo ha salido mal'
                    });
                    await setLoading(false);
                }
            }
        }
    }

    const getUserData = async () => {
        var nombres = null;
        var id_user = null;
        var address_user = null;
        nombres = await localStorage.getItem("name");
        id_user = await localStorage.getItem("id_user");
        if (await nombres != null) {
            setUserId(id_user);
        } else {
            setUserId('');
            setNombre('');
            setPhone('');
            setAddress('');
        }
    }

    const getDataFromClient = async () => {
        var sendetData = new URLSearchParams('id_user=' + userId);
        const response = await fetch('/backend/mainController.php?opt=getDataFromId', {
            method: 'POST',
            body: sendetData
        });
        const obtained = await response.json();
        setNombre(obtained.name);
        setAddress(obtained.address);
        setPhone(obtained.phone);
        setSuburb(obtained.suburb);
        setReference(obtained.reference);
        setBetweenStreets(obtained.between_streets);
        /**
         * *Para los datos fiscales
         */
        setRazonSocial(obtained.business_name);
        setDireccionFiscal(obtained.business_address);
        setRfc(obtained.rfc);
        setEmail(obtained.business_email);
    }

    data.map((actual) => {
        utilizable.push({
            id: actual.id,
            quantity: actual.quantity,
            product: actual.product,
            priceDom: actual.priceDom
        });
    });

    const getDefaultPrices = async() =>{
        const url = '/backend/mainController.php?opt=getDataFromItem';
        let dataToSend = new URLSearchParams('id_item=1');
        let response = await fetch(url, {
            method: 'POST',
            body: dataToSend
        });
        let obtained = await response.json();
        setLocalPrice(Number(await obtained.price));
        dataToSend = new URLSearchParams('id_item=2');
        response = await fetch(url, {
            method: 'POST',
            body: dataToSend
        });
        obtained = await response.json();
        setForeanPrice(Number(await obtained.price));

    }

    useEffect(() => {
        getDefaultPrices();
        var temporal = 0;
        data.map((actual) => {
            temporal += (Number(actual.priceDom) * Number(actual.quantity));
        });
        /*if(isCheckedDom){
            if(foraneo){
                temporal+=Number(200);
            }else{
                temporal+=Number(10);
            }
        }*/
        setTotalPrice(temporal);
    }, [props.data]);

    useEffect(() => {

        if (props.id_user == '') {
            Notification['error']({
                duration: 5000,
                title: 'Necesita Loggearse',
                description: 'Disculpe, necesita estar loggeado para continuar'
            });

        }

        getUserData();

        getPaymentData();
    }, []);

    useEffect(() => {
        if (userId != '') {
            getDataFromClient();
        }
    }, [userId]);

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '45px',
        fontSize: '10px'
    }; const { Fade, Collapse, Transition } = Animation;

    const slimText = {
        fontSize: '0.666em',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
    };

    const titleStyle = {
        paddingBottom: 5,
        whiteSpace: 'nowrap',
        fontWeight: 500
    };

    const dataStyle = {
        fontSize: '1.2em',
        fontWeight: 500
    };


    useEffect(() => {

    }, [data]);

    const EachItem = (props) => {
        const item = props.item;
        const index = props.index;

        return (
            <div>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={2} style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }} >
                        <div>{item.quantity}</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={2} style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }} >
                        <div>x</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12} style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }} >
                        <div>{item.product}</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3} style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }} >
                        <div>${item.priceDom}</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3} style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }} >
                        <div>${Number(item.priceDom) * Number(item.quantity)}</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={2} style={{
                        ...styleCenter,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }} >
                        <div className="order" onClick={() => {

                            utilizable.splice(index, 1);
                            setData(utilizable);
                        }} ><Icon icon="trash" size="2x" ></Icon></div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        );
    }

    return (
        <div>
            <div >
                <h2>Datos de envío</h2>
                <Form fluid >
                    <FormGroup>
                        <ControlLabel>Nombre</ControlLabel>
                        <Input placeholder={nombre} onChange={(response) => setRNombre(response)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Teléfono</ControlLabel>
                        <Input placeholder={phone} onChange={(response) => setRPhone(response)} />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox value="b" onChange={(value, checked) => {
                            setIsCheckedDom(!isCheckedDom);
                        }}>Envío a domicilio</Checkbox>
                    </FormGroup>
                    <Collapse in={isCheckedDom}>
                        <div>
                            <div style={{ backgroundColor: '#d4edda', display: 'flex', margin: '15px', padding: '7px', borderColor: '#c3e6cb', borderRadius: '.25rem', color: '#155724', border: '1px solid transparent' }} >
                                Recuerde que los precios de envío están sujetos a cambios
                                </div>
                            <FormGroup>
                                <Checkbox value="c" onChange={(value, checked) => {
                                    //setIsCheckedDom(!isCheckedDom);
                                    setForaneo(checked);

                                }}>Envío Fuera de Ciudad Valles</Checkbox>
                            </FormGroup>
                            <Collapse in={foraneo}>
                                <div>
                                    <FormGroup>
                                        <ControlLabel>Estado</ControlLabel>
                                        <Input onChange={(response) => setEstado(response)} placeholder={estado} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Ciudad</ControlLabel>
                                        <Input onChange={(response) => setCiudad(response)} placeholder={ciudad} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Código Postal</ControlLabel>
                                        <Input onChange={(response) => setCp(response)} placeholder={cp} />
                                    </FormGroup>
                                </div>
                            </Collapse>
                            <FormGroup style={{ marginTop: '20px' }} >
                                <ControlLabel>Calle y número</ControlLabel>
                                <Input placeholder={address} onChange={(response) => setRAddress(response)} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Colonia</ControlLabel>
                                <Input placeholder={suburb} onChange={(response) => setRSuburb(response)} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Referencia</ControlLabel>
                                <Input placeholder={reference} onChange={(response) => setRReference(response)} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Entre calles</ControlLabel>
                                <Input placeholder={betweenStreets} onChange={(response) => { setRBetweenStreets(response) }} />
                            </FormGroup>
                        </div>
                    </Collapse>
                    <FormGroup style={{ marginTop: '20px' }}>
                        <ControlLabel>Método de pago</ControlLabel>
                        {payMethod ? <ControlLabel>Pago por transferencia</ControlLabel> : <ControlLabel>Pago al recibir</ControlLabel>}
                        <Toggle onChange={(response) => { setPayMethod(response) }} />
                        <Collapse in={payMethod}>
                            <div>
                                <div style={{ backgroundColor: '#d4edda', margin: '15px', padding: '7px', borderColor: '#c3e6cb', borderRadius: '.25rem', color: '#155724', border: '1px solid transparent' }} >
                                    <div>
                                        Por favor deposite la cantidad hacia el siguiente banco: {banco}
                                    </div>
                                    <div>
                                        Cheques y depósitos: {cuenta}
                                    </div>
                                    <div>
                                        Transferencia: {transferencia}
                                    </div>
                                    <div>
                                        Oxxo: {oxxo}
                                    </div>
                                    <div>
                                        Y envíe su comprobante al whatsapp: {numeroKali}
                                    </div>
                                    <div>
                                        Gracias por su confianza y preferencia.
                                </div>
                                </div>
                                <Divider>También puede elegir</Divider>
                                <div style={{ backgroundColor: '#b8daff', margin: '15px', padding: '7px', borderColor: '#004085', borderRadius: '.25rem', color: '#004085', border: '1px solid transparent' }} >
                                    <div>
                                        Por favor deposite la cantidad hacia el siguiente banco: {banco2}
                                    </div>
                                    <div>
                                        Cheques y depósitos: {cuenta2}
                                    </div>
                                    <div>
                                        Transferencia: {transferencia2}
                                    </div>
                                    <div>
                                        Oxxo: {oxxo2}
                                    </div>
                                    <div>
                                        Y envíe su comprobante al whatsapp: {numeroKali}
                                    </div>
                                    <div>
                                        Gracias por su confianza y preferencia.
                                </div>
                                </div>
                            </div>
                        </Collapse>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox value="a" onChange={(value, checked) => {
                            setIsChecked(checked);
                        }}>Solicitar factura</Checkbox>
                    </FormGroup>
                    <Collapse in={isChecked}>
                        <div>
                            <FormGroup >
                                <ControlLabel>Razón social: </ControlLabel>
                                <Input placeholder={razonSocial} onChange={(response) => setRazonSocial(response)} />
                            </FormGroup>
                            <FormGroup >
                                <ControlLabel>Dirección fiscal: (Calle, número, Colonia, Ciudad, Estado y C.P.)</ControlLabel>
                                <Input placeholder={direccionFiscal} onChange={(response) => setDireccionFiscal(response)} />
                            </FormGroup>
                            <FormGroup >
                                <ControlLabel>RFC: </ControlLabel>
                                <Input placeholder={rfc} onChange={(response) => setRfc(response)} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Forma de pago ({payment_type[0].label} por omisión)</ControlLabel>
                                <InputPicker style={{ width: '100%' }} data={payment_type} placeholder={payment_type[0].label} defaultValue='1'
                                    onChange={(response) => {
                                        setPaymentType(response)
                                    }}
                                    onClean={() => setPaymentType('1')}
                                ></InputPicker>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Correo electrónico</ControlLabel>
                                <Input placeholder={email} onChange={(response) => setEmail(response)} />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>
                                    <ControlLabel>Tipo de gasto ({CFDI[0].label} por omisión) </ControlLabel>
                                    <InputPicker style={{ width: '100%' }} data={CFDI} placeholder={CFDI[0].label} defaultValue='1'
                                        onChange={(response) => setCfdi(response)}
                                        onClean={() => setCfdi('1')}
                                    />
                                </ControlLabel>
                            </FormGroup>
                        </div>
                    </Collapse>
                </Form>
            </div>
            <FlexboxGrid style={{ marginTop: '20px', fontSize: '10px' }} >
                <FlexboxGrid.Item colspan={2}>
                    <div>Cant</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2}>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <div>Producto</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={3}>
                    <div>P. Unitario</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={3}>
                    <div>P. Total</div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <List hover>
                {data.map((item, index) => (
                    <List.Item key={item['id']} index={index} >
                        <EachItem item={item} index={index} />
                    </List.Item>
                ))}
                {(isCheckedDom && foraneo) && (
                    <List.Item>
                        <FlexboxGrid style={{ fontSize: '10px' }}>
                            <FlexboxGrid.Item colspan={15} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                Costo de envío
                                </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={9} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                ${ foreanPrice }
                                </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
                {(isCheckedDom && !foraneo) && (
                    <List.Item>
                        <FlexboxGrid style={{ fontSize: '10px' }}>
                            <FlexboxGrid.Item colspan={15} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                Costo de envío
                                </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={9} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                ${localPrice}
                                </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
            </List>
            <FlexboxGrid style={{ fontSize: '10px', marginTop: '10px' }}>
                <FlexboxGrid.Item colspan={19} style={{ alignItems: 'center' }} >
                    <div>Total</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={3} style={{ alignItems: 'center' }} >
                    {(!foraneo && isCheckedDom) ? (
                        <div>${Number(totalPrice) + localPrice}</div>
                    ) : <></>}
                    {(foraneo && isCheckedDom) ? (
                        <div>${Number(totalPrice) + foreanPrice}</div>
                    ) : <></>}

                    {(!isCheckedDom) && (
                        <div>${totalPrice}</div>
                    )}
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <FormGroup style={{ textAlign: 'right', marginTop: '30px' }}>
                <ButtonToolbar >
                    <Button appearance="primary" onClick={() => {

                        if (isChecked) {
                            if (razonSocial != '' && direccionFiscal != '' && rfc != '' && (remail != '' || email != '')) {
                                if (props.id_user != '') {
                                    requestShipment();
                                } else {
                                    Notification['error']({
                                        duration: 5000,
                                        title: 'Necesita Loggearse',
                                        description: 'Por favor, inicie sesión para continuar'
                                    });
                                    props.modalValor('Iniciar Sesión');
                                }


                            } else {
                                console.log("Razón social: " + razonSocial);
                                console.log("dirFiscal: " + direccionFiscal);
                                console.log("RFC: " + rfc);
                                console.log("Email: " + email);
                                Alert.error("Por favor, rellene sus datos fiscales");
                            }
                        } else {
                            if (props.id_user != '') {
                                requestShipment();
                            } else {
                                Notification['error']({
                                    duration: 5000,
                                    title: 'Necesita Loggearse',
                                    description: 'Por favor, inicie sesión para continuar'
                                });
                                props.modalValor('Iniciar Sesión');
                            }
                        }
                    }}  >Enviar</Button>
                    <Button appearance="default" onClick={() => action(false)}  >Cancelar</Button>
                </ButtonToolbar>
            </FormGroup>
        </div>
    );
}