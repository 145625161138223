import './App.css';
import 'rsuite/lib/styles/index.less';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import {Index} from './pages/index/index';
import {Test} from './pages/test/test';
import { Admin } from './pages/admin/admin';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact >
          <Index/>
        </Route>
        <Route path="/test">
          <Test/>
        </Route>
        <Route path="/admin">
          <Admin/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
