import React, { useEffect, useState } from 'react';
import {Icon} from 'rsuite';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

export const NewNavbarComponent = (props) =>{
    const [actualMenu, setActualMenu] = useState([]);
    const menu = props.menu;
    const setSection = props.section;
    const setContenido = props.contenido;
    const setDrawer = props.setDrawer;


    useEffect(()=>{
        setActualMenu(props.menu);
    },[props.menu]);

    const SubMenuChild = (props) =>{
        const item = props.item;
        return(
            <div>
                <SubMenu icon={<Icon style={{fontSize: '10px'}} icon="circle" />} title={ <div style={{marginLeft: '0px'}}>{item.title}</div> } >
                    {(item.content).map((actual, index)=>(
                        <EvaluateItem item={actual} />
                    ))}
                </SubMenu>
            </div>
        );
    }

    const NormalChild = (props) =>{
        const item = props.item;
        return( <MenuItem onClick={()=>{
            setSection(item.value); 
            setContenido('Productos');
            setDrawer(false);
        }} icon={ <Icon style={{fontSize: '10px'}} icon="circle" /> } >{item.title}</MenuItem>  );
    }

    const EvaluateItem = (props) =>{
        
        const item = props.item;
        return(
            <div>
                { item.content ? 
                    <SubMenuChild item={item} />
                    :
                    <NormalChild item={item} />
                }
            </div>
        );
    }

    return(
        <div style={{width: '100%'}}>
            <ProSidebar>
                <Menu>
                    <MenuItem onClick={()=>{
                        setContenido('Slider');
                        setDrawer(false);
                    }} icon={<Icon icon="dashboard" />} >Kali</MenuItem>
                    { actualMenu.length > 0 && (
                        <div>
                            {actualMenu.map((item, index)=>(
                                <EvaluateItem item={item} />
                            ))}
                        </div>
                    )}
                </Menu>
            </ProSidebar>
        </div>
    );
}