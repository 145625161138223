import React, { useEffect, useState } from 'react';
import {
  FlexboxGrid
} from 'rsuite';
import './generateReport.css';

export const GenerateReport = (props) =>{
    const item = props.item;
    const [data, setData] = useState([]);
    const [temporalList, setTemporalList] = useState([]);
    const [newData, setNewData] = useState([]);
    const [fullTotal, setFullTotal] = useState(0);

    useEffect(()=>{
      //setData(props.item);
      //setTemporalList(item.list);
      if(item != null){
        fixArray(item.list);
      }
    },[]);

    


    useEffect(()=>{
      if(item != null){
        fixArray(item.list);
      }
    },[props.item]);

    const fixArray = (datas)  =>{
      var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/10)).toFixed();
        var sobrantes = total%10;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<10; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<10; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
      setNewData(temporal);
      
    }

    const normalStyle = {
      fontWeight: 'bold',
      fontSize: '12px'
    };

    const tableStyle = {
      textDecoration: 'underline', 
      fontSize: '10px',
      
      paddingBottom: '3px',
      border:'1px solid black',
      textAlign: 'center',
      fontWeight: 'bold'
    };

    const withOutLine  ={
      fontSize: '10px',
      marginTop: '-1px',
      border:'1px solid black',
      textAlign: 'center',
      fontWeight: 'bold'
    }

    const Header = () =>{
      return(
        <div>
          <img src="./header.png" style={{width: '100%'}} alt=""/>
          <div style={{height: '1px', backgroundColor: '#BF0411'}}></div>
          <div style={{height: '1px', backgroundColor: '#BF0411', marginTop: '1px'}}></div>  
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={3} style={normalStyle} >
              No. Cliente:
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={17} style={normalStyle} >
              {item.phone}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4} style={normalStyle} >
              No. Pedido: K-{item.id_order}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={normalStyle} >
              Nombre: 
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={21} style={normalStyle} >
              {item.name}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={normalStyle} >
              Calle y Núm:
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={5} style={normalStyle} >
              {item.address}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={10} style={{fontWeight: 'bold', fontSize: '15px', color: 'blue'}} >
              Si vas a comprar tecnología que sea la mejor
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6} style={{...normalStyle, fontSize: '10px'}} >
              {item.formated} a las {item.time}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={normalStyle} >
              Colonia:
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6} style={normalStyle} >
              {item.suburb}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={normalStyle} >
              Entre calles:
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} style={normalStyle} >
              {item.between_streets}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={normalStyle} >
              Referencia:
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={21} style={normalStyle} >
              {item.reference}
            </FlexboxGrid.Item>
            {/*<FlexboxGrid.Item colspan={24} style={normalStyle} >
              Referencia de casa: {item.reference}
            </FlexboxGrid.Item>*/}
          </FlexboxGrid>
          <FlexboxGrid style={{marginTop: '2px', width: '100%', alignContent: 'center', alignItems: 'center'}} >
            <FlexboxGrid.Item colspan={2} style={tableStyle}>
              Código.
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2} style={tableStyle}>
              Cantidad
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={10} style={tableStyle}>
              Descripción.
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2} style={tableStyle}>
              Garantía.
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4} style={tableStyle}>
              Número de serie.
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2} style={tableStyle}>
              P. Unit.
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2} style={tableStyle}>
              Importe.
            </FlexboxGrid.Item>
          </FlexboxGrid>
          
        </div>
      );
    }

    const ItemList = (props) =>{

      var fixed = [];

      (props.lista).map((actual)=>{
        fixed.push(actual);
      });
      var temporal = fixed.length;
      for(let i=0; i<(10-temporal); i++){
        fixed.push({
          id_item: '-',
          quantity: '-',
          name: '-',
          warranty: '-',
          price: '-',
          final: '-'
        });
      }


      return(
        <div>
          {(fixed).map((actual)=>(
            <div>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={2} style={withOutLine} >
                  {actual.id_item}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={withOutLine} >
                  {actual.quantity}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={10} style={{...withOutLine, textAlign: 'left',}} >
                  {(actual.name).length >= 56 && (
                    <>
                      {(actual.name).substr(0, 56)}...
                    </>
                  )}
                  {(actual.name).length < 56 && (
                    <>
                      {actual.name}
                    </>
                  )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={withOutLine} >
                  {actual.warranty !== '-' && (
                    <div>{actual.warranty} Días</div>
                  )}
                  {actual.warranty === '-' && (
                    <div>{actual.warranty}</div>
                  )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4} style={withOutLine} >
                  {actual.serial_number ? 
                    <>{actual.serial_number}</> : <>-</>
                  }
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={withOutLine} >
                  {actual.price !== '-' && (
                    <div>${actual.price}</div>
                  )}
                  {actual.price === '-' && (
                    <div>{actual.price}</div>
                  )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={withOutLine} >
                  {actual.final !== '-' && (
                    <div>{actual.final}</div>
                  )}
                  {actual.final === '-' && (
                    <div>{actual.final}</div>
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>
          ))}
        </div>
      );
    }

    const Footer = (props) =>{
      var total = 0;
      
      (item.list).map((actual)=>{
        total+=Number(actual.price)*Number(actual.quantity);
      });
      return(
        <div>
          <FlexboxGrid style={{marginTop: '2px'}}>
            <FlexboxGrid.Item colspan={21} >

            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={{textAlign: 'right', border:'2px solid black', fontWeight: 'bold', fontSize: '14px'}} >
              { newData.length === (props.index)+1  ? 
                <>
                  ${total}
                </>:
                <>
                 ⠀
                </>
               }
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <div style={{width: '100%'}} ><img style={{width: '100%'}} src="./footer.png" alt=""/></div>
        </div>
      )
    }

    const ExistData = () =>{
      return(
        <div>
          {newData.map((actual, index)=>(
            <div className="page">
              <Header/>
              <ItemList lista={actual} />
              <Footer lista={actual} index={index} full={newData} />
              
              <Header/>
              <ItemList lista={actual} />
              <Footer lista={actual} index={index} full={newData} />
            </div>
          ))}
        </div>
      );
    }

    return(
      <div ref={props.referencia}  style={{}} >
        <center>
        {(newData.length>0 && item !== null ) && (
            <ExistData />
        )}
        </center>
      </div>
    );
}