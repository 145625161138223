import React, {useState, useEffect} from 'react';

import { useHistory } from "react-router-dom";

import { 
    Button,
    FormGroup,
    ControlLabel,
    Input,
    Form,
    Alert,
    ButtonToolbar
} from 'rsuite';

export const LoginForm = (props) =>{
    const action = props.action;
    const setLoading = props.action2;
    var md5 = require('md5');
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const loginUser = async () =>{
        await setLoading(true);
        try{
            const data = new URLSearchParams("username="+user+"&password="+md5(password));
            const response = await fetch('/backend/mainController.php?opt=login',{
                method: 'POST',
                body: data
            })
            const obtenido = await response.json();
            if(obtenido.response){
                Alert.success("Bienvenido "+obtenido.name);
                
                await localStorage.setItem("id_user", obtenido.id_user);
                await localStorage.setItem("user_type", obtenido.user_type);
                await localStorage.setItem("name", obtenido.name+" "+obtenido.lastname);
                if(obtenido.user_type==1){
                    history.replace("/admin");
                }
                await setLoading(false);
                await action(false);
            }else{
                Alert.error("Lo sentimos, usuario y/o contraseña inválidos, intente de nuevo");
                await setLoading(false);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    return(
        <Form fluid>
            <FormGroup>
                <ControlLabel>Usuario</ControlLabel>
                <Input placeholder="usuario" onChange={(entry)=>{
                    setUser(entry);
                }} />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Contraseña</ControlLabel>
                <Input placeholder="contraseña" type="password" 
                    onChange={(entry)=>{
                        setPassword(entry);
                    }}
                    onKeyDown={(response)=>{
                        if(response.key  === 'Enter' && user!='' ){
                            loginUser();
                        }
                    } }
                />
            </FormGroup>
            <FormGroup style={{textAlign: 'right', marginTop: '-20px'}}>
                <Button onClick={()=>{props.modalValor('¿Olvidó su contraseña?')}} appearance="link">¿Olvidó su contraseña?</Button>
                <Button onClick={()=>{props.modalValor('Crear Usuario')}} appearance="link">Aún no estás registrado? regístrate</Button>
            </FormGroup>
            <FormGroup style={{textAlign: 'right'}}>
            <ButtonToolbar >
                <Button appearance="primary" onClick={loginUser} >Enviar</Button>
                <Button appearance="default" onClick={()=>action(false)} >Cancelar</Button>
            </ButtonToolbar>
            </FormGroup>
        </Form>
    );

}