import React, { useState } from 'react';
import { PersonalDataChange } from './../../components/personalDataChange/personalDataChange';
import { DownloadExcel } from './../../components/downloadExcel/downloadExcel';
import { 
  Popover,
  Icon,
  Whisper
} from 'rsuite';

export const Test = () => {

  return (
    <div style={{ width: '400px' }} >
      <DownloadExcel item={<Whisper placement="top" trigger="hover"
        speaker={((
          <Popover >
            <p>Descargar catálogo</p>
          </Popover>
        ))} >
        <Icon style={{ marginTop: '20px' }} icon="download" size="2x" className="order" />
      </Whisper>} />
    </div>
  );
}