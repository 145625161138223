import React, {useState, useEffect} from 'react';
import {
    Button,
    Modal,
    Loader,
    Container,
    Header,
    Grid,
    Row,
    Col,
    Sidebar,
    Sidenav,
    Nav,
    Icon,
    Dropdown,
    Drawer, 
    List,
    FlexboxGrid,
    Whisper,
    Popover
} from 'rsuite';
import { DependencyForm } from './../../components/dependencyForm/dependencyForm';
import { AddUser } from './../../components/addUser/addUser';
import { useHistory } from "react-router-dom";
import { ProductsTable } from './../../components/productsTable/productsTable';
import {NavbarComponent } from './../../components/navbarcomponent/navbarcomponent';
import './admin.css';
import { UploadExcelProducts } from '../../components/uploadExcelProducts/uplpadExcelProducts';
import { ShipmentsTable } from './../../components/shipmentsTable/shipmentsTable';
import { UploadImageForm } from './../../components/uploadImageForm/uplpadImageForm';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { ProdusctEditableTable } from './../../components/productsEditableTable/productsEditableTable';
//import { UploadImageForm } from './../../components/uploadImageForm/uplpadImageForm';
import { ManagePublish } from './../../components/managePublish/managePublish';
import { CategoriesEdiTable } from "./../../components/categoriesEdiTable/categoriesEdiTable";

import { ManageProductsPhotos } from './../../components/manageProductsPhotos/manageProductsPhotos';

import { DownloadExcel } from './../../components/downloadExcel/downloadExcel';
import { ChangePaymentData } from './../../components/changePaymentData/changePaymentData';
import { UsersTable } from './../../components/usersTable/usersTable';
import { DownloadExcelCategories } from './../../components/downloadExcelCategories/downloadExcelCategories';
import { ChangePrincipalFooterForm } from './../../components/changePrincipalFooterForm/changePrincipalFooterForm';
import { ChangePasswordForm } from './../../components/changePasswordForm/changePasswordForm';
import { DownloadDataByDate } from './../../components/downloadDataByDate/downloadDataByDate';



export const Admin = () =>{
    const history = useHistory();
    const [modalValue, setModalValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState("");
    const [name, setName] = useState("");
    const [menu, setMenu] = useState([]);

    const [content, setContent] = useState('Lista de pedidos');

    const checkValidation = async()=>{
        if(await localStorage.getItem("user_type")!='1'){
            history.replace("/");
        }
    }

    useEffect(()=>{
        checkValidation();
    },[]);

    /**
     * * Móvil
     */
    const [modalMovil, setModalMovil] = useState(false);
    const [drawerMovil, setDrawerMovil] = useState(false);
    //console.log( localStorage.getItem("id_user") );
    //setName(localStorage.removeItem("id_user"));

    const checkMenu  = async () =>{
        const response = await fetch('/backend/mainController.php?opt=getCategoriesByOrder');
        const obtained = await response.json();
        setMenu(obtained);
    }

    const holaMundo = () =>{
        //console.log("hola mundo");
    }

    const getShipments = async () =>{
        
    }

    const menuLateral = 
    [
        {title: 'Inicio', operation: ()=>{history.replace("/")}}, 

        {title: 'Agregar categoría', operation: ()=>{
            setOpenModal(true);
            setModalValue('Agregar categoría');
        }},
        {title: 'Editar Categorías', operation: ()=>{
            setContent('Editar Categorías');
        }},
        {title: <DownloadExcelCategories item={<div>Obtener lista de categorías</div>} />, operation: ()=>{
            
        }},
        {title: 'Lista de productos', operation: ()=>{
            setContent('Lista de productos');
            setOpenDrawer(false);
        }},
        {title: 'Cambiar pie de página', operation: ()=>{
            setOpenModal(true);
            setModalValue('Cambiar pie de página');
        }},
        {title: 'Cambiar cabecera de órdenes', operation: ()=>{
            setOpenModal(true);
            setModalValue('Subir cabecera de órdenes');
        }},
        {title: 'Cambiar pie de página de órdenes', operation: ()=>{
            setOpenModal(true);
            setModalValue('Subir pie de página de órdenes');
        }},
        {title: 'Agregar imágenes de productos', operation: ()=>{
            setOpenModal(true);
            setModalValue('Agregar imágenes de productos');
        }},
        {title: 'Ver fotos de productos', operation: ()=>{
            setContent('Ver fotos de productos');
        }},
        {title: 'Agregar lista de productos', operation: ()=>{
            setOpenModal(true);
            setModalValue('Agregar lista de productos');
        }},
        {title: 'Lista de pedidos', operation: ()=>{
            setOpenDrawer(false);
            setContent('Lista de pedidos');
        }},
        {title: 'Descargar información de productos pedidos por fecha', operation: ()=>{
            setOpenModal(true);
            setModalValue('Descargar información de productos pedidos por fecha');
        }},
        {title: 'Cambiar información de pago', operation: ()=>{
            setOpenModal(true);
            setModalValue('Cambiar información de pago');
        }},
        {title: 'Agregar usuario', operation: ()=>{
            setOpenModal(true);
            setModalValue('Agregar usuario');
        }},
        {title: 'Ver usuarios', operation: ()=>{
            setOpenDrawer(false);
            setContent('Ver usuarios');
        }},
        {title: 'Manejar fotos de portada', operation: ()=>{            
            setContent('Manejar fotos de portada');
            setOpenDrawer(false);
        }},
        {title: 'Cambiar Contraseña', operation: ()=>{
            setOpenModal(true);
            setModalValue('Cambiar Contraseña');
        }},
        {title: 'Cerrar sesión', operation: ()=>{
            localStorage.removeItem("id_user");
            localStorage.removeItem("user_type");
            localStorage.removeItem("name");
            localStorage.clear();
            history.replace("/");
        }}
    ];


    const menuLateralMovil = 
    [
        {title: 'Inicio', operation: ()=>{history.replace("/")}}, 
        {title: 'Agregar categoría', operation: ()=>{
            setModalMovil(true);
            setModalValue('Agregar categoría');
        }},
        {title: 'Editar Categorías', operation: ()=>{
            setContent('Editar Categorías');
        }},
        {title: 'Lista de productos', operation: ()=>{
            setContent('Lista de productos');
            setDrawerMovil(false);
        }},
        {title: <DownloadExcelCategories item={<div>Obtener lista de categorías</div>} />, operation: ()=>{
            
        }},
        {title: 'Cambiar pie de página', operation: ()=>{
            setModalMovil(true);
            setModalValue('Cambiar pie de página');
        }},
        {title: 'Cambiar cabecera de órdenes', operation: ()=>{
            setModalMovil(true);
            setModalValue('Subir cabecera de órdenes');
        }},
        {title: 'Cambiar pie de página de órdenes', operation: ()=>{
            setModalMovil(true);
            setModalValue('Subir pie de página de órdenes');
        }},
        {title: 'Agregar imágenes de productos', operation: ()=>{
            setModalMovil(true);
            setModalValue('Agregar imágenes de productos');
        }},
        {title: 'Ver fotos de productos', operation: ()=>{
            setContent('Ver fotos de productos');
        }},
        {title: 'Agregar lista de productos', operation: ()=>{
            setModalMovil(true);
            setModalValue('Agregar lista de productos');
        }},
        {title: 'Lista de pedidos', operation: ()=>{
            setDrawerMovil(false);
            setContent('Lista de pedidos');
        }},
        {title: 'Descargar información de productos pedidos por fecha', operation: ()=>{
            setModalMovil(true);
            setModalValue('Descargar información de productos pedidos por fecha');
        }},
        {title: 'Cambiar información de pago', operation: ()=>{
            setModalMovil(true);
            setModalValue('Cambiar información de pago');
        }},
        {title: 'Agregar usuario', operation: ()=>{
            setModalMovil(true);
            setModalValue('Agregar usuario');
        }},
        {title: 'Ver usuarios', operation: ()=>{
            setDrawerMovil(false);
            setContent('Ver usuarios');
        }},
        {title: 'Manejar fotos de portada', operation: ()=>{
            setDrawerMovil(false);
            setContent('Manejar fotos de portada');
        }},

        {title: 'Cambiar Contraseña', operation: ()=>{
            setModalMovil(true);
            setModalValue('Cambiar Contraseña');
        }},
        {title: 'Cerrar sesión', operation: ()=>{
            localStorage.removeItem("id_user");
            localStorage.removeItem("user_type");
            localStorage.removeItem("name");
            localStorage.clear();
            history.replace("/");
        }}
    ];

    

    const getDataMenu = async() =>{
        const response = await fetch('/backend/mainController.php?opt=getCategories');
        const obtained = await response.json();
        if(!obtained.response){
            //console.log(obtained);
        }else{
            //console.log(obtained);
        }
    }

    useEffect(()=>{
        getDataMenu();
        //localStorage.removeItem("id_user");
        setName(""+localStorage.getItem("name"));
        setUser(""+localStorage.getItem("id_user"));
        checkMenu();
        //localStorage.removeItem("user_type");
    }, []);

    return(
        <div>
            <div>
                <div className="header" style={{ backgroundColor: '#4CAF50'}} >
                    <Grid fluid>
                        <Row className="show-grid" >
                            <Col xs={4} sm={4} mdHidden lgHidden >
                                <img className="order" style={{marginLeft: '5px', marginTop: '5px', height: '50px'}} onClick={()=>setContent('Slider')} src="./logokali.svg" ></img>
                            </Col>
                            <Col xs={10} sm={10} mdHidden lgHidden >
                                
                            </Col>
                            <Col xs={10} sm={10}  mdHidden lgHidden>    
                                <div style={{marginTop: '15px'}} >
                                <a 
                                    className="openMenu"
                                    style={{
                                        textDecoration: 'none',
                                        color: 'white',
                                        fontSize: '20px',
                                        textAlign: 'right',
                                    }}
                                    onClick={()=>{
                                        setDrawerMovil(true);
                                    }}
                                >
                                    {name}
                                </a>
                                </div>
                            </Col>
                            <Col xsHidden smHidden md={4}  >
                                <img className='order' style={{marginLeft: '70px', marginTop: '15px', marginBottom: '15px', height: '35px'}} onClick={()=>{
                                    
                                }} src="./kalilogo.svg" />
                            </Col>
                            <Col xsHidden smHidden md={16} >

                            </Col>
                            <Col xsHidden smHidden md={4} >
                                <div style={{marginTop: '15px'}}>
                                    <a 
                                        className="openMenu"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                            fontSize: '20px',
                                        }}
                                        onClick={()=>{
                                            setOpenDrawer(true)
                                        }}
                                    >
                                        {name}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <div>
                    <Grid fluid>
                        <Row>
                            <Col xsHidden smHidden md={24} >
                                <div style={{display: 'flex'}} >
                                    <div style={{width: '250px', marginTop: '20px'}} >
                                        
                                    </div>
                                    <div className="cuerpo" style={{ width: '80%'}}>
                                        <div className="contenido" style={{borderRadius: '25px', width: '99%', margin: '10px'}} >
                                            {content == 'Lista de pedidos' && (
                                                <ShipmentsTable/>
                                            )}
                                            {content == 'Lista de productos' && (
                                                <ProdusctEditableTable />
                                            )}
                                            {content === 'Manejar fotos de portada' && (
                                                <ManagePublish />
                                            )}
                                            {content === 'Editar Categorías' && (
                                                <CategoriesEdiTable />
                                            )}
                                            {content === 'Ver fotos de productos' && (
                                                <ManageProductsPhotos />
                                            )}
                                            {content === 'Ver usuarios' && (
                                                <UsersTable />
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div style={{width: '250px', backgroundColor: '#f0f2f5'}} ></div>
                                </div>
                            </Col>
                            {/* Para móviles */}
                            <Col xs={24} sm={24} lgHidden mdHidden >
                                <div>
                                <div className="cuerpo" style={{ width: '100%'}}>
                                        
                                            {content === 'Lista de pedidos' && (
                                                <ShipmentsTable/>
                                            )}
                                            {content === 'Lista de productos' && (
                                                <ProdusctEditableTable />
                                            )}
                                            {content === 'Manejar fotos de portada' && (
                                                <ManagePublish />
                                            )}
                                            {content === 'Editar Categorías' && (
                                                <CategoriesEdiTable />
                                            )}
                                            {content === 'Ver fotos de productos' && (
                                                <ManageProductsPhotos />
                                            )}
                                            {content === 'Ver usuarios' && (
                                                <UsersTable />
                                            )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
            <Modal
                show={openModal}
                onHide={()=>setOpenModal(false)}
            >
                <Modal.Header>{modalValue}</Modal.Header>
                <Modal.Body>
                    {loading && (
                        <center> <Loader size="md" content="Consultando" /> </center>
                    )}
                    {!loading && modalValue==='Agregar categoría' && (
                        <DependencyForm action={setOpenModal} action2={setLoading} />
                    )}
                    {!loading && modalValue==='Descargar información de productos pedidos por fecha'&& (
                        <DownloadDataByDate action={setOpenModal} />
                    )}
                    {!loading && modalValue==='Agregar usuario' && (
                        <AddUser action={setOpenModal} action2={setLoading} />
                    )}
                    {!loading && modalValue==='Agregar lista de productos' && (
                        <UploadExcelProducts action={setOpenModal} />
                    )}
                    {!loading && modalValue==='Agregar imágenes de productos' && (
                        <UploadImageForm address="../products/"  modalClose={setOpenModal}/>
                    )}
                    {!loading && modalValue==='Subir cabecera de órdenes' && (
                        <div>
                            <div style={{marginLeft: '10px', fontSize: '16px', fontWeight: 'bold'}} >
                                Nueva cabecera de pedido
                            </div>
                            <UploadImageForm address="../" filename="header"  modalClose={setOpenModal}/>
                        </div>
                    )}
                    {!loading && modalValue==='Subir pie de página de órdenes' && (
                        <div>
                            <div style={{marginLeft: '10px', fontSize: '16px', fontWeight: 'bold'}} >
                                Nuevo pie de página de pedido
                            </div>
                            <UploadImageForm address="../" filename="footer"  modalClose={setOpenModal}/>
                        </div>
                    )}
                    {!loading && modalValue=='Cambiar información de pago' && (
                        <ChangePaymentData />
                    )}
                    {!loading && modalValue=='Cambiar Contraseña' && (
                        <ChangePasswordForm id={user} />
                    )}
                    {!loading && modalValue=='Cambiar pie de página' && (
                        <UploadImageForm address="../" filename="BANER"  modalClose={setOpenModal}/>
                    )}
                    
                </Modal.Body>
            </Modal>

            <Modal
                show={modalMovil}
                onHide={()=>setModalMovil(false)}
                style={{width: '100%'}}
            >
                <Modal.Header>{modalValue}</Modal.Header>
                <Modal.Body>
                    {loading && (
                        <center> <Loader size="md" content="Consultando" /> </center>
                    )}
                    {!loading && modalValue=='Agregar categoría' && (
                        <DependencyForm action={setModalMovil} action2={setLoading} />
                    )}
                    {!loading && modalValue==='Descargar información de productos pedidos por fecha'&& (
                        <DownloadDataByDate action={setModalMovil} />
                    )}
                    {!loading && modalValue=='Agregar usuario' && (
                        <AddUser action={setModalMovil} action2={setLoading} />
                    )}
                    {!loading && modalValue=='Agregar lista de productos' && (
                        <UploadExcelProducts action={setModalMovil} />
                    )}
                    {!loading && modalValue=='Agregar imágenes de productos' && (
                        <UploadImageForm address="../products/" modalClose={setModalMovil} />
                    )}
                    {!loading && modalValue=='Cambiar información de pago' && (
                        <ChangePaymentData />
                    )}
                    {!loading && modalValue=='Cambiar pie de página' && (
                        <ChangePrincipalFooterForm />
                    )}
                </Modal.Body>
            </Modal>

            <Drawer
                show={openDrawer}
                onHide={()=>{setOpenDrawer(false)}}
                style={{backgroundColor: '#f0f2f5'}}
            >
                <Drawer.Header>
                    <Drawer.Title>
                        {name}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <List hover >
                        {menuLateral.map(({title, operation}, index)=>(
                            <List.Item key={index} index={index} onClick={operation} >
                                <div className="openMenu" >
                                    <a onClick={operation} >{title}</a>
                                </div>
                            </List.Item>
                        ))}
                    </List>
                </Drawer.Body>
            </Drawer>

            <Drawer
                show={drawerMovil}
                onHide={()=>{setDrawerMovil(false)}}
                style={{backgroundColor: '#f0f2f5', width: '100%'}}
                placement="left"
            >
                <Drawer.Header>
                    <Drawer.Title>
                        {name}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <List hover >
                        {menuLateralMovil.map(({title, operation}, index)=>(
                            <List.Item key={index} index={index} onClick={operation} >
                                <div className="openMenu" >
                                    <a onClick={operation} >{title}</a>
                                </div>
                            </List.Item>
                        ))}
                    </List>
                </Drawer.Body>
            </Drawer>
        </div>
    );
}