import React, { useState, useEffect } from 'react';

import ReactExport from "react-data-export";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const DownloadExcelFromDate = (props) =>{

    const [item, setItem] = useState(<></>);
    const [data, setData] = useState([]);

    const getAllData = async() => {
        const dateToSearch = await new URLSearchParams('date_order='+props.date_order);
        const response = await fetch('/backend/mainController.php?opt=getProductsByDate', {
            method: 'POST',
            body: dateToSearch
        });
        const obtained =  await response.json();
        setData(await obtained);
    }

    var d = new Date();

    /*useEffect(()=>{
        if(props.item){
            setItem(props.item);
        }
        getAllData();
    },[]);*/

    useEffect(()=>{
        if(props.item){
            setItem(props.item);
        }
        getAllData();
    }, [props.date_order]);

    return(
        <>
            <ExcelFile filename={"Descarga de Compras "+props.date_order} element={ item } >
                <ExcelSheet data={data} name="Comprados">
                    <ExcelColumn label="ORDEN" value="id_order"/>
                    <ExcelColumn label="PRODUCTO" value="id_item"/>
                    <ExcelColumn label="CANTIDAD" value="quantity"/>
                    <ExcelColumn label="PRECIO" value="price"/>
                </ExcelSheet>
            </ExcelFile>
        </>
    );
}