import React, { useEffect, useRef, useState } from 'react';
import ReactExport from "react-data-export";
import {Button, Icon} from 'rsuite';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const DownloadExcelCategories = (props) =>{

    const [item, setItem] = useState(<></>);
    const [data, setData] = useState([]);

    const getAllData = async() => {
        const response = await fetch('/backend/mainController.php?opt=getCategoriesJson');
        const obtained =  await response.json();
        setData(await obtained);
    }
    var d = new Date();

    useEffect(()=>{
        if(props.item){
            setItem(props.item);
        }
        getAllData();
    },[])
    return(
        <div>
            <ExcelFile filename={"Categorías "+d.getDate()+"-"+(d.getMonth()+1)+"-"+d.getFullYear()} element={ item } >
                <ExcelSheet data={data} name="Categorías">
                    <ExcelColumn label="CLAVE" value="id_category"/>
                    <ExcelColumn label="NOMBRE" value="name"/>
                    <ExcelColumn label="DEPENDE DE:" value="dependOf"/>
                </ExcelSheet>
            </ExcelFile>
        </div>
    )
}