import { duration } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { 
    Uploader, 
    Form, 
    ControlLabel, 
    FormGroup,
    Button,
    ButtonToolbar,
    Notification,
    Loader
} from 'rsuite';
import * as XLSX from 'xlsx';

export const UploadExcelProducts =(props)=>{

    const setArreglo = props.arreglo;
    const action = props.action;
    const [loading, setLoading] = useState(false);
    //const setLoading = props.action2;
    const [data, setData] = useState([]);
    const [disabled, setDisabled] = useState(false);
    
    const uploadItems = async (datas) =>{

      console.log(datas);

      datas.map(async (actual)=>{
        let clave = '', nombre='', notas='', pgral=0, ptienda=0, pdomicilio=0, cantidad=0, seccion=0, estado=0, foto='0.png', garantia=0;
        clave = actual.CLAVE;
        if(actual.NOMBRE){
          nombre = actual.NOMBRE;
        }
        if(actual.NOTAS){
          notas=actual.NOTAS;  
        }
        if(actual.PGRAL){
          pgral=actual.PGRAL;
        }
        if(actual.PTIENDA){
          ptienda = actual.PTIENDA;
        }
        if(actual.PDOMICILIO){
          pdomicilio=actual.PDOMICILIO;
        }
        if(actual.CANTIDAD){
          cantidad=actual.CANTIDAD;
        }
        if(actual.SECCION){
          seccion=actual.SECCION;
        }
        if(actual.ESTADO){
          estado=actual.ESTADO;
        }
        if(actual.FOTO){
          foto = actual.FOTO;
        }
        if(actual.GARANTIA){
          garantia = actual.GARANTIA;
        }
        let url = "/backend/mainController.php?opt=addProduct";
        await setLoading(false);
        let cuerpo = await new URLSearchParams("id="+clave+"&name="+nombre+"&image="+foto+"&note="+notas+"&general_price="+pgral+"&registred_price="+ptienda+"&delivery_price="+pdomicilio+"&quantity="+cantidad+"&warranty="+garantia+"&section="+seccion+"&status="+estado);
        console.log(await cuerpo);
        const response = await fetch(url, {
          method: 'POST',
          body: cuerpo
        });
        const obtenido = await response.json();
        console.log(obtenido);
        
      });
      
      /*var url = "/backend/mainController.php?opt=addProduct";
      setLoading(true);
      console.log(datas);
      datas.map( async (actual)=>{
        var urlImages="";
        var clave, name, image, note, general_price, registred_price, delivery_price, quantity, warranty, section, status;
        clave = actual.CLAVE;
        name  = actual.NOMBRE;

        if(actual.FOTO=='' || actual.FOTO==null){
          image = urlImages+'0.jpg';
        }else{
          image = urlImages+actual.FOTO;
        }

        if(actual.NOTAS==''){
          note = '' ;
        }else{
          note = actual.NOTAS;
        }

        if(actual.CANTIDAD==''){
          quantity = '0';
        }else{
          quantity = actual.CANTIDAD;
        }

        if(actual.GARANTIA==''){
          warranty = '0';
        }else{
          warranty = actual.GARANTIA;
        }

        section = actual.SECCION;
        
        if(actual.ESTADO==''){
          status = '0';
        }else{
          status = actual.ESTADO;
        }
        general_price = actual.PGRAL;
        registred_price = actual.PTIENDA;
        delivery_price = actual.PDOMICILIO;
        //var clave, name, image, note, general_price, registred_price, delivery_price, quantity, warranty, section, status;
        var sendetData = new URLSearchParams("id="+clave+"&name="+name+"&image="+image+"&note="+note+"&general_price="+general_price+"&registred_price="+registred_price+"&delivery_price="+delivery_price+"&quantity="+quantity+"&warranty="+warranty+"&section="+section+"&status="+status);
        
        if(clave && name && image && note && general_price && registred_price && delivery_price && quantity && warranty && section && status){
          try{
            const response = await fetch(url, {
              method: 'POST',
              body: await sendetData
            });
            const obtenido = await response.json();
            console.log(obtenido);
          } catch(error){         
            Notification['error']({
              title: 'Oops',
              duration: 20000,
              description: 'Al parecer ocurrió un error con la clave: '+clave+".\nPuede estar repetida, por favor, revísela"
            })          
          }
        }

      });
      setLoading(false);
      Notification['success']({
        title: 'Terminado',
        duration: 5000,
        description: 'Archivo subido por completo'
      })*/
    }

    const readExcel = (file) =>{
        const promise = new Promise((resolve, reject)=>{
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file)
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, {type: 'buffer'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          };
          fileReader.onerror = (error) =>{
            reject(error);
          };
        });
        promise.then((d)=>{
          setData(d);
          console.log(data);
          //console.log(data);
          //uploadItems(d);
        });
    }
    const [cambios, setCambios] = useState(0);

    useEffect(()=>{
        
    }, [disabled])


    const styles = {
        lineHeight: '200px'
      };
    return(
        <div>    
          {loading && (
            <center><Loader size="md" content="Consultando" /></center>
          )}
          {!loading && (
            <div>
              <ControlLabel>
                  Por favor, suba el archivo de los productos
              </ControlLabel>
              <Uploader 
                disabled={disabled} 
                autoUpload={false} 
                onChange={(e)=>{
                  if(e.length>0){
                    readExcel(e[e.length-1].blobFile);
                    setDisabled(true);
                    setData([]);
                  }else{
                    setDisabled(false);
                      
                  }
                  setCambios(cambios+1);
                }} 
                draggable>
                  <div style={styles}>Arrastre el archivo excel, o clickee para busucarlo</div>
              </Uploader>
              <FormGroup style={{textAlign: 'right'}}>
                <ButtonToolbar >
                    <Button appearance="primary" onClick={()=>{
                      uploadItems(data);
                    }}  >Cargar</Button>
                    <Button appearance="default" onClick={()=>action(false)} >Cancelar</Button>
                </ButtonToolbar>
                </FormGroup>
            </div>
          )}
        </div>
    );
}


