import React, { useEffect, useState } from 'react';
import { Grid } from 'react-virtualized';

import {
    List,
    Modal,
    FlexboxGrid,
    Icon,
    Whisper,
    Popover,
    Pagination,
    Input,
    InputGroup,
    FormGroup,
    ButtonToolbar,
    Button,
    Loader,
    Alert,
    SelectPicker,
    Row,
    Col,
    Grid as Con
} from 'rsuite';
import { UploadImageForm } from '../uploadImageForm/uplpadImageForm';
import { DownloadExcel } from './../../components/downloadExcel/downloadExcel';

export const ProdusctEditableTable = () =>{

    /**
     * *Estados 
     */
    const [categories, setCategories] = useState([]);
    const [word, setWord] = useState('');
    const [products, setProducts] = useState([]);
    const [orderData, setOrderData] = useState(1);
    const [functionalData, setFunctionalData] = useState([]);
    const [actualPage, setActualPage] = useState(0);

    /**
     * *Para modal's
     */
    const [modal, setModal] = useState(false);
    const [modalMovil, setModalMovil] = useState(false);

    const [actualShipmentToSend, setActualShipmentToSend] = useState('');

    /**
     * *Inicialización de estados
     */
    useEffect(()=>{
        getProducts();
    }, []);

    useEffect(()=>{
        fixArray(products);
        
    }, [products]);

    useEffect(()=>{
        getProducts();
        console.log(categories)
    }, [orderData]);

    useEffect(()=>{
        console.log(functionalData);
    }, [functionalData]);

    /**
     * *Estilos
     */

    const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        fontSize: '10px',
        color: 'black'
    };

    const styleCenterMovil = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '52px',
        fontSize: '10px'
    };
      
    const slimText = {
        fontSize: '0.666em',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
    };

    const slimTextMovil = {
        fontSize: '10px',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
    };
      
    const titleStyle = {
        paddingBottom: 5,
        whiteSpace: 'nowrap',
        fontWeight: 500
    };

    const titleStyleMovil = {
        paddingBottom: 5,
        
    };
      
    const dataStyle = {
        fontSize: '1.2em',
        fontWeight: 500
    };

    /**
     * *Funciones asíncronas
     */
    const getProducts = async () =>{
        const gettingCategories = await fetch("/backend/mainController.php?opt=getCategoriesLabel");
        const responseCategories = await gettingCategories.json();
        setCategories(await responseCategories)
        const data = await new URLSearchParams("order="+orderData+"&word="+word);
        const response = await fetch("/backend/mainController.php?opt=getProductsAdmin", {
            method: 'POST',
            body: data
        });
        const obtained = await response.json();
        setProducts(await obtained);
    }
    
    /**
     * *Funciones normales
     */

     const fixArray = (datas)  =>{
        var rows = 9;
        var temporal = [];
        var temporal2 = [];
        var total = datas.length;
        var veces = (Math.trunc(total/rows)).toFixed();
        var sobrantes = total%rows;
        var contador=0;
        if(sobrantes>0){
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(
                        datas[contador]
                    );
                    contador++;
                }
                temporal.push(temporal2);
            }
            temporal2=[];
            for(let i=0; i<sobrantes; i++){
                temporal2.push(datas[contador]);
                contador++;
            }
            temporal.push(temporal2);
        }else{
            for(let i=0; i<veces; i++){
                temporal2=[];
                for(let j=0; j<rows; j++){
                    temporal2.push(datas[contador]);
                    contador++;
                }
                temporal.push(temporal2);
            }
        }
        setFunctionalData(temporal);  
        //setNewData(temporal);
    }

    const ItemProduct = (props) =>{
        
        var item = props.item;
        const [clave, setClave] = useState(item.id_item);
        const [name, setName] = useState(item.name);
        const [note, setNote] = useState(item.note);
        const [generalPrice, setGeneralPrice] = useState(item.general_price);
        const [registredPrice, setRegistredPrice] = useState(item.registred_price);
        const [deliveryPrice, setDeliveryPrice] = useState(item.delivery_price);
        const [image, setImage] = useState(item.image);
        const [quantity, setQuantity] = useState(item.quantity);
        const [warranty, setWarranty] = useState(item.warranty);
        const [section, setSection] = useState(item.section);
        const [status, setStatus] = useState(item.status);

        

        const modifyItem = async() =>{
            var dataPost = await new URLSearchParams("id_item="+clave+"&name="+name+"&note="+note+"&general_price="+generalPrice+"&registred_price="+registredPrice+"&delivery_price="+deliveryPrice+"&image="+image+"&quantity="+quantity+"&warranty="+warranty+"&section="+section+"&status="+status);
            const hereResponse = await fetch("/backend/mainController.php?opt=uploadeItemData",{
                method: 'POST',
                body: dataPost
            });
            const responsed = await hereResponse.json();
            if(await responsed.response){
                Alert.success("Cambios guardados");
            }else{
                Alert.error("Ha ocurrido un error");
            }
            getProducts();
        }

        return(
            <div>
                <Con fluid>
                    <Row>
                        <Col xsHidden smHidden md={24} >
                            <div style={{display: 'flex'}}>
                            <FlexboxGrid style={{width: '3%'}}>
                            <div  > 
                                <img style={{height: '60%', position: 'absolute', alignItems: 'center', alignSelf: 'center', alignContent: 'center', marginTop: '20px'}} onClick={()=>{
                                        setActualShipmentToSend(item.id_item);
                                        setModal(true);
                                }} className="order" src={item.image} alt=""/>
                                </div>
                            </FlexboxGrid>
                            <FlexboxGrid style={{width: '97%'}}>
                                <FlexboxGrid.Item
                                    colspan={1}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                </FlexboxGrid.Item>
                                
                                <FlexboxGrid.Item
                                    colspan={2}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                        colspan={1}
                                        style={{
                                            ...styleCenter,
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <div>Clave:</div>
                                        <div style={{width: '90%'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.id_item} onChange={(response)=>setClave(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={4}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Nombre:</div>
                                    <div style={{width: '90%'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.name} onChange={(response)=>setName(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={4}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Nota:</div>
                                    <div style={{width: '90%'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.note} onChange={(response)=>setNote(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={2}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Precio general:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.general_price} onChange={(response)=>setGeneralPrice(response)}  /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={2}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Precio registrado:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.registred_price} onChange={(response)=>setRegistredPrice(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={2}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Precio a domicilio:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.delivery_price} onChange={(response)=>setDeliveryPrice(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={1}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Cantidad:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.quantity} onChange={(response)=>setQuantity(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={1}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Garantía:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.warranty} onChange={(response)=>setWarranty(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={4}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={3}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={10}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden',
                                        marginTop: '10px'
                                    }}
                                >
                                    <div>Categoría:</div>
                                    <div style={{width: '90%', color: 'black'}} >
                                        <SelectPicker style={{width: '100%', color: 'black'}} size="xs" data={categories} placeholder="categoría" defaultValue={item.section} renderValue={(value, item)=>{
                                            return(
                                                <div style={{color: 'black', fontSize: '12px'}} >
                                                    {item.label}
                                                </div>
                                            );
                                        }}
                                        onChange={(value, item, event)=>{
                                            setSection(value);
                                        }} />
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={3}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden',
                                        marginTop: '10px'
                                    }}
                                >
                                    <div>Estado de producto:</div>
                                    <div style={{width: '90%', color: 'black'}} >
                                        <SelectPicker style={{width: '100%'}} size="xs" data={[{value: '1', label: 'Activo', role: 'Activo'}, {value: '0', label: 'Inactivo', role: 'Inactivo'}]} placeholder="estado" defaultValue={item.status} renderValue={(value, item)=>{
                                            return(<div style={{color: 'black', fontSize: '12px'}}>
                                                {item.label}
                                            </div>)
                                        }}
                                        onChange={(value, item, event)=>{
                                            setStatus(value)
                                        }} />
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={5} />
                                <FlexboxGrid.Item colspan={2} >
                                    <Button appearance="primary" onClick={()=>{modifyItem()}} style={{marginTop: '-15px'}} > <Icon icon="edit2"   />  Modificar</Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} lgHidden mdHidden >
                            <FlexboxGrid fluid >
                            <FlexboxGrid.Item
                                colspan={4}
                                style={{
                                    ...styleCenterMovil,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden'
                                }}
                            >
                                <div  > <img style={{height: '35px'}} onClick={()=>{
                                            setActualShipmentToSend(item.id_item);
                                            setModalMovil(true);
                                        }}  className="order" src={item.image} alt=""/> </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                colspan={4}
                                style={{
                                    ...styleCenterMovil,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden'
                                }}
                            >
                                <div>Clave:</div>
                                <div style={{width: '90%'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.id_item} onChange={(response)=>setClave(response)} /></div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                    colspan={8}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                <div>Nombre:</div>
                                <div style={{width: '90%'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.name} onChange={(response)=>setName(response)} /></div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                colspan={8}
                                style={{
                                    ...styleCenterMovil,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden'
                                }}
                            >
                                <div>Nota:</div>
                                <div style={{width: '90%'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.note} onChange={(response)=>setNote(response)} /></div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                    colspan={8}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Precio general:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.general_price} onChange={(response)=>setGeneralPrice(response)}  /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={8}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Precio registrado:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.registred_price} onChange={(response)=>setRegistredPrice(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={8}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Precio a domicilio:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.delivery_price} onChange={(response)=>setDeliveryPrice(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={4}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Cantidad:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.quantity} onChange={(response)=>setQuantity(response)} /></div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={4}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div>Garantía:</div>
                                    <div style={{width: '90%', color: 'black'}} ><Input style={{fontSize: '10px', height: '25px'}} defaultValue={item.warranty} onChange={(response)=>setWarranty(response)} /></div>
                                </FlexboxGrid.Item>
                                
                                <FlexboxGrid.Item
                                    colspan={10}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden',
                                        
                                    }}
                                >
                                    <div>Categoría:</div>
                                    <div style={{width: '90%', color: 'black'}} >
                                        <SelectPicker placement="bottomStart" style={{width: '100%', color: 'black'}} size="xs" data={categories} placeholder="categoría" defaultValue={item.section} renderValue={(value, item)=>{
                                            return(
                                                <div style={{color: 'black', fontSize: '10px'}} >
                                                    {item.label}
                                                </div>
                                            );
                                        }}
                                        onChange={(value, item, event)=>{
                                            setSection(value);
                                        }} />
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={6}
                                    style={{
                                        ...styleCenterMovil,
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        overflow: 'hidden',
                                        
                                    }}
                                >
                                    <div>Estado:</div>
                                    <div style={{width: '90%', color: 'black'}} >
                                        <SelectPicker placement="bottomEnd" style={{width: '100%'}} size="xs" data={[{value: '1', label: 'Activo', role: 'Activo'}, {value: '0', label: 'Inactivo', role: 'Inactivo'}]} placeholder="estado" defaultValue={item.status} renderValue={(value, item)=>{
                                            return(<div style={{color: 'black', fontSize: '10px'}}>
                                                {item.label}
                                            </div>)
                                        }}
                                        onChange={(value, item, event)=>{
                                            setStatus(value)
                                        }} />
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={24}  >
                                    <Button appearance="primary" onClick={()=>{modifyItem()}} > <Icon icon="edit2"   />  Modificar</Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Col>
                    </Row>
                </Con>
                
            </div>
        )
    }

    return(
        <div  >
            <Con fluid>
                <Row>
                    <Col xsHidden smHidden md={24}>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={3} >
                                <div style={{...styleCenter, fontSize: '16px', fontWeight: 'bold', marginTop: '10px'}}>Productos</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={19} style={{...styleCenter, marginTop: '10px'}} >
                                <InputGroup >
                                    <Input placeholder="Buscar por clave o nombre" onChange={(response)=>{
                                        setWord(response);
                                    }} />
                                    <InputGroup.Addon>
                                        <Icon icon="search" className="order" onClick={()=>{
                                            getProducts();
                                        }} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1} style={{marginLeft: '20px'}} >
                            <DownloadExcel item={<Whisper placement="top" trigger="hover" 
                                speaker={((
                                    <Popover >
                                        <p>Descargar catálogo</p>
                                    </Popover>
                                ))} >
                                <Icon style={{marginTop: '20px'}} icon="download" size="2x" className="order" />         
                            </Whisper>}  />
                            </FlexboxGrid.Item>
                            
                        </FlexboxGrid>
                        {functionalData.length>0 && (
                            <div>
                                <List hover >
                                    {functionalData[actualPage].map((item, index)=>(
                                        <List.Item key={item['id_order']  } index={index}  >
                                            <ItemProduct item={item} />
                                        </List.Item>
                                    ))}
                                </List>
                                <FlexboxGrid style={{...styleCenter, height: '40px'}}>
                                    <Pagination
                                        first
                                        ellipsis
                                        last
                                        boundaryLinks
                                        pages={functionalData.length}
                                        maxButtons={5}
                                        activePage={(actualPage+1)}
                                        onSelect={(response)=>{
                                            setActualPage(response-1);
                                        }}
                                    />   
                                </FlexboxGrid>
                            </div>
                        )}
                    </Col>
                    <Col sm={24} xs={24} lgHidden mdHidden >
                        <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24} >
                                <div style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '10px'}}>Productos</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={21} style={{...styleCenter, marginTop: '5px'}} >
                                <InputGroup >
                                    <Input placeholder="Buscar por clave o nombre" onChange={(response)=>{
                                        setWord(response);
                                    }} />
                                    <InputGroup.Addon>
                                        <Icon icon="search" className="order" onClick={()=>{
                                            getProducts();
                                        }} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="order" colspan={1}>
                                <DownloadExcel item={
                                    <Icon style={{marginTop:  '15px', marginLeft: '5px'}} icon="download" size="2x" className="order" /> 
                                } />
                            </FlexboxGrid.Item>
                            
                        </FlexboxGrid>
                        {functionalData.length>0 && (
                            <div>
                                <List hover >
                                    {functionalData[actualPage].map((item, index)=>(
                                        <List.Item key={item['id_order']  } index={index}  >
                                            <ItemProduct item={item} />
                                        </List.Item>
                                    ))}
                                </List>
                                <FlexboxGrid style={{...styleCenter, height: '40px'}}>
                                    <Pagination
                                        first
                                        ellipsis
                                        last
                                        boundaryLinks
                                        pages={functionalData.length}
                                        maxButtons={5}
                                        activePage={(actualPage+1)}
                                        onSelect={(response)=>{
                                            setActualPage(response-1);
                                        }}
                                    />   
                                </FlexboxGrid>
                            </div>
                        )}
                    </Col>
                </Row>
            </Con>
            <Modal 
                    show={modalMovil}
                    onHide={()=>setModalMovil(false)}
                    style={{width: '100%'}}
                >
                    <Modal.Title>Cambiar foto</Modal.Title>
                    <UploadImageForm modalClose={setModalMovil}  id={actualShipmentToSend} filename={actualShipmentToSend} />
                </Modal>
                <Modal 
                    show={modal}
                    onHide={()=>setModal(false)}    
                >
                    <Modal.Title>Cambiar foto</Modal.Title>
                    <UploadImageForm modalClose={setModal}  id={actualShipmentToSend} filename={actualShipmentToSend} />
                </Modal>
        </div>
    );
}