import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, FormGroup, Divider } from 'rsuite';

export const ShowFiscalDataToPay = (props) => {

    const action = props.closeModal;

    const [cuenta, setCuenta] = useState('');
    const [banco, setBanco] = useState('');
    const [transferencia, setTransferencia] = useState('');
    const [oxxo, setOxxo] = useState('');
    const [cuenta2, setCuenta2] = useState('');
    const [banco2, setBanco2] = useState('');
    const [transferencia2, setTransferencia2] = useState('');
    const [oxxo2, setOxxo2] = useState('');
    const [numeroKali, setNumeroKali] = useState('');


    const getPaymentData = async () => {
        const url = '/backend/mainController.php?opt=getPaymentData';
        let datas = new URLSearchParams('kind=1');
        const response1 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained1 = await response1.json();
        setCuenta(await obtained1.value);
        datas = new URLSearchParams('kind=2');
        const response2 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained2 = await response2.json();
        setNumeroKali(await obtained2.value);
        datas = new URLSearchParams('kind=3');
        const response3 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained3 = await response3.json();
        setBanco(await obtained3.value);
        datas = new URLSearchParams('kind=7');
        const response4 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained4 = await response4.json();
        setTransferencia(await obtained4.value);
        datas = new URLSearchParams('kind=8');
        const response5 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained5 = await response5.json();
        setOxxo(await obtained5.value);
        datas = new URLSearchParams('kind=5');
        const response6 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained6 = await response6.json();
        setBanco2(await obtained6.value);
        datas = new URLSearchParams('kind=6');
        const response7 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained7 = await response7.json();
        setCuenta2(await obtained7.value);
        datas = new URLSearchParams('kind=9');
        const response8 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained8 = await response8.json();
        setTransferencia2(await obtained8.value);
        datas = new URLSearchParams('kind=10');
        const response9 = await fetch(url, {
            method: 'POST',
            body: datas
        });
        const obtained9 = await response9.json();
        setOxxo2(await obtained9.value);

    }

    useEffect(() => {
        getPaymentData();
    }, []);

    return (
        <>
            <div>
                <div style={{ backgroundColor: '#d4edda', margin: '15px', padding: '7px', borderColor: '#c3e6cb', borderRadius: '.25rem', color: '#155724', border: '1px solid transparent' }} >
                    <div>
                        Por favor deposite la cantidad hacia el siguiente banco: {banco}
                    </div>
                    <div>
                        Cheques y depósitos: {cuenta}
                    </div>
                    <div>
                        Transferencia: {transferencia}
                    </div>
                    <div>
                        Oxxo: {oxxo}
                    </div>
                    <div>
                        Y envíe su comprobante al whatsapp: {numeroKali}
                    </div>
                    <div>
                        Gracias por su confianza y preferencia.
                                </div>
                </div>
                <Divider>También puede elegir</Divider>
                <div style={{ backgroundColor: '#b8daff', margin: '15px', padding: '7px', borderColor: '#004085', borderRadius: '.25rem', color: '#004085', border: '1px solid transparent' }} >
                    <div>
                        Por favor deposite la cantidad hacia el siguiente banco: {banco2}
                    </div>
                    <div>
                        Cheques y depósitos: {cuenta2}
                    </div>
                    <div>
                        Transferencia: {transferencia2}
                    </div>
                    <div>
                        Oxxo: {oxxo2}
                    </div>
                    <div>
                        Y envíe su comprobante al whatsapp: {numeroKali}
                    </div>
                    <div>
                        Gracias por su confianza y preferencia.
                                </div>
                </div>
            </div>
            <FormGroup style={{ textAlign: 'right', marginTop: '30px' }}>
                <ButtonToolbar >
                    <Button appearance="default" onClick={() => { action(false) }}>Cancelar</Button>
                </ButtonToolbar>
            </FormGroup>
        </>
    );
}